@import "../../../../assets/stylesheets/scss/tokens";

.deleteChartingNoteModal {
  h2 {
    padding-bottom: 20px;
    color: $theme-text-primary;
    border-bottom: 1px solid $theme-border-primary;
    @include type-h4;
    text-align: center;
  }

  .messageText {
    text-align: center;
    margin-top: 24px;
  }

  .noteText {
    text-align: center;
    margin-top: 20px;
    @include type-sm;
    color: $theme-text-secondary;
  }

  .buttons {
    margin-top: 24px;
    text-align: center;

    :first-child {
      margin-right: 18px;
    }
  }
}

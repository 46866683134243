@import "./src/assets/stylesheets/scss/tokens";

.underline {
  border-bottom: 1px solid $theme-border-primary;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem
}

.noPackages {
  // Added to handle older COP UI height - can be removed when we enable the new COP UI
  height: REM(350);
}

@import "assets/stylesheets/scss/tokens";
@import "../Button.module.scss";

button.tertiary, a.tertiary {
  @extend .healthieButton;
  
  @include iconStyles;
  padding: 1rem 0;
}

.tertiary {
  background: transparent;

  &.default {
    color: $theme-accent-primary;
  }

  &.danger {
    color: $theme-notice-bold-warning;
  }

  &:hover {
      &.default {
        color: $theme-accent-primary-hover;
      }
      &.danger {
        color: $theme-notice-bold-warning-hover;
      }
  }

  &:active {
      &.default {
        color: $theme-accent-primary-hover;
      }
      &.danger {
        color: $theme-notice-bold-warning-hover;
      }
  }
}

.buttonWithIcon {
  &.tertiary {
    &:disabled, &.loading {
      &:hover, &:active, &:focus-visible{
        cursor: default;
      }
    }
  }
}

.buttonWithText {
  &.tertiary {
    &.default, &.danger {
      &:disabled, &.loading {
        cursor: default;
      }
    }
  }
}

.buttonWithTextAndIcon {
  @extend .buttonWithText;
}


.ModifyPermissionTemplate {
  .PermissionTemplateFormWrapper {
    padding: 1.5rem;
    min-height: 100vh;
  }
  .stickyBreadcrumb {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}

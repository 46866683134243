@import "src/assets/stylesheets/scss/_tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.switchAccountOption {
  width: 244px;
  min-height: 347px;
  background-color: $theme-background-primary;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $theme-border-primary;
  display: flex;
  flex-direction: column;

  .switchAccountClientName {
    display: flex;
    width: 242px;
    min-height: 3rem;
    border-top: solid 1px $theme-border-primary;
    background-color: $theme-background-primary;
    align-items: center;
    justify-content: center;
    div {
      text-align: center;
      padding: 0.75rem;
    }
    span {
      word-break: break-all;
    }
  }
}


@include only-for-ie{
  .switchAccountOption {
    float: left;
  }
}



.switchAccountButtonContainer {
  margin-top: auto;
  border: 1px solid $theme-accent-primary;

  button {
    width: 100%;
    border-radius: 0px;
  }
}

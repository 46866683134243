.dosespotModal {
  z-index: 1000;
  margin: auto;
}

.dosespotModalBody {
  height: calc(95vh - 8rem);
  width: 100%;
  overflow: hidden;

  iframe {
    min-height: calc(95vh - 8rem);
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
}

.dosespotModalWrapper {
  height: 100%;
  overflow: hidden;
}

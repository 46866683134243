@import '../../../assets/stylesheets/scss/tokens';


.availabilityMenuWrapper.menuOpen {
    > button[class*="buttonWithText"] {
        background-color: $theme-text-primary;
        color: $theme-white;
        &:active, &:focus, &:hover {
          background-color: $theme-text-primary;
          color: $theme-white;
        }
    }
} 
.availabilityMenu, .availabilityMenuWrapper > button {
    margin-top: 1rem;
}
@import "src/assets/stylesheets/scss/tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.fieldWrapper {
  max-width: 378px;

  .select {
    height: 36px;
  }

  @include mobile {
    max-width: 100%;
  }
}

@import 'src/assets/stylesheets/scss/tokens.scss';

.item {
  @include type-lg;
  border-bottom: 1px solid $theme-border-primary;

  &:not(:last-child) {
    margin-bottom: 0.313rem !important;
  }
  
  > * {
    font-size: 14px;
    margin: 0;
  }
  
  .label {
    color: $theme-text-secondary;
    font-size: inherit;

    &::first-letter {
      text-transform: uppercase;
    }
  }
  
  .value {
    color: $theme-text-primary;
    font-size: inherit;
  }
}

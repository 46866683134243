@import "src/assets/stylesheets/scss/_tokens";

.cardRowContainer{
  display: flex;

  .cardInfoKey{
    @include type-lg-bold;
    margin-right: 5px
  }
  .cardInfoValue{
    @include type-lg;
    color: $theme-accent-primary;
  }
}

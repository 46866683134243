@import "assets/stylesheets/scss/tokens";

.label {
		display: block;
		@include type-sm;
		text-align: left;
		color: $theme-text-primary;
		&:not(:last-child){
			margin: 8px 0 5px 0;
		}
}
.tinymceEditor {
	&.disabled {
		:global {
			.tox-edit-area{
				border-radius: 0;
			}
		}
	}
	:global{
		.tox-tinymce{
			border: 1px solid $theme-border-input;
			border-radius: 3px;
			&:hover, &:focus, &:active {
				border-color: $theme-border-input;
			}
		}
	}
}

@import "./src/assets/stylesheets/scss/tokens";

.card {
  border-top: 1px solid $theme-border-primary;
  &.highPriority {
    box-shadow: 0.5rem 0 0 0 $theme-notice-bold-alert inset;
  }
  .contentRow {
    word-break: break-word;
  }
  .noteLink {
    @include type-sm;
    vertical-align: middle;
    word-break: keep-all;
  }
  .highPriorityText {
    @include type-sm-bold;
  }
}

.detailRow {
  display: flex;
  align-items: center;
  padding: 0 0 0.25rem 0 !important;
  .clientName {
    @include type-sm-bold;
    color: $theme-accent-primary;
    word-break: break-all;
  }
}

.tasksAvatarContainer {
  display: flex;
  justify-content: flex-end;
  .taskClient {
    margin-left: -0.5rem;
  }
}

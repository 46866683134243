@import 'src/assets/stylesheets/scss/_tokens.scss';

.healthieCollapsibleSection {
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: $theme-background-primary;
  border: 1px solid $theme-border-primary;
  margin-bottom: 1rem;

  &.hideBorder {
    border: none;
  }

  &.transparentBordered {
    background: none;
  }

  &:not(.multiCollapsible, .hideBorder) {
    border: 1px solid $theme-border-primary;
  }

  &.multiCollapsible.clipContent:not(:first-child) {
    clip-path: inset(0 -4px -6px -4px);
  }

  &.sectionType {
    position: relative;
    border-left-width: 0;

    &::before {
      content: '';
      width: 0.75rem;
      border: 0.75rem solid $theme-border-primary;
      border-right-width: 0;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      position: absolute;
      top: -1px;
      bottom: -1px;
    }

    &.success::before {
      border-color: $theme-notice-bold-success;
    }

    &.alert::before {
      border-color: $theme-notice-bold-alert;
    }

    &.warning::before, &.error::before {
      border-color: $theme-notice-bold-warning;
    }

    &.info::before, &.specific::before {
      border-color: $theme-accent-primary;
    }

    &.soft-success::before {
      border-color: $theme-notice-soft-success;
    }

    &.soft-alert::before {
      border-color: $theme-notice-soft-alert;
    }

    &.soft-warning::before {
      border-color: $theme-notice-soft-warning;
    }
  }

  .sectionHeader {
    height: auto;
    min-height: 3.75rem;
    padding: 1rem 1.25rem;
    cursor: pointer;

    &.noPadding {
      padding: 0;
    }

    &.isAside {
      padding: 1rem 1.5rem;
    }

    // Contains headerContainer (headerMain ((name + label)) + status pills) + actionsContainer
    .sectionHeaderContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // Contains headerMain (name + label) and status pills
    .headerContainer {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    // Contains name and label
    .headerMain {
      flex-grow: 1;
      margin: 0.25rem 0;
    }

    .headerName {
      @include type-lg-bold;
      color: $theme-text-primary;
      display: inline-block;
      pointer-events: none;
    }

    .icon {
      display: inline-block;
      line-height: 1rem;
      padding-right: 0.5rem;
      vertical-align: bottom;
    }

    .label {
      margin-right: auto;
      margin-top: 0.5rem;
    }

    .statusPillsContainer {
      align-self: flex-start;
      white-space: nowrap;

      > div {
        display: inline-block;
        margin: 0 0.5rem 0 0;

        &:last-child {
          margin-right: 0.25rem;
        }
      }
    }

    .details {
      display: block;
      margin-top: 0.5rem;
      width: 100%;
    }

    .actionsContainer {
      display: flex;
      align-items: baseline;
      align-self: start;

      .collapseToggle:only-child {
        margin-left: 0.75rem;

        &.preserveDropdownSpacing {
          margin-left: 4rem; // hold space for absent dropdown actions
        }
      }
    }
  }

  .sectionBody {
    &.isAside {
      padding: 0 1.5rem 1.5rem;
    }

    height: 100%;
    padding: 1rem 1.25rem;

    &.noPadding {
      padding: 0;
    }
  }

  &.isCollapsed {
    .sectionBody {
      display: none;
    }
  }

  &.isAside {
    margin-bottom: 1.5rem;
  }

  &.fullHeight {
    height: 100%
  }

  &.noBottomMargin {
    margin-bottom: 0;
  }
}

.fullHeight {
  height: 100%
}

@import '../../../assets/stylesheets/scss/tokens';

.mainWrapper {
  background-color: $theme-background-secondary;
  height: fit-content;
  min-height: 100%;
  padding-bottom: 5rem;
}
.navguardText {
  display: flex;
  align-items: center;
  flex-direction: column; 

  .errorText {
    color: $theme-notice-bold-warning;
    @include type-sm;
  }
}

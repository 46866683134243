.field {
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;

  &:not(:last-child, .noBottomMargin) {
    margin-bottom: .75rem;
  }
}

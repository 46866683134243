@import "src/assets/stylesheets/scss/_tokens";


.divider {
  border: solid 1px #e0e0e0;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}
   
    
.submitButton{
  margin-top: 2rem;
}



@import "./src/assets/stylesheets/scss/tokens";
.bizAddressTip {
  @include type-lg;
  color: $theme-text-secondary;
}

.financialLocationFields {
  :global{
    label {
      color: $theme-text-primary;
      @include type-sm-bold;
    }
    
    .row {
      display: flex;
      flex-flow: row wrap;
      margin: 0;
      
      &:nth-child(2) {
        .col {
          &:not(:last-child) {
            flex-basis: 100%;
          }
          
          &:last-child {
            flex-grow: unset;
            flex-basis: 35%;
          }
        }
      }
      
      &:last-child {
        margin-top: -3.6rem;
        justify-content: flex-end;
        
        .col {
          
          &:first-child {
            margin-right: 0.5rem;
            
            .Select-control {
              height: 32px;
            }
          }
          
          &:nth-child(2) {
            input {
              height: 32px;
            }
          }
          
          &:not(:last-child) {
            flex-basis: 30%;
            flex-grow: unset;
          }
          
          &:last-child {
            flex-basis: 100%;
          }
        }
      }
      
      .col {
        padding: 0;
      }
    }
  }
}


@import "src/assets/stylesheets/scss/tokens";
.faxingTabs {
    display: flex;
    border-bottom: 1px solid $theme-border-primary;
    padding: 0 REM(30);

    .faxNumber {
        margin-left: auto;
        color: $theme-text-secondary;
        @include type-lg;
        text-align: right;
        width: 100%;
        span {
            @include type-lg-bold;
        }
    }
}

@import "assets/stylesheets/scss/tokens";

.checkboxContainer {
  display: flex;
  align-items: center;
  &.alignment {
    align-items: flex-start;
  }
  .labelContainer {
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 0;
    margin: 0;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    align-items: center;
    position: relative;
    .checkmark {
      min-width: 14px;
      width: 14px;
      height: 14px;
      background-color: $theme-background-input;
      border: 1px solid $theme-border-input;
      border-radius: 2px;
      position: relative;
      &:after {
        content: "";
        display: none;
        position: absolute;
      }
    }
    .label {
      display: flex;
      color: $theme-text-primary;
      text-align: left;
      @include type-lg;
      padding-left: 5px;
      align-items: center;
      svg{
        margin-right: 5px;
      }
    }
    &.checked{
      .checkmark{
        background: $theme-accent-primary;
        border: 1px solid $theme-accent-primary;
        &:after {
          display: block;
          top: 0;
          left: 4px;
          width: 6px;
          height: 10px;
          border: solid $theme-white;
          transform: rotate(45deg);
          border-width: 0 2px 2px 0;
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
    }
    &.readOnly{
      .checkmark {
        cursor: not-allowed;
      }
    }
    &.disabled {
      cursor: not-allowed;
      .checkmark {
        cursor: not-allowed;
        background-color: $theme-background-disabled;
        border: 1px solid $theme-border-input;
      }
      .label{
        color: $theme-text-secondary;
      }
      &.checked {
        &.showCheck{
          .checkmark{
            &:after {
              display: block;
              top: 0;
              left: 4px;
              width: 6px;
              height: 10px;
              border: solid $theme-border-input;
              transform: rotate(45deg);
              border-width: 0 2px 2px 0;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
            }
          }
        }
      }
    }
    &.longerLabel{
      align-items: flex-start;
      .label{
        line-height: 14px;
      }
    }
  }
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }
  .tooltipIcon {
    color: $theme-accent-primary;
    margin-left: 10px;
    margin-bottom: 3px;
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
  .tooltip{
    pointer-events: auto!important;
    max-width: 320px;
    @include type-lg;
    z-index: 5;
    a{
      color: $theme-white;
      text-decoration: underline;
      padding: 0 2px;
      &:hover{
        opacity: 0.9;
      }
    }
  }
}

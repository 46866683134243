@import "../../../assets/stylesheets/scss/tokens";
.paymentDatePicker {
  max-width: none;
  width: 100%;
  position: relative;
  :global .react-datepicker-wrapper {
    width: 100%;
  }
  svg {
    pointer-events: none;
    position:absolute;
    right: 6px;
  }
  .paymentDateInputWrapper {
    display: flex;
    align-items:center;
    .paymentDateInput {
      @include type-lg;
      width: 100%;
      background: $theme-background-primary;
      border-radius: 3px;
      border: 1px solid $theme-accent-primary;
      height: 33px;
      color: $theme-text-secondary;
      padding: 0 7px;
      cursor: pointer;

    }
    
  }
}

.paymentDateNotification {
  margin-top: 15px;
}

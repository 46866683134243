@import 'src/assets/stylesheets/scss/tokens';

.wrapper {
  padding: REM(24);

  .title {
    @include type-h3;
    color: $theme-text-primary;
  }
}

@import "src/assets/stylesheets/scss/tokens";

.carePlanSearchBox {
  min-width: 285px;
  max-height: 280px;
  overflow: auto;

  .searchBox {
    padding: 0.75rem;

    form div {
      width: 100%;
    }
  }

  .carePlanTemplateItems {
    .noTemplatesTip {
      display: flex;
      flex-direction: column;
      min-height: 80px;
      padding: 10px 5px;

      span {
        color: $theme-text-secondary;
        @include type-lg;
        text-align: center;
      }
    }

    >div:not(.noTemplatesTip ) {
      height: 45px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      border-top: 1px solid $theme-border-primary;

      &.templateItem {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          background-color: $theme-background-secondary;
          >p {
            @include type-lg-bold;
          }
        }
      }

      span {
        color: $theme-text-secondary;
        @include type-lg-bold;
      }
    }

    .templateItemName {
      color: $theme-accent-primary;
      @include type-lg;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      min-width: 225px;
      max-width: 300px;

      &:not(:last-child) {
        border-bottom: 1px solid $theme-border-primary;
      }
    }
  }
}

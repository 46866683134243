@import "./src/assets/stylesheets/scss/tokens";
@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.sectionActionBar {
  bottom: 0;
  position: fixed;
  width: 570px;
  background: $theme-background-secondary;
  z-index: 10;
  margin: 0 revert-layer;
  padding-bottom: 2rem;

  .title {
    @include type-sm-bold;
    text-transform: uppercase;
  }
}
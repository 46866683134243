@import "assets/stylesheets/scss/tokens";

.field {
  position: relative;
  min-height: 49px;
  display: flex;
  flex-direction: column;

  &:not(:last-child, .noBottomMargin) {
    margin-bottom: .75rem;
  }

  .input {
    @include type-lg;
    height: 35px;
    width: 100%;
    border: 1px solid;
    border-radius: 3px;
    padding: calc(0.375em - 1px) calc(0.625em - 1px);
    background-color: $theme-background-input;

    &::placeholder {
      color: $theme-text-secondary;
    }

    &:focus, &:active, &:hover {
      border-color: $theme-accent-primary;
    }

    &:not(:focus) {
      border: 1px solid $theme-border-input;
    }


    &.isDanger {
      border-color: $theme-notice-bold-warning;
    }
  }
}

@import "assets/stylesheets/scss/tokens";

.optionLabel{
    display: flex;
    align-items: center;

    .optionLabelCode{
        padding-left: 0.25rem;
        span {
            display:inline-block;
            @include type-lg;
            text-transform: uppercase;
            width: 65px;
            margin-right: 0.75rem;
        }
    }

    .optionLabelName{
        span{
            display:inline-block;
            @include type-lg;
        }
    }
}

@import "src/assets/stylesheets/scss/tokens";

.wrapper {
  flex-wrap: nowrap;
  margin-bottom: 0 !important;
  width: 100%;
  min-height: 40px;
  flex-wrap: wrap;
  height: fit-content;
  justify-content: flex-end;

  .labelWrapper {
    text-align: left;
    padding: 0 0 0 0.5rem !important;

    .label {
      @include type-lg-bold;
      text-align: left;
      padding: 0;
      display: flex;
      align-items: center;
    }
    :global{
      .fa-question-circle {
        color: $theme-accent-primary;
        font-size: 1rem;
        margin-left: 0.5rem;
      }
    }
  }

  .sublabel {
    @include type-lg;
    padding: 0 0 0.5rem 0;
    text-align: left;
    flex-basis: 100%;
    padding: 0 0 0.5rem 2rem;
  }

  .customTooltipWrapper {
    margin-left: 0.5rem;
    height: 20px;
  }

  .dropdownWrapper {
    justify-items: flex-end;
    align-self: flex-start;
    padding: 0.5rem 0 0 0;
  }

  .dragIcon {
    height: 24px;
  }
}
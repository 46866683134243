.providerRow {
  display: flex;
  padding: 10px 0px;

  .providerField {
    width: 185px;
    flex-shrink: 0;
  }

  .providerUrlField {
    width: 100%;
    padding-left: 20px;
  }

  .removeButton {
    margin-top: auto;
    padding-left: 10px;
    padding-bottom: 4px;
  }
}

@import 'src/assets/stylesheets/scss/tokens';

.labOrdersEmptyState {
  display: flex;
  flex-direction: column;

  .title {
    @include type-lg;
    margin-bottom: REM(27);
  }

  .actions {
    display: flex;
    gap: REM(10);
  }
}

.labOrdersEmptyStateOld {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: REM(73);

  .title {
    @include type-lg;
    margin-bottom: REM(27);
  }

  .actions {
    display: flex;
    gap: REM(10);
  }
}

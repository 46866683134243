@import 'src/assets/stylesheets/scss/tokens.scss';

.authorizationItemContent {
  color: $theme-text-primary;

  &.withSidebarStyles {
    display: grid;
    row-gap: 11px;

    .label {
      @include type-sm-bold;
    }
  }

  .value {
    @include type-sm;
    margin: 0;
  }
}

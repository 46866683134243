@import 'src/assets/stylesheets/scss/tokens';

.actionButtons {
  padding-top: 20px;
  display: flex;

  button:first-child {
    flex-grow: 1;
    margin-right: 20px;
  }
}

.inputs {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 10px 0;

  label:first-child {
    margin: 0 0 1px;
  }

  > div:nth-child(2) {
    width: 100%;

    > p {
      bottom: -21px;
    }
  }

  input {
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
      width: 115px;
    }

    &:first-child {
      min-height: 35px;
    }
  }
}

.price {
  margin-right: 15px;

  + p[data-error-adapter="true"] {
    bottom: -20px;
  }
}

.icon {
  margin-left: 15px;
  align-self: self-start;
  padding-top: 18px;
  cursor: pointer;
}
@import 'src/assets/stylesheets/scss/tokens';

.wrapper {
  margin-top: REM(32);

  .title {
    @include type-lg-bold;
    color: $theme-text-primary;
    margin-bottom: REM(24);
  }
}

@import "assets/stylesheets/scss/tokens";

.container {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  :global {
    .react-datepicker-popper {
        min-width: 100px;
    }
    .react-datepicker--time-only {
      width: 100px;

      .react-datepicker__time-container {
        width: 98px;

        .react-datepicker__time-box {
          width: 98px;

          li.react-datepicker__time-list-item--disabled {
            cursor: default;
          }
        }
      }
    }
  }
}

.errorText {
  z-index: 2;
  margin-top: 5px; // conflicting with styles in SharedAdapterStyles.scss
  color: $theme-notice-bold-warning;
  @include type-sm;
}

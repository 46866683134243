@import "src/assets/stylesheets/scss/tokens";

.noMarginContainer {
  max-width: 100%;
  margin: 0;
}

.chartHistoryHeader {
  @include type-lg-bold;
  margin-bottom: 1rem;
}

.chartHistoryTable {
  width: 100%;
  text-align: left;

  tr {
    border-bottom: 1px $theme-border-primary solid;
    width: 100%;
    padding: 1rem;
    height: 56px; // Due to table css, this needs to include padding height: 24px + 1rem padding top and bottom

    th:first-of-type {
      width: 50%;
    }

    .actionContainer {
      .description {
        @include type-sm;
        white-space: pre-line;
      }
    }
  }
}
@import 'src/assets/stylesheets/scss/tokens';

.modalSubtitle {
  .subheader {
    @include type-lg-bold;
  }

  .consent {
    @include type-lg;
  }

  > [data-ff-adapter] {
    margin-top: 1.25rem;
  }
}

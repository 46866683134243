@import "src/assets/stylesheets/scss/tokens";
.loadingProfileContainer{
  display: flex;
  height: 100%;
  background: $theme-background-primary;
  color: $theme-text-primary;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  img {
    max-width: 380px;
  }
  .content{
    flex-basis: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .userName{
    @include type-h3;
  }
  .subTitle{
    margin-top: 10px;
    @include type-h4;
  }
  .para{
    @include type-lg;
  }
}

@import "./src/assets/stylesheets/scss/tokens";
.label {
  @include type-sm;
  text-align: left;
  color: $theme-text-primary;
  display: block;

  &:not(:last-child) {
    margin: 8px 0 5px 0;
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.directMessagesContainer {
    .headerContainer{
        border-bottom: 0;
        padding: 2rem;
        padding-bottom: 0;
        margin-bottom: 1rem;
        .headerTitleContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .headerTitle {
                @include type-h3;
                color: $theme-text-primary;
                width: fit-content;
                margin-right: 1rem;
            }
        }
    }
}

.listHeader {
  padding: 0 1rem;
  margin-bottom: 0 !important;
}

.directMessagesWrapper {
  padding-right: 1rem;
}

.directMessagesFormWrapper {
    padding-top: 1rem;
}

.tableWrapper {
  margin: 0 !important;
  padding: 0.5rem;
  width: 100%;
  .table {
    background: $theme-background-primary;
    width: 100%;
    margin-bottom: 3rem;
    thead {
        tr{
            border: none;
            height: 3.25rem;
        }
        th {
            @include type-sm-bold;
            text-transform: uppercase;
            color: $theme-text-secondary !important;
            border: none;
            .tooltip {
                max-width: 400px;
                @include type-lg;
                text-transform: none;
            }
            i {
                color: $theme-accent-primary;
            }
            &.colXL{
                width: 32%;
                text-align: center;
            }
            &.colL {
                width: 25%;
                text-align: center;
            }
            &.colMD{
                width: 12%;
                text-align: center;
            }
        }
    }
    tbody {
        tr {
            td {
                text-align: center;
            }
        }
    }
    .scrollRow {
        height: 150px;
    }
  }
}

.showMessage {
    padding-top: 5rem;

    .messageItem {
        margin-bottom: 1rem;
        p {
            @include type-lg-bold;
        }

        ul {
            list-style-type: disc;
        }

        .messageBody {
            border: 1px solid $theme-border-primary;
            border-radius: 3px;
            padding: 3rem;
            p {
                @include type-lg-bold;
            }
        }
    }
}

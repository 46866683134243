@import "./src/assets/stylesheets/scss/tokens";
@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.formActionBar {
  background: $theme-background-primary;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 100;
  width: 100%;
  min-height: 6rem;
  bottom: 0px;

  @include tablet {
    width: calc(100% - 85px);
  }

  .title {
    @include type-sm-bold;
    text-transform: uppercase;
  }
}

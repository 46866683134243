@import "./src/assets/stylesheets/scss/tokens";
.faxLineRequestEmptyStateBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  &Text {
    @include type-h4;
    text-align: center;
    margin-bottom: 10px;
    &Main {
      text-align: center;
      color: $theme-text-primary;
    }
    &Wrap {
      color: $theme-text-primary;
      text-align: center;
      max-width: 300px;
      word-wrap: break-word;
      margin: auto;
    }
    &Requested {
      text-align: center;
      color: $theme-notice-bold-success;
    }
  }

}

@import "../../../assets/stylesheets/scss/tokens";

.link {
	color: $theme-accent-primary;
	@include type-sm;
	margin: 0;
	padding: 8px 15px 8px 12px;
	width: 100%;
	display: block;
	text-align: left;
	white-space: nowrap;
	cursor: pointer;

	&:hover,
	&:focus {
		background: $theme-background-hover;
	}
}

@import "assets/stylesheets/scss/tokens";
.errorText {
	z-index: 2;
	margin-top: 5px; // conflicting with styles in SharedAdapterStyles.scss
	color:$theme-notice-bold-warning;
	@include type-sm;
}

.flexErrorText {
	z-index: 2;
	display: flex;
	align-items: flex-start;
	gap: 10px;
	color:$theme-notice-bold-warning;
	@include type-sm;
}

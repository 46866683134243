@import "src/assets/stylesheets/scss/tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.editFormAnswerGroupWrapper {
  background-color: $theme-background-secondary;
  padding-bottom: 7.5rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 66px);

  @include mobile {
    padding-bottom: 12.5rem;
  }

  :global {
    .reset-button {
      margin-left: auto;
      margin-top: auto;
      height: 37px;
      padding: 0.625rem;
    }

    .intake-form-buttons-container {
      margin-bottom: 0.938rem;
      margin-top: 1.438rem;
      display: flex;
      justify-content: center;
      gap: 15px;
      a:not(:last-child) {
        margin-right: 1.875rem;
      }
    }

    .save-changes-buttons-container {
      display: flex;
      justify-content: center;
      width: 36%;
      margin: auto;
      padding: 0.5rem 0 0 0;
      border-top: 1px solid $theme-border-primary;

      .save-button {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        align-items: center;
        text-align: center;
        margin: 0;
        font-size: 11px !important;
      }

      .save-button:hover {
        color: $theme-accent-primary;
      }

      div:not(:last-child) {
        margin-right: 1.875rem;
      }

      @include mobile {
        padding: 0;
        margin-top: 0;
        flex-direction: column;
        align-items: center;

        div:not(:last-child) {
          margin-right: unset;
          margin-bottom: 1.875rem;
        }
      }

      .healthie-button:not(.is-loading)[disabled] {
        background-color: $theme-background-disabled !important;
        border-color: $theme-border-input !important;

        &:hover {
          background-color: $theme-background-disabled !important;
          border-color: $theme-border-input !important;
        }
      }
    }

    @media(max-width: 769px) {
      .intake-form-buttons-container {
        div:first-child {
          .primary-button:first-child {
            margin-top: 1.25rem;
          }
        }
      }
    }

    .allergy-fields {
      padding: 1.25rem 0;
      border-bottom: solid 1px $theme-border-primary;
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
      }

      .buttons {
        button {
          min-width: 229px;
          height: 35px;
          border: 1px solid $theme-accent-primary;
          border-radius: 3px;
        }

        .add-field-button {
          padding: 0.625rem 2.188rem;
        }
      }
    }

    #name {
      width: 200px;
    }

    #reactions {
      width: 250px;
    }

    #type {
      width: 130px;
    }

    table {
      tr {
        height: auto;
      }

      th {
        min-width: 110px;
      }

      td {
        padding-right: 0.625rem;
      }

      .Select {
        height: auto;
        margin-bottom: 0.625rem;
      }

      .allergy-row:first-of-type {
        td {
          border-top: 1px solid $theme-border-primary;
          padding-top: 0.625rem;
        }
      }

      .allergy-row {
        .field {
          display: none;
        }
      }

      tr {
        height: 41px;
        .custom-allergy-field {
          margin: 0.625rem 0;
        }
      }

      #active {
        label {
          margin: auto;
        }
      }

      .x-button {
        color: $theme-text-secondary;
        border: none;
        background: transparent;
        padding: 0.438rem 0;
        margin: 0.625rem 0;
        cursor: pointer;
      }

      input[type="radio" i] {
        appearance: radio;
        width: 14px;
        margin-left: 0;
      }
    }
    .medication-fields {
      th {
        padding-bottom: 0.625rem;
        border-bottom: 1px solid $theme-border-primary;
        min-width: 40px;
      }
    }
  }

  &Base {
    margin: 1.25rem auto 0 auto;
    max-width: 760px;
    width: 100%;
    background-color: $theme-background-primary;
    padding: 1.875rem;

    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);

    :global {
      .mce-top-part.mce-container.mce-stack-layout-item.mce-first {
        top: 68px;
      }
    }
  }

  &.fullWidthForm {
    margin-right: 1.563rem;
    padding-right: 1.563rem;

    .editFormAnswerGroupWrapperBase {
      max-width: 100%;
      margin-left: 1.563rem;
      margin-right: 1.563rem;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

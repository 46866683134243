 @import 'src/assets/stylesheets/scss/_tokens.scss';
 @import 'src/assets/stylesheets/scss/utilities/MediaQueries/_mediaQueryMixins.scss';

 .buttonsWrapper {
    border-top: 1px solid $theme-border-primary;
    display: flex;
    gap: 0.43rem;
    justify-content: flex-end;
    margin-top: 1.5rem;
    max-height: 3.813rem;
    padding-top: 1.563rem;
    
    @include mobile {
      flex-wrap: wrap;
      max-height:none;
      padding-bottom: REM(50);

      button[type="submit"], 
      button[type="button"] {
        margin: 0;
        width: 100%;
      }
    }
  }

@import "./src/assets/stylesheets/scss/tokens";

.pageHeader {
  border-bottom: 1px solid $theme-border-primary;
  margin-bottom: 1.25rem;
  padding-bottom: 1rem;
}
.title {
  @include type-h3;
  padding-right: 1.5rem;
}

.filterRow {
  margin-bottom: 1.5rem;
}

.tooltip{
  @include type-lg;
  text-align: left;
}

.client-link {
  @include type-lg-bold;
}
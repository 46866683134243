@import 'src/assets/stylesheets/scss/_tokens.scss';

.convoTaskPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: fit-content;
  gap: 4px;
  border: 1px solid transparent;
  border-radius: 12px;
  padding: 0 4px;
  white-space: nowrap;
  cursor: pointer;

  >svg {
    flex-shrink: 0
  }

  >span {
    @include type-sm-bold;
    color: $theme-text-secondary;
    display: none;
  }

  &:hover {
    background-color: $theme-background-primary;
    border: 1px solid $theme-border-primary;

    >span {
      display: inline-block;
    }
  }
}

.taskPreviewTooltip {
  &[data-id=tooltip] {
    @include type-sm;
    border-radius: 0;
    padding: 8px 12px;
    margin-top: 6px;

    &:after {
      border-left-width: 6px;
      border-right-width: 6px;
      top: -5px;
      margin-left: -6px;
    }
  }
}

.pending {
  svg {
    path {
      fill: $theme-notice-bold-alert;
    }
  }
}

.complete {
  svg {
    path {
      fill: $theme-notice-bold-success;
    }
  }
}

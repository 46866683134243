@import "assets/stylesheets/scss/tokens";

.labelWrapper {
  display: grid;
  row-gap: 8px;

  .label {
    @include type-lg-bold;
  }

  .description {
    @include type-lg;
  }
}

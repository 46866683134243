@import "src/assets/stylesheets/scss/tokens";

.providerHomeTasks {
  width: 100%;

  &Header{
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;

    span {
      @include type-h4;
      margin-right: 15px;
    }

    > a {
      color: $theme-accent-primary;
      transition: all 250ms ease;
      &:hover {
        color: $theme-text-primary;
      }
    }

    .tasksActionBlock {
      margin-left: auto;

      a {
        color: $theme-accent-primary;
        @include type-lg;
        transition: all 250ms ease;
        &:hover {
          color: $theme-text-primary;
        }
      }
    }
  }

  &Content {
    .providerTasksSectionContainer {
      :global {
        .cp-sidebr-section-header.flex-level{
          background: $theme-background-secondary;
          min-height: 46px;
          padding: 0 10px;
          margin-bottom: 5px;

          &:not(.is-static) {
            cursor: pointer;
          }

          &.is-static {
            margin-bottom: 0px;
          }

          > div {
            .medium-subtitle {
              color: $theme-text-primary;
              @include type-sm-bold-uppercase;
            }
            .small-subtitle {
              @include type-lg-bold;
              font-style: normal;
              font-stretch: normal;
              color: $theme-accent-primary;
            }
          }
        }
      }
    }
  }

  &EmptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;
    margin-bottom: 60px;

    span {
      color: $theme-accent-primary;
      @include type-lg-bold;
      margin-top: 15px;
    }

    button {
      margin-top: 24px;
    }
  }
}

@import "src/assets/stylesheets/scss/tokens";

.archiveModal {
  >p {
    @include type-lg;
    white-space: pre-line;
  }

  .offeringsBox {
    display: flex;
    gap: 6px;
    background-color: $theme-background-secondary;
    padding: 14px 12px;
    margin-top: 16px;

    .offeringsTitle {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        @include type-sm;
      }
    }

    >p {
      @include type-sm-bold;
      line-height: 17px;
      color: $theme-accent-primary;
    }
  }
}

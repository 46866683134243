@import "./src/assets/stylesheets/scss/tokens";

.sectionHeader {
	margin-bottom: 0.75rem;

	.md{
		border-bottom: 2px solid $theme-border-primary;
		padding: 0.75rem 0;
		.title {
			@include type-h4;
			color: $theme-text-primary;
		}
	}

	.sm{
		border-bottom: 1px solid $theme-border-primary;
		.title {
			@include type-lg-bold;
			color: $theme-text-primary;
		}
	}
}

.cols{
  display: flex;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
  &:not(:last-child) {
    margin-bottom: 0.5rem
  }

  .col{
    max-width: 100%;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75rem;

    &.isVAligned {
      display: flex;
      align-items: center;
    }

    &.colw3{
      flex: none;
      width: 25%;
    }
    &.colw8{
      flex: none;
      width: 66%;
    }
    &.colw1{
      flex: none;
      width: 8.3333333333%;
    }
  }
}

.marginTop{
  margin-top: 0.75rem;
}
@import "src/assets/stylesheets/scss/tokens";

.wrapper:global(.embedded-appointment-type-container){
  padding-top: 50px;
  display: block;
  max-width: 350px;
  margin: 0 auto;

  .header {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
    width: 100%;
    margin: 0 auto;
    display: block;
    color: $theme-text-primary;
  }

  .bodyMessage {
    width: 100%;
    margin: 0 auto;
    display: block;
    text-align: center;
    color: $theme-text-secondary;
    padding-bottom: 15px;
  }

  .selectField {
    margin-bottom: 15px;
  }

  .confirmButton {
    text-align: center;
  }

  .notListedNotification {
    padding-bottom: 15px;
  }
}

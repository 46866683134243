@import "src/assets/stylesheets/scss/tokens";

.clientMedicationsList {
  background-color: $theme-background-secondary;
  padding: 20px 30px;
  height: 50vw;
  .medicationRow {
    width: 568px;
    margin-bottom: 10px;
    padding: 15px;
    background-color: $theme-background-primary;
    @include shadow-sm;
    .content {
      .name {
        @include type-lg-bold;
      }
      .startDate {
        @include type-sm;
      }
      .directions, .location {
        @include type-sm;
        color: $theme-text-secondary;
        margin-top: 10px;
        display: flex;
        svg {
          margin-right: 2px;
        }
        div {
          line-height: 10px;
        }
      }
    }
  }
}
.healthRecords {
  h2 {
    @include type-lg;
  }
  h1 {
    @include type-h2;
  }
  .header {
    padding: 25px 30px;
    padding-bottom: 0;
  }
  .dashboardHeader {
    padding: 30px;
    padding-bottom: 10px;
    a {
      color: $theme-accent-primary;
      @include type-lg-bold;
      height: 21px;
      padding-bottom: 10px;
    }

    a:hover{
      border-bottom: 2px solid $theme-text-primary;
      a {
        color: $theme-text-primary;
      }
    }

    &:not(:last-child) {
      margin-right: 48px;
    }
    .active{
      border-bottom: 2px solid $theme-text-primary;
      a {
        color: $theme-text-primary;
      }
    }
  }
}

.clientFormMedicationSearch {
  :global {
    .field {
      display: flex !important
    }

    .squared-search-input.top-bar-search {
      min-width: 220px
    }

    .medication-search-results {
      width: 220px;
      padding: 1rem 0.25rem;
    }
  }
}

// Journal Entry Colors
$default: #eef9f5;
$metric: #fef7f6;
$mirror: #f9effb;
$workout: #fef4ee;
$sleep: #e9ecf1;
$note: #EBEBEB;
$poop: #EDECEB;
$waterIntake: #e7fafc;
$symptom: #FEE6F0;


.entryTypeContent {
  &.MetricEntry {
      background: $metric;
  }
  &.WorkoutEntry {
      background: $workout;
  }
}

.entryTitleContainer {
  background: $default;
  &.MetricEntry {
      background: $metric;
  }
  
  &.MirrorEntry {
      background: $mirror;
  }
  &.WorkoutEntry {
      background: $workout;
  }
  &.SleepEntry {
      background: $sleep;
  }
  &.NoteEntry {
      background: $note;
  }
  &.PoopEntry {
      background: $poop;
  }
  &.WaterIntakeEntry {
      background: $waterIntake;
  }
  &.SymptomEntry {
      background: $symptom;
  }
}



@import 'src/assets/stylesheets/scss/tokens';
@import 'src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins';

.itemBox {
    height: 135px;
    display: flex;
    margin: 1.25rem 0;
    align-items: center;
    border-radius: 10px;
    border: solid 1px $theme-border-primary;
    @include shadow-sm;

    @include mobile {
      height: fit-content;
      padding: 0.625rem 0;
      align-items: stretch;

      .intakeFlowImage {
          height: initial;
          margin: -0.625rem 2.5rem -0.625rem 0;
      }
    }

    .intakeFlowImage {
        width: 50px;
        height: 100%;
        display: flex;
        min-width: 50px;
        margin-right: 2.5rem;
        align-items: center;
        background: $theme-accent-primary;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        .intakeFormTypeContainer {
            left: 58px;
            width: 44px;
            height: 44px;
            display: flex;
            min-width: 44px;
            border-radius: 50%;
            position: absolute;
            background: $theme-background-primary;
            align-items: center;
            justify-content: center;
            border: 1px solid $theme-border-primary;

            @include mobile {
                left: 38px;
            }
        }
    }

    .intakeFlowMain {
        width: 100%;

        .intakeFormTitleContainer {

            .intakeFormTitleBox {
            display: flex;
            flex-direction: column;
            width: 75%;

                @include mobile {
                    margin-bottom: 0.625rem;
                    width: 100%;
                }

                .intakeFlowTitle {
                  color: $theme-text-primary;
                  cursor: pointer;
                  @includetype-lg-bold;
                  margin-right: 0.625rem;
                }

                .recurrenceIcon {
                  position: relative;
                  display: inline-block;
                  top: 6px;
                }

                .recurringRequestTooltip {
                  max-width: 400px;
                }

                .formDetails {
                    @include type-lg;
                    color: $theme-text-secondary;
                }
            }

            .intakeFlowRequestDetails {
                display: flex;
                gap: 8px;

                .intakeFlowRequestBox{
                  display: flex;
                  flex-direction: column;
                  min-width: 100px;
                }

                .intakeFormTitleCompletedContainer {
                  font-size: 11px;
                  line-height: 11px;
                  min-width: 100px;
                }

            }

        }

        .intakeFormStatusActionContainer {
            display: flex;
            align-items: center;
            gap: 1rem;
            justify-content: flex-end;

            .intakeFormStatusContainer {
              width: 105px;
              @include type-sm-bold;
              text-align: center;
              border-radius: 17px;
              display: inline-block;
              text-transform: uppercase;
              max-height: 25px;
              padding: 0.375rem 0;
            }

            .labelSkippedStatus {
              color: $theme-text-secondary;
              border: 1px solid $theme-border-primary;
            }

            .labelCompletedStatus {
                color: $theme-notice-bold-success;
                background: $theme-notice-soft-success;
            }

            .labelIncompleteStatus {
                color: $theme-notice-bold-warning;
                background: rgba(255, 90, 0, 0.1);
                border: 1px dashed $theme-notice-bold-warning;
            }

            .labelNotStartedStatus {
                color: $theme-white;
                background: $theme-notice-bold-warning;
            }

            .intakeFormAction {
              height: 25px;
              cursor: pointer;
              @include type-lg-bold;
              color: $theme-accent-primary;
            }

            .dotsSpace {
              width: 35px;
              display: inline-block;
            }

            .utilityBtnStyle {
              width: 85px;
              height: 40px;
              color: $theme-accent-primary;
              min-width: 85px;
              line-height: 40px;
              font-weight: 600;
              text-align: center;
              border-radius: 3px;
              background: transparent;
              border: 1px solid $theme-accent-primary;

              &:hover {
                  color: $theme-white;
                  background-color: $theme-accent-primary;
              }
            }

            .primaryBtnStyle {
              width: 85px;
              height: 40px;
              color: $theme-white;
              min-width: 85px;
              line-height: 40px;
              text-align: center;
              border-radius: 3px;
              background-color: $theme-accent-primary;
              border: 1px solid $theme-accent-primary;
              font-weight: 600;
              white-space: nowrap;

              &:hover {
                  color: $theme-accent-primary;
                  background: transparent;
              }
            }
        }
    }

    @media screen and (max-width: 1269px) {
      height: fit-content;
      padding: 0.625rem 0;
      align-items: stretch;

      .intakeFlowImage {
          height: initial;
          margin: -0.625rem 2.5rem -0.625rem 0;
      }

      .intakeFormAction {
        margin-left: 1rem;
      }
    }

    @media screen and (min-width: 1270px) {
      height: 75px;

      .intakeFlowMain {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .intakeFormStatusActionContainer {
          justify-content: space-between;
          width: 330px;

          .dotsSpace {
            width: 40px;
            display: inline-block;
          }

          .spaceButton {
            width: 52px;
            display: inline-block;
          }
        }
      }


      .intakeFormTitleContainer {
      width: 100%;
      display: flex;
      margin-bottom: 0;

        .intakeFlowTitle {
            width: 100%;
            line-height: 25px;
        }

        .intakeFormTitleCompletedContainer {
          width: 150px;
          line-height: 25px;
          text-align: center;
        }

        .intake-flow-dropdown-actions {
        width: 40px;
        }
      }
    }
}

.modalWrapper {
  .modalDocumentWrapper {
    background: $theme-background-secondary;
    border-radius: 5px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

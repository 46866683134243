@import 'src/assets/stylesheets/scss/tokens';

.listLabOrders {
  padding: 0 40px;

  table {
    th, td {
      border: none;
    }

    td {
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      text-align: left;
      vertical-align: middle;
    }
  }

  .tableHeader {
    tr {
      height: 40px;

      th {
        @include type-sm-bold-uppercase;
        color: $theme-text-secondary;
        vertical-align: middle;
        padding: 0.5em 0.75em;
        padding-bottom: 10px;
        line-height: 17px;
        text-align: left;

        svg {
          margin-left: 2px;
          padding-top: 1px;
          path {
            fill: $theme-accent-primary;
          }
        }

        &.activeCol {
          color: $theme-text-primary;
          svg {
            path {
              fill: $theme-text-primary;
            }
          }
        }
      }

      .nameCol {
        width: 30%;
      }

      .labCol {
        width: 15%;
      }
    }
  }
}

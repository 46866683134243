@import "src/assets/stylesheets/scss/_tokens";

.previousVersionContainer {
  width: 95%;
  padding: 0rem 0.5rem;
  margin: 1rem 1rem 3rem;
  border-radius: 10px;
}

.previousVersionContainer:hover {
  background-color: $theme-background-secondary;
}

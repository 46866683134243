@import "src/assets/stylesheets/scss/tokens";

.questionBankWrapper {
  border-left: 1px solid $theme-border-primary;
  padding: 1.5rem;
  text-align: left;
  height: 100%;
  overflow: scroll;

  .header {
    .titleText {
      @include type-lg-bold;
    }
    @include type-lg;

    .dropdownWrapper {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  .noCopiedQuestions {
    margin-top: 1rem;
  }
}
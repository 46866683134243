@import "./src/assets/stylesheets/scss/tokens";

.clientOverviewWrapper {
  padding: 0.75rem 1rem;
}

.dualRow {
  display: flex;
  min-height: 'min-content';
  gap: 0.5rem;
  margin-bottom: 1rem;
  .rowItem {
    flex: 1 1 0;
  }
}

.noBottomPadding {
  padding-bottom: 0;
}

.appointmentContent {
  // This is done so the empty state matches the height of the
  // AppsTabsContents scrollbox
  height: 250px;
}

.emptyClientOverview {
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    @include type-lg-bold;
    padding: 5rem;
    text-align: center;
  }
}

// This styling is global for all .field classes: https://github.com/healthie/web/pull/8952#discussion_r1126207217
.field {
		height: 49px; // total height of 35px input and 14px label. Error text is not taken into account, as it's absolutely positioned
		position: relative;
		display: flex;
		flex-direction: column;

		&:not(:last-child, .noBottomMargin) {
				margin-bottom: .75rem;
		}

		[class=react-datepicker-popper] {
				z-index: 3;
		}
}

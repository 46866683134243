@import "src/assets/stylesheets/scss/tokens";

.shareAndEmbedModalContent {
  .checkbox {
    padding: 0 25px;
  }

  .tabsContent {
    margin-bottom: 15px;
    border-bottom: 2px solid $theme-border-primary;

    .tabs {
      display: flex;
      padding: 35px 25px 0 25px;


      .tabLink {
        display: inline-block;
        min-width: 85px;
        color: $theme-text-secondary;
        text-align: center;
        margin-right: 12px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        position: relative;
        padding: 0 10px;
        top: 3px;
        cursor: pointer;
        @include type-lg;

        &Active {
          top: 2px;
          background-color: $theme-background-primary;
          border-bottom: 3px solid $theme-accent-primary;
          height: 30px;
          color: $theme-accent-primary;
          font-weight: 600;
          min-width: 130px;
        }
      }
    }
  }

  .linkContainer {
    padding: 0 25px;
    .calendarSharingLink {
      padding: 3px;
      width: 80%;
      user-select: all;
      border-radius: 3px;
      overflow-x: hidden;
      white-space: nowrap;
      -ms-user-select: all;
      -moz-user-select: all;
      -ms-overflow-style: none;
      -webkit-user-select: all;
      background: $theme-background-primary;
      border: 0.5px solid $theme-border-primary;
      border-color: $theme-accent-primary;

      span {
        @include type-lg;
        color: $theme-text-primary;
      }
    }

    .calendarSharingLink::-webkit-scrollbar {
      display: none;
    }

    .offeringCopyNote {
      padding: 15px 0 25px 0px;
      margin: 0;

      span {
        font-size: 11px;
        font-weight: 900;
      }

      .healthieActionLink {
        margin-left: 5px;
        text-decoration: underline;
        color: $theme-accent-primary;
        cursor: pointer;
      }
    }
  }

  .previewLinkContainer {
    background-color: $theme-background-secondary;
    height: 38px;
    width: 100%;
    border-bottom:unset;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 25px 0 25px;
    justify-content: space-between;

    .customMediumSubtitle {
      color: $theme-text-secondary;
      @include type-sm-bold-uppercase
    }

    .healthieActionLink {
      margin: 0 0 0 5px;
      vertical-align: middle;
      text-decoration: none;
      @include type-sm-bold-uppercase;
      color: $theme-accent-primary;
        svg {
          vertical-align: middle;
          margin-left: 5px;
          path {
            fill: $theme-accent-primary;
          }
        }
    }

    .healthieActionLink:hover {
      color: $theme-text-primary;
      svg {
        path {
          fill: $theme-text-primary;
        }
      }
    }
  }

  .offeringPreviewContainer {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin: 0 0 0 0;
    width: 100%;
    padding: 0 22px;
    height: auto;
    border: unset;

    .offeringSharePreview {
      width: 100%;
      max-height: 300px;
      display: inline-block;
      overflow: hidden;
      position: relative;
      background: url(../../../../assets/images/loading_indicator.gif) center center no-repeat;
      &.hideSpinner{
        background: none;
      }

      .iframePreview{
        position: relative;
        -ms-zoom: 0.40;
        -moz-transform: scale(0.40);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.40);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.40);
        -webkit-transform-origin: 0 0;
        pointer-events: none;
        iframe{
          width: 1460px;
          height: 900px;
        }
      }
    }
  }
}

.shareMultipleCheckboxContainer {
  margin: 10px 0;
}

@import "src/assets/stylesheets/scss/tokens";

*[class*='dropdown is-active'].tagFilterDropDown {
  *[class='dropdown-trigger'] {
    *[class='dropdown-title-container'] {
      & > div > [class^='Button_buttonWithTextAndIcon'] {
        background: $theme-accent-primary;
        border: 2px solid $theme-accent-primary;
        color: $theme-white;

        & > svg > path {
          fill: $theme-white;
        }
      }
    }
  }
}

.tagFilterDropDown {
  *[class='dropdown-title-container'] {
    & div > [class^='Button_buttonWithTextAndIcon']   {
      min-width: 105px;
      display: flex;
      height: 32px;
      border-radius: 17.5px;
      border: 1px solid $theme-accent-primary;
      padding: 0 15px;
      background: $theme-background-primary;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: $theme-accent-primary;
      font-size: 14px !important;

      & > svg > path {
        fill: $theme-accent-primary;
      }

      &:hover {
        color: $theme-accent-primary;
        background-color: $theme-background-hover;
      }
    }

    & > *[class='has-selections'] {
      & > [class^='Button_buttonWithTextAndIcon'] {
        border-color: $theme-accent-primary;
        color: $theme-accent-primary;
      }
    }
  }

  *[class='dropdown-menu'] {
    left: -87px;
    top: 117%;
    background: $theme-background-primary;
    border-radius: 0px;
    width: 280px;
    border: 1px solid $theme-border-primary;
    @include shadow-sm;

    *[class='healthie-dropdown-triangle'] {
      left: 50%;
      top: -4px;
      margin-top: -3px;
      margin-left: -8px;
      border-top: none !important;
      border-bottom-color: $theme-white;
      width: 1px;
      height: 0;
      position: absolute;
      box-sizing: content-box;
      border: 8px solid transparent;
    }
  }

  .hideDropdownButton {
    background-image: none;
    background-color: transparent;
    border: none;
  }

  .tagsDropdownForm {
    &Header {
      display: flex;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      border-bottom: 1px solid $theme-border-primary;

      span {
        &:first-child {
          @include type-lg-bold;
        }

        &:last-child {
          color: $theme-text-secondary;
          @include type-sm;
        }
      }
    }

    &Selections {
      padding: 16px 10px 16px 20px;

      .field {

        .custom-checkbox-container {

          .checkmark {
            border-radius: 3px;
            top: 5px;
          }
        }

        &:last-child .custom-checkbox-container {
          margin-bottom: unset;
        }
      }
    }

    &Buttons {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      background: $theme-background-primary;

      > button {
        background: $theme-background-primary;
        color: $theme-accent-primary;
      }

      > .field button {
        width: 106px;
      }
    }
  }
}
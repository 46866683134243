.valueContainer {
    display: flex;

    .valueContainerOption{
        display: flex;
        align-items: center;
        flex-flow: row-reverse;
    }

    .react-select__input-container {
        margin: 0 -0.125rem;
    }
}

@import '../../../../assets/stylesheets/scss/tokens';

.availabilityHeaderText {
    text-align: center;
}

.availabilityDateHeader {
    @include type-lg-bold;
    padding-bottom: 0.25rem;
    .availabilitySubHeader {
        @include type-sm;
    }
}
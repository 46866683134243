@import "src/assets/stylesheets/scss/tokens";

.formHistoryCreatorAvatar {
  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 50%;
  @include shadow-sm;

  display: flex;
  align-items: center;
  justify-content: center;

  .iconPlaceholder {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $theme-text-primary;

    >svg {
      margin: auto;
    }
  }
}

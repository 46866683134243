@import "src/assets/stylesheets/scss/tokens";

.formHistoryRow {

  &:not(:first-child) {
    border-top: 1px solid $theme-border-primary;
  }

  .formHistoryDate {
    color: $theme-text-secondary;
  }

  .formHistoryDetails {
    padding-left: 0.75rem;

    p:first-child > span {
      @include type-lg-bold;
    }

    p:last-child{
      color: $theme-text-secondary;
    }
  }
}

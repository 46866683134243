@import "../../../assets/stylesheets/scss/tokens";

.inputContainer {
		display: flex;
		align-items: center;
		.inputField {
				@include type-lg;
				min-height: 30px;
				border-radius: 3px;
				background-color: $theme-background-primary;
				border: 1px solid $theme-border-input;
				box-shadow: none;
				color: $theme-text-primary;
				box-sizing: border-box;
				max-width: 75px;
				width: 100%;
				padding: 0 10px;
				&::placeholder {
						color: $theme-text-secondary;
				}
				@media screen and (max-width: 769px - 1px) {
						min-height: 44px;
						border: 1px solid $theme-border-input;
						&::placeholder {
								color: $theme-text-secondary;
						}
				}
		}
		.inputSpan{
				margin: 0 10px;
		}
}

@import "assets/stylesheets/scss/tokens";
.quillDefault {
		.ql-toolbar.ql-snow {
				background: $theme-background-primary;
				border: 1px solid $theme-border-primary;
		}
		.ql-container{
				border: 1px solid $theme-border-primary;
		}
		.ql-editor {
				background: $theme-background-secondary;
		}
}

.quillWithBg {
		.ql-toolbar.ql-snow {
				background: $theme-background-secondary;
				border: 1px solid $theme-border-primary;
		}
		.ql-container{
				border: 1px solid $theme-border-primary;
				border-top: none;
		}
		.ql-editor {
				background: $theme-background-primary;
		}
}

.quillNoBorder {
		.ql-toolbar.ql-snow{
				border: none;
				border-top: 1px solid $theme-border-primary;
		}
		.ql-container{
				border: none;
		}
}

@import "src/assets/stylesheets/scss/tokens";

.addressFormSection {
  .section:not(:first-child) {
    margin-top: 30px;
  }
}
.addSectionButton {
  margin: 12px 0 -12px 0;
}

@import "src/assets/stylesheets/scss/tokens";

.afterBookingSectionContainer {
  margin-bottom: 1rem;

  .actionsListItem:not(:last-child) > div {
    border-bottom: 1px solid $theme-border-primary;
  }

  .actionsListItem {
    .actionsListItemName p:first-child {
      @include type-sm-bold-uppercase;
      color: $theme-text-primary;
      text-transform: uppercase;
      width: 100%;
    }
    .actionsListItemName p:not(:first-child) {
      @include type-sm;
      color: $theme-text-secondary;
      max-width: 49%;
      margin-right: 0.125rem;
    }
    .actionsListItemName p.separator {
      margin: 0 0.24rem;
    }
    .actionsListItemName p:nth-child(2) {
      @include type-sm-bold;
    }

    .scheduledContentListItemName {
      display: flex;
      flex-direction: column;

    }
    .scheduledContentListItemName p:first-child {
      @include type-sm-bold-uppercase;
      color: $theme-text-primary;
      text-transform: uppercase;
      width: 100%;
    }

    .scheduledContentListItemName p:not(:first-child) {
      @include type-sm;
      color: $theme-text-secondary;
      margin-right: 2px;
    }

    .scheduledContentListItemName p:nth-child(2) {
      @include type-sm-bold;
    }
  }
}

@import "src/assets/stylesheets/scss/tokens";

.paginationContainer {
  display: flex;
  justify-content: center;
  padding-top: 1.75rem;
  gap: 0.5rem;

  > button {
    padding: 0.5rem 0.75rem !important;
  }

  .dots {
    display: flex;
    align-items: center;
  }

  .dots:hover {
    cursor: default;
    background-color: transparent;
    color: $theme-accent-primary;
  }
}

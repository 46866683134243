@import 'src/assets/stylesheets/scss/tokens';

.labOrdersHeader {
  display: flex;
  flex-direction: column;
  margin: 27px 48px 20px 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid $theme-border-primary;
  gap: 20px;

  .titleBox {
    display: flex;
    justify-content: space-between;

    h2 {
      @include type-h3;
    }
  }

  

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      width: 100%;
      max-width: 347px;

      form[role=searh] {
        min-width: 152px;
      }
    }

    .filters {
      display: flex;
      gap: 16px;
    }
  }
}

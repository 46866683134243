@import "./src/assets/stylesheets/scss/tokens";

.upgradeForCms1500sWrapper {
  padding: 1.125rem 0;
  
  .headline {
    color: $theme-text-primary;
    @include type-h4;
  }
  
  .content {
    max-width: 400px;
    margin: 5rem auto 0;
  }

  .upgradeForCms1500sBox {

    &Title {
      color: $theme-accent-primary;
      @include type-h4;
      text-align: left;
    }

    &Text {
      color: $theme-text-secondary;
      @include type-lg;
      text-align: left;
    }
  }
}

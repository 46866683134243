@import "src/assets/stylesheets/scss/tokens";

.header {
  @include type-lg-bold;
  margin-bottom: 2rem;
}

.signatureWrapper {
  border: 1px solid var(--theme-border-primary);
  border-radius: 4px;
  padding: 1rem;
  margin: 0 1rem 1rem 0 !important;
  .name {
    @include type-lg-bold;
    width: 224px;
  }
  .date {
    @include type-sm;
  }
  .dropdownContainer {
    width: 40px;
  }
}

.modalSignatureWrapper {
  max-width: 340px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.bold {
  @include type-lg-bold;
  white-space: pre-wrap;
}

@import "./src/assets/stylesheets/scss/tokens";
.previewContainer {
  border: solid 1px $theme-border-primary;
  height: auto;
  margin: 0.5rem 0;
  padding: 0.75rem;
  &:hover {
    cursor: pointer;
  }
  .icon {
    text-align: right;
    img{
      width: 100%;
    }
  }
  .content {
    padding-left: 0.75rem !important;
  }
  .title {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include type-lg-bold;
    color: $theme-text-primary;
    text-align: left;
    margin-bottom: 0.5rem !important;
  }
  .contactType {
    display: flex;
    .image {
      height: 17px;
      padding-right: 0.12rem;
    }
  }
  .info {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@import "src/assets/stylesheets/scss/tokens";

$toggle-button-height: 2rem;
$toggle-button-width: 3.5rem;
$toggle-button-padding: REM(2);

.field {
		height: $toggle-button-height;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
				.label {
						color: $theme-text-primary;
						@include type-sm;
				}
				.toggleBtnInput{
						height: 0;
						width: 0;
						visibility: hidden;
						display: none;
				}
				.toggleBtnLabel {
						cursor: pointer;
						text-align: center;
						vertical-align: middle;
						border-radius: calc($toggle-button-height / 2);
						height: $toggle-button-height;
						width: $toggle-button-width;
						background: $theme-background-disabled;
						position: relative;
						&.disabled {
								background-color: $theme-background-disabled;
								cursor: not-allowed;
								opacity: 0.3;
								&.isSubmitting {
										cursor: wait;
								}

								&:after {
									color: transparent;
								}
						}
						&:after{
								content: 'OFF';
								position: absolute;
								top: $toggle-button-padding;
								left: $toggle-button-padding;
								transform: translateX(0);
								transition: transform 0.1s ease-out;
								background: $theme-white;
								border-radius: 50%;
								vertical-align: middle;
								color: $theme-text-secondary;
								@include type-sm-bold;
								box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
								height: calc($toggle-button-height - ($toggle-button-padding * 2));
								width: calc($toggle-button-height - ($toggle-button-padding * 2));
								display: flex;
								align-items: center;
								justify-content: center;
						}
						&.true {
								background: $theme-accent-primary;
								&:after{
									transform: translateX(calc($toggle-button-width - $toggle-button-height));
									content: 'ON';
									color: $theme-accent-primary;
								}
						}
				}
}

@import 'src/assets/stylesheets/scss/tokens';

.labOrderConnectModal {
  div > section {
    border-radius: 0px;
  }

  .labOrderConnectLink {
    display: flex;
    align-items: center;
    justify-content: center;
    height: REM(99);
    border: 1px solid $theme-border-primary;
    border-radius: 4px;
    margin: REM(16);
    @include shadow-sm;

    &.connected {
      pointer-events: none;

      span {
        display: flex;
        align-items: center;

        span {
          color: $theme-notice-bold-success;
          margin-left: REM(16);
        }
      }
    }

    &.connect {
      span {
        display: flex;
        align-items: center;

        span {
          color: $theme-notice-soft-alert;
          margin-left: REM(16);
        }
      }
    }
  }
}


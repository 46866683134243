@import "src/assets/stylesheets/scss/tokens";

.chatFilter {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;

  >div {
    display: flex;
    align-items: center;
    border-radius: 4px;
    height: 40px;
    padding: 0px 9px;
    cursor: pointer;

    &:hover:not(.activeFilter) {
      background: $theme-background-hover;
    }

    span {
      @include type-lg-bold;
      color: $theme-text-primary;
    }

    span:first-of-type {
      position: relative;
      top: 2px;
      margin-right: 10px;
    }

    span:last-of-type {
      margin-left: auto;
    }
  }

  .activeFilter {
    background: $theme-background-hover;


    span {
      @include type-lg-bold;
    }
  }
}
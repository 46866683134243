@import "assets/stylesheets/scss/tokens";

.previewBtn {
		background-color: $theme-background-secondary;
		height: 53px;
		display: flex;
		justify-content: center;
		cursor: pointer;
		padding: 5px;
		img{
				object-fit: contain;
		}
}
.tooltip {
		max-width: 400px;
}

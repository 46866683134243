@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.financialInformationContainer {
  width: 70%;


  @include tablet{
    width: auto;
  }

  @include mobile {
    width: auto;
  }
}

@import "src/assets/stylesheets/scss/tokens";


  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding-bottom: 16px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $theme-border-primary;
    margin-bottom: 24px;
    h5.modalHeaderTitle {
      @include type-h4;
      color: $theme-text-primary;
      margin-bottom: auto;
    }
    &.textOnly {
      justify-content: center;
      text-align: center;
    }
    .leftContent {
      display: flex;
      align-items: center;
      gap: 16px;
      svg {
        display: flex;
      }
    }
    .closeIcon:hover svg g {
      stroke: $theme-accent-primary;
    } 
    
    .backButton:hover {
      svg[data-testid='chevronLeft'] path {
        fill: $theme-accent-primary;
      }
    }
  }

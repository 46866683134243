@import 'src/assets/stylesheets/scss/_tokens.scss';

.noteTask {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 24px;
  padding: 0 4px;
  background-color: $theme-background-primary;
  border: 1px solid $theme-border-primary;
  border-radius: 12px;
  white-space: nowrap;
  cursor: pointer;

  @include type-sm-bold;
  color: $theme-text-secondary;

  &.add {
    display: none;

    &:hover {
      background-color: $theme-accent-primary;

      svg {
        path {
          fill: $theme-white;
        }
      }
    }
  }
}

.triggerTooltip {
    @include type-sm;
    border-radius: 0;
    padding: 8px 12px;
    margin-top: 6px;

    &:after {
      border-left-width: 6px;
      border-right-width: 6px;
      top: -5px;
      margin-left: -6px;
    }
  }

.pending {
  svg {
    path {
      fill: $theme-notice-bold-alert;
    }
  }
}

.complete {
  svg {
    path {
      fill: $theme-notice-bold-success;
    }
  }
}

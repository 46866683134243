@import "assets/stylesheets/scss/tokens";

.groupLabel {
	color: $theme-text-primary;
	@include type-sm-bold;
	margin-bottom: 0.5rem;
}
.groupSubLabel {
	color: $theme-text-secondary;
	@include type-sm;
	margin-bottom: 0.5rem;
}
.radioBtnGroup {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	&.isHorizontal {
		flex-direction: row;
	}
	.radioButtonContainer {
		display: inline-flex;
		max-width: REM(500);
		flex-direction: column;
		align-items: flex-start;
		gap: 8px;
	}
	
	// Radio button container
	.radioButton {
		cursor: pointer;
		user-select: none;
		-ms-user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		height: 100%;
		display: flex;
		align-items: flex-start;
		border: 1px solid transparent;
		border-radius: REM(4);
		margin-bottom: 0;
		&:focus{
			border: 1px solid $theme-accent-primary;
		}
		.radioBtnLabelContainer {
				display: flex;
				align-items: center;
				cursor: pointer;
				user-select: none;
				position: relative;
				margin: 6px 0;
				-ms-user-select: none;
				-moz-user-select: none;
				-webkit-user-select: none;
				.label {
						@include type-lg;
						color: $theme-text-primary;
				}
				i {
						margin-left: 8px;
				}
				.input {
						opacity: 0;
						cursor: pointer;
						position: absolute;
						margin-right: 3px;
				}
				.checkmark {
						width: 14px;
						height: 14px;
						background: $theme-background-input;
						border-radius: 50%;
						border: 1px solid $theme-border-input;
						margin-right: 5px;
						position: relative;
						&:after{
								left: 4px;
								top: 0.25em;
								width: 14px;
								height: 14px;
								border-radius: 50%;
								background: $theme-accent-primary;
								content: "";
								display: none;
								position: absolute;
						}
				}
				&.active{
						.checkmark{
								&:after{
										display: block;
								}
						}
				}
		}
		&.error {
			background: $theme-notice-soft-warning;
			border: 1px solid $theme-notice-bold-warning;
			.checkmark{
				&.active {
					&:after {
						background: transparent;
					}
				}
			}
		}
		.mobileRadioBtnContainer {
				overflow: auto;
				.radioBtnLabelContainer {
						.checkmark {
								width: 20px;
								height: 20px;
								&:after{
										left: 4px;
										top: 0.25em;
										width: 10px;
										height: 10px;
										border-radius: 50%;
										background: $theme-accent-primary;
								}
						}
				}
		}
		
		// Hide the browser's default radio button
		.radioInput {
			visibility: hidden;
			cursor: pointer;
			position: absolute;
			margin: auto 0;
		}
		
		// Create a custom radio button
		.checkmarkWrapper{
			display: flex;
			padding: 0.25rem;
			align-items: center;
			justify-content: center;
			.checkmark {
				width: 1rem;
				height: 1rem;
				background: $theme-background-primary;
				position: relative;
				border-radius: 50%;
				border: 1px solid $theme-text-primary;
				&:after {
					content: "";
					display: none;
					position: absolute;
				}
				&.active {
					&:after{
						display: block;
						left: REM(2);
						top: REM(2);
						width: REM(10);
						height: REM(10);
						border-radius: 50%;
						background: $theme-accent-primary;
					}
				}
			}
		}
		&.disabled {
			cursor: not-allowed;
			&:hover	{
				background: $theme-background-primary;
			}
			.label{
				color: $theme-text-primary;
			}
			.checkmark {
				border: 1px solid	$theme-border-primary;
				&:after{
					background: $theme-border-primary;
				}
				&.active {
					&:after {
						background: $theme-border-primary;
					}
				}
			}
		}
		.tooltipIcon{
			display: flex;
			width: 24px;
			height: 24px;
			padding: REM(2);
			justify-content: center;
			align-items: center;
		}
	}
}
@import "src/assets/stylesheets/scss/tokens";

.draggableQuestionTemplateWrapper {
  cursor: pointer;
  border: 1px solid $theme-border-primary;
  border-radius: 0.25rem;
  padding: 0.5rem 0 0.5rem 0.5rem;
  margin-top: 1rem;

  &.hoveredItem {
    background-color: $theme-background-secondary;
  
    input, span, div, table {
      background-color: $theme-background-secondary !important;
    }
  }
}
@import "src/assets/stylesheets/scss/_tokens";

.implantableDevice {
  border-radius: 3px;
  border: 1px solid $theme-border-primary;
  margin-bottom: 15px;

  .header {
    display: flex;
    align-items: center;
    background: $theme-background-secondary;
    height: 46px;
    padding: 0px .75rem;

    .name {
      @include type-lg;
      font-size: 16px;
    }

    .optionsWrapper {
      margin-left: auto;
    }
  }

  .details {
    padding: 12px;
    margin-bottom: 0;

    .label {
      @include type-lg-bold;
      color: $theme-text-secondary;
    }
  }
}


@import "src/assets/stylesheets/scss/tokens";

.importClientsAside {

    .asideTabContainer {
        div {
          max-width: 100%;
          > span {
            color: $theme-text-primary;
            @include type-lg;
            transition: all 250ms ease;
          }
        }
    }


    .asideTabContent {
        &Paragraph {
            p {
            color: $theme-text-secondary;
            @include type-lg;

            span {
                color: $theme-accent-primary;
                @include type-lg-bold;
            }
            }

            p:not(:last-child) {
            margin-top: 1.3rem;
            }

            hr {
            margin: 1rem 0;
            }
        }

        &FileSelector {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background: $theme-background-secondary;
            border-radius: 0px;
            height: 90px;
        }

        &Fields {
            padding: 1.3rem 0;
            textarea {
            height: 74px;
            min-height: 74px;
            }
        }

        &Buttons {
            justify-content: space-between;

            &Submit {
                button {
                    width: 281px;
                }
            }
        }

        &Info {
            margin-top: 1rem;
            color: $theme-accent-primary;
            @include type-lg-bold;
            height: 14px;
        }

    }

    .asideTabContentEmptyStateBox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 3.15rem;

        &Text {
            font-weight: 900;
            line-height: 22px;
            padding: 0.5rem;

            &Main {
                @include type-h4;
                color: $theme-notice-bold-success;
                text-align: center;
            }

            &Wrap {
                color: $theme-accent-primary;
                @include type-lg;
                text-align: center;
                margin-top: 1.6rem;
            }
        }


        &SubText {
            @include type-lg;
            color: $theme-text-secondary;
            text-align: center;
        }

        &Note{
            @include type-sm;
            color: $theme-text-secondary;
            text-align: center;
            margin-top: 1.6rem;
        }
    }
}

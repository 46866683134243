.mainLogo {
  max-height: 100px;
  max-width: 150px;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.sideBarClosedLogo {
  clip-path: inset(0 80% 0 0);
  max-height: 100px;
  max-width: 160px;
  object-fit: contain;
  position: absolute;
  left: 30%;
  top: 30%
}

.hidden {
  display: none;
}

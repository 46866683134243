@import "../../../assets/stylesheets/scss/tokens";
.externalCalendar {
  .externalCalendarItem  {
    padding: 8px 15px 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 15px;
    }
    span {
      color: $theme-accent-primary;
      @include type-lg-bold;
    }

    &:not(:last-child) {
      margin-bottom: 18px;
    }

    &:hover, &:active{
      background-color: $theme-background-secondary;
      @include type-sm;
      color: $theme-accent-primary;
    }
  }
}

@import "src/assets/stylesheets/scss/tokens";

.allCoursesHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .actions {
    display: flex;
    flex-wrap: wrap;
    gap: 26px;
    align-items: center;
    padding: 32px 40px 0 40px;

    h2 {
      @include type-h3;
    }

    .searchBox {
      max-width: 197px;

      margin-right: auto;
    }
  }

  .tabs {
    >div {
      margin-bottom: 0px;

      div:first-child {
        margin-left: 34px;
      }
    }
  }
}

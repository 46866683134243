@import "src/assets/stylesheets/scss/tokens";
.copyUrlToClipboardButton {
  @include type-lg;
  display: inline-flex;
  color: $theme-accent-primary;
  min-width: 85px;
  cursor: pointer;
  margin-left: 10px;
  padding: .625rem .45rem;
  border-radius: 3px;
  background: transparent;
  border: 1px solid $theme-accent-primary;
  @include type-lg-bold;
  svg {
    top: 3px;
    margin-right:6px;
    position: relative;
  }

  &:hover {
    color: $theme-white;
    background-color: $theme-accent-primary;

    svg path {
      fill: $theme-white;
    }
  }
}

@import "../../../assets/stylesheets/scss/tokens";

@-ms-keyframes spin {
		from { -ms-transform: rotate(0deg); }
		to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
		from { -moz-transform: rotate(0deg); }
		to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
		from { -webkit-transform: rotate(0deg); }
		to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
		from {
				transform:rotate(0deg);
		}
		to {
				transform:rotate(360deg);
		}
}

.container{
		box-sizing: border-box;
		clear: both;
		font-size: 1rem;
		position: relative;
		text-align: left;
		&.center{
				text-align: center;
		}
}
.btn{
		@include type-lg-bold;
		border-radius: 3px;
		border-width: 1px;
		border-style: solid;
		border-color: transparent;
		height: 2.5rem;
		text-align: center;
		margin: 0;
		padding: 0.5rem 1rem;
		box-sizing: border-box;
		outline: none;
		cursor: pointer;
		white-space: nowrap;
		box-shadow: none;
		display: inline-block;
		-moz-appearance: none;
		-webkit-appearance: none;
		align-items: center;
		&:hover{
				cursor: pointer;
		}
		&.primary {
				background-color: $theme-accent-primary;
				color: $theme-white;
				border-color: $theme-accent-primary;
				padding-right: 20px;
				padding-left: 20px;
				&:hover, &:active, &:focus{
						background-color: $theme-accent-primary-hover;
						border-color: $theme-accent-primary-hover;
				}
		}
		&.isLoading, &.isLoading:hover, &.isLoading:active, &.isLoading:focus {
				border-color: $theme-background-disabled !important;
				background: $theme-background-disabled !important;
				color: $theme-white !important;
				cursor: not-allowed;
				position: relative;
				&:after{
						-webkit-animation: spin 500ms infinite linear;
						animation: spin 500ms infinite linear;
						border: 2px solid $theme-border-primary;
						border-radius: 290486px;
						border-right-color: transparent;
						border-top-color: transparent;
						content: "";
						display: block;
						height: 1em;
						position: absolute;
						width: 1em;
						top: 10px;
						left: 5px;
				}
		}
}

.link {
		background-color: transparent;
		border-color: transparent;
		color: $theme-text-secondary;
		text-decoration: underline;
		border-width: 1px;
		cursor: pointer;
		justify-content: center;
		padding-bottom: calc(0.375em - 1px);
		padding-left: 0.75em;
		padding-right: 0.75em;
		padding-top: calc(0.375em - 1px);
		text-align: center;
		white-space: nowrap;
		height: 30px;
		border-radius: 5px;
		@include type-sm-bold;
		-webkit-appearance: none;
		align-items: center;
		box-shadow: none;
		display: inline-flex;
		vertical-align: top;
		&:hover{
				background-color: $theme-background-secondary;
				color: $theme-text-secondary;
		}
}
@import "../../../assets/stylesheets/scss/tokens";
@import "../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.splitButton {
  > div {
    display:inline-flex;
    position:relative;
    height: 40px;
    > button:first-of-type {
      border-radius:3px 0px 0px 3px;
    }
    > button:last-of-type {
      border-radius:0px 3px 3px 0px;
      margin: 0;
    }
  }
  &.primary {
    > div > button:first-of-type {
      border-right:1px solid $theme-white;
    }
  }
  &.secondary {
    > div > button:first-of-type {
      border-right:none;
    }
  }
  &.tertiary > div {
      grid-gap:1px;
  }
 
  &.lg, &.md {
    > div > button:last-of-type {
      padding:10px;
    }
  }
  &.sm {
    > div > button:last-of-type {
      padding:8px;
    }
  }
  &.fullWidth, &.fullWidth > div, &.fullWidth > div > button:first-child {
    width: 100%;
  }
}

.multiActionTriangleWrapper {
  z-index: 20;
  position:absolute;
  top:100%;
  margin-top:10px;
  right:0;

  &.triangleHidden {
    margin-top:0px;
    right:unset;
  }
  > .healthieDropdownTriangle {
    border-top:none;
    border-bottom-color: $theme-white;
  }

  @include only-for-safari {
    z-index: auto;
  }

  .healthieDropdownContent {
    @include only-for-safari {
      border:none;
    }
  }
}

.healthieDropdownContent{
  box-shadow: none;
  -webkit-box-shadow: none;
  white-space: nowrap;
  border-radius: 0;
  border: 1px solid $theme-border-primary;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: $theme-background-primary;
  width: 100%;
  a {
    @include type-sm;
    color: $theme-text-primary;
    margin: 0;
    padding: 8px 15px 8px 12px;
    width: 100%;
    display: block;

    &:hover, &:active{
      background-color: $theme-background-secondary;
      @include type-sm-bold;
    }
  }
 
  a.is-danger{
    color: $theme-notice-bold-warning;
  }
  div {
    position:relative;
    z-index:1;
  }
}

.healthieDropdownTriangle {
  top: 0;
  right:10%;
  margin-top: -3px;
  margin-left: -8px;
  width: 1px;
  height: 0;
  position: absolute;
  box-sizing: content-box;
  border: 8px solid transparent;
  &::before {
    content: "";
    top: -9px;
    height: 0;
    width: 1px;
    position: absolute;
    box-sizing: content-box;
    border: 8px solid transparent;
    left: -8px;
    z-index: -1;
    border-width: 8px;
    border-bottom-color: rgba(0,0,0,.25);
  }
}

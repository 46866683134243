@import "src/assets/stylesheets/scss/tokens";

.container {
  border: 1px solid $theme-border-primary;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .planName {
    @include type-lg-bold;
  }
  .planInfo {
    @include type-lg;
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.sideBarContainer {
  background-color: $theme-background-primary;
  min-height: 80vh;
  border-right: 2px solid $theme-border-primary;
}

.activeTab {
  background-color: $theme-background-hover;
  color: $theme-accent-primary;
}

.navButton{
  height: 2rem;
  cursor: pointer;
  &:hover {
    background-color: $theme-background-hover;
    color: $theme-text-primary;
  }
}

.navButtonText {
  margin-left: 2rem;
  //text-transform: uppercase;
  //letter-spacing: 0.18em;
}

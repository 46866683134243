@import "./src/assets/stylesheets/scss/tokens";

.emptyStateBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding-top: 50px;

  .illustrationIconContainer {
    svg {
      margin-bottom: 12px;
    }
  }

  &Text {
    color: $theme-text-primary;
    @include type-lg-bold;
    text-align: center;
    margin-bottom: 10px;
    &Main {
      color: $theme-text-primary
    }
  }

  &SubText {
    color: $theme-text-secondary;
    @include type-lg;
    text-align: center;
  }
}

@import '../../../../assets/stylesheets/scss/tokens';

.container {
    display: flex;
    background: $theme-background-primary;
    border-bottom: 1px solid $theme-border-primary;
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 9;

    .backButton {
      border-right: 1px solid $theme-border-primary;
      display: grid;
      height: 100%;
      margin-right: 15px;
      place-content: center;
      width: 46px;

      span svg {
        cursor: pointer;
      }
    }

    .breadCrumbs {
      display: flex;
      height: 100%;

      .breadCrumb {
        display: flex;
        align-items: center;
        height: 100%;

        &.active {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            height: 2px;
            width: 100%;
            bottom: 14px;
            background-color: $theme-text-primary;
          }

          .name {
            cursor: default;
            color: $theme-text-primary;
            text-transform: capitalize;
          }
        }

        &:not(:last-child) {
          &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-size: 14px;
            content: "\F054";
            color: $theme-text-secondary;
            margin: auto 20px;

            width: 8px;
            height: 18px;
          }
        }

        .name {
          color: $theme-accent-primary;
          @include type-lg-bold;
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 1em;

      .dropdown-menu {
        left: -200px;

        .healthie-dropdown-triangle {
          left: 285px;
        }
      }

      .button {
        background-color: $theme-accent-primary !important; // overriding another !important :(

        &:hover, &:focus {
          background-color: $theme-accent-primary !important;

          svg g g {
            fill: $theme-white;
          }
        }

        svg {
          margin-bottom: -1px;
          margin-right: 5px;
          margin-left: -5px;
        }
      }
    }

    .large-title {
      color: $theme-text-primary;
      @include type-h2;
      height: 36px;
    }
}

@import "src/assets/stylesheets/scss/tokens";

.wrapper {
  padding: REM(24);

  .headline {
    @include type-lg-bold;
    margin-bottom: REM(8);
  }
  
  .subcopy {
    @include type-lg;
    max-width: 803px;
    margin-bottom: REM(40);
  }
}
// Make ErrorAdapter absolutely positioned to avoid shifting
// up bottom-aligned element (e.g., gender identity)
.absoluteErrorAdapter {
  margin-bottom: 0.5rem;

  + [data-error-adapter="true"] {
    position: absolute;
    top: calc(100% - 0.5rem);
  }
}

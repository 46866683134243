@import 'src/assets/stylesheets/scss/tokens';

.wrapper {
  color: $theme-text-primary;

  .title {
    @include type-sm;

    margin-bottom: REM(4);
  }

  .items {
    display: grid;
    row-gap: REM(8);
  }
}

@import "src/assets/stylesheets/scss/tokens";

.downloadResult {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 3px;
  background-color: $theme-background-secondary;
  transition: 0.3s;

  &:hover {
    background-color: $theme-accent-primary;
    svg path {
      fill: $theme-white;
    }
  }
}

@import "../../assets/stylesheets/scss/tokens";
.productQuantityBox {
  display: flex;
  background: $theme-background-secondary;
  border-radius: 15px;
  width: 100%;
  padding: 10px;
  .iconContainer {
    width: 28px;
    height: 28px;
  }
  .infoWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .productQuantity {
      color: $theme-text-primary;
      @include type-h2;
      margin-left: 10px;
    }
    .productName{
      color: $theme-text-secondary;
      @include type-sm-bold-uppercase;
      margin-left: 10px;
    }
  }
}

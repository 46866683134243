@import "./src/assets/stylesheets/scss/tokens";

.chartNoteLink {
  a {
    display: block;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis;
    @include type-lg-bold;
  }
}

@import "../../../../assets/stylesheets/scss/tokens";

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.basePulsingLoader {
  width: 100%;
  height: 100%;
    :global #pulse-loader-text {
    margin-top: 20px;
    margin-left: 6px;
      span {
        color: initial;
        @include type-sm;
      }
    }
}
.initialLoaderContainer {
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .initialLoader {
    border: 16px solid $theme-border-primary;
    border-radius: 50%;
    border-top: 16px solid $theme-accent-primary;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    .loaderText {
      display: block;
    }
  }
}


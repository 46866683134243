@import "src/assets/stylesheets/scss/_tokens";

.copyButtonContainer {
  display: flex;

  .copyButtonSuccessContainer {
    display: flex;
    @include type-sm-bold;
    color: $theme-accent-primary;
    background-color: $theme-background-secondary;
    border-radius: 5px;
    padding: 5px 5px;
    width: 20%;
    margin-left: 10px;
    justify-content: center;
    align-items: center;

  }
}
@import "../../../assets/stylesheets/scss/tokens";
@import "../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";


@media print, screen and (max-width: 769px) {
  .dateColumns {
    flex-direction: column;
    align-items: center;
    > .buttonContainer {
      display:flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 7.5px;
    }
  }
  .submitContainer {
    align-self:flex-end;
  }
}

.healthieDateRangeSelector {
  .triggerText {
    cursor: pointer;
    color: $theme-accent-primary;
  }
  .dateColumns button:not(.dateRangeButton) {
    float:right;
  }
  :global {
    div#modal-content {
      width: 750px !important;
      max-height: 600px;
      @include only-for-ie{
        width:auto !important;
      }
    }
  }
}



.dateColumns {
  margin-top: -0.75rem;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  display:flex;
  &:last-child {
    margin-bottom: -0.75rem;
  }
  .dateColumn {
    .dateRangeButton {
      @include type-lg;
      color: $theme-text-primary;
      background-color: $theme-background-primary;
      border-radius: 5px;
      width: 120px;
      margin: 8px 0 0;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      border: 1px solid transparent;
      border-color: rgba(0,0,0,.05);
      position: relative;
      vertical-align: top;
      justify-content: center;
      padding: calc(.375em - 1px) .75em;
      white-space: nowrap;
      &:hover {
        background-color: $theme-background-hover;
        @include type-lg-bold;
      }
      &.selected {
        color: $theme-white;
        background: $theme-accent-primary;
        border-color: $theme-accent-primary;
  
      }
    }
  }
}



.dateColumn {
  max-width: 100%;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0.75rem;
  .subtitle {
    @include type-lg;
    color: $theme-text-primary;
  }
}

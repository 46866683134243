@import "./src/assets/stylesheets/scss/tokens";

.headerContainer {
		background: $theme-background-primary;
		border-bottom: 1px solid $theme-border-primary;
		height: 60px;
		position: sticky;
		top: 0;
		z-index: 9;
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		.leftContainer{
				display: flex;
				align-items: center;
				height: 100%;
				padding-right: 10px;
				width: fit-content;
		}
		.rightContainer {
				height: inherit;
				display: flex;
				align-items: center;
				margin-right: 10px;
				width: fit-content;
				justify-content: flex-end;
		}
}

@import "./src/assets/stylesheets/scss/tokens";

.labOrderForm {
  padding: 0;
}

.datePicker {
  :global {
    .react-datepicker-wrapper  {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}

.clientCol {
  width: 100%;
  padding: 0.75rem 0;
}

.fieldPadding {
  padding-bottom: 1.5rem;
  margin-bottom: 0;
}

.icdField {
  padding-bottom: 0;
}

.reviewerWrapper {
  width: 100%;
  input {
    width: 100%;
  }
}

.summaryEntries {
  padding-bottom: 1.5rem !important;
  li {
    margin-left: 2rem;
  }
}


@import 'src/assets/stylesheets/scss/tokens';

.error {
  color: $theme-notice-bold-warning;
  a {
    color: $theme-notice-bold-warning !important;
  }
}

.labStatus {
  display: flex;
  align-content: left;
}

.critical {
  @include type-lg-bold;
  color: $theme-notice-bold-warning;
  a {
    @include type-lg-bold;
    color: $theme-notice-bold-warning !important;
  }
}

.orderLabelContainer {
  display: flex;
  align-content: center;
}

.labOrderViewTooltip {
  width: 300px;
}
.noHeight {
  height: auto
}

.alignBottom {
  align-content: end;
}

.toggleBtn {
  flex-direction: column;
  align-items: start;
  height: auto;
  padding: 0px;
  span {
    margin-bottom: .25rem;
  }
}

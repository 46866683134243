@import 'src/assets/stylesheets/scss/tokens';

.multiFilterLabel {
  display: flex;
  width: max-content;
  height: 34px;
  border-radius: 17.5px;
  align-items: center;
  border: 1px solid $theme-accent-primary;
  padding: 0 15px;
  background: $theme-background-primary;
  cursor: pointer;

  @media(max-width: 1236px) {
    padding: 0 5px;

    > span {
      font-size: 10px !important;
      line-height: 15px !important;
    }

    > svg {
      margin-right: 5px !important;
    }
  }

  > svg path:last-of-type {
    fill: $theme-accent-primary;
  }

  &.active {
    background: $theme-accent-primary;
    border: 1px solid $theme-accent-primary;

    > svg path:last-of-type {
      fill: $theme-white;
    }
  
    > span {
      &:first-child #close-icon {

        &:hover svg g g {
          stroke: $theme-accent-primary;
        }

        svg g g {
          stroke: $theme-white;
        }
      }

      &:last-child {
        color: $theme-white;
      }
    }
  }

  &.hasSelection {
    border-color: $theme-accent-primary;

    > span {

      &:first-child {
        margin-right: 11px;
      }

      &:last-child {
        color: $theme-accent-primary;
      }
    }
  }

  &.active.hasSelection {
    > span {
      svg g g {
        stroke: $theme-white;
      }

      &:last-child {
        color: $theme-white;
      }
    }
  }

  > svg {
    margin-right: 10px;
  }

  > span {
    color: $theme-accent-primary;
    @include type-lg-bold;
  }

  &:hover:not(.active) {
    background: $theme-background-hover;
    border: 1px solid $theme-accent-primary;

    > svg path:last-of-type {
      fill: $theme-accent-primary;
    }

    > span {
      &:first-child:hover #close-icon svg g g  {
        stroke: $theme-accent-primary;
      }

      &:last-child {
        color: $theme-accent-primary;
      }
    }
  }

  .resetTrigger {
    display: flex;
    align-items: center;
    color: $theme-accent-primary;

    >div {
      height: 16px;
    }

    >svg {
      g > g {
        stroke-width: 1;
      }
    }

    &:hover {
      >svg {
        g > g {
          stroke: $theme-text-primary;
        }
      }
    }
  }
}
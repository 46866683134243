@import "assets/stylesheets/scss/tokens";

.previewEntryImage {
		width: 100%;
		background: $theme-background-secondary;
		border-radius: 3px;
		height: 126px;
		display: flex;
		justify-content: center;
		cursor: pointer;
		
		.previewEntryImageWrapper {
				margin: auto;
				display: flex;
				justify-content: center;
				align-content: center;
		}
		
		.btnText {
				color: $theme-accent-primary;
				@include type-lg-bold;
				align-self: center;
				margin-left: 10px;
		}
}

.previewBox {
		border-radius: 3px;
		height: 106px;
		width: 106px;
		text-align: center;
		
		img {
				object-fit: contain;
				height: 100%;
		}
}

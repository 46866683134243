@import 'src/assets/stylesheets/scss/tokens';

.prescriptionSectionWidget {
  background: $theme-background-primary;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid $theme-border-primary;
  padding: 20px 0;
  margin-bottom: 0.75rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .left {
    .title {
      @include type-lg-bold;
      color: $theme-text-primary;
    }
  }

  .right {
    display: flex;
    align-items: center;

    a, button {
      @include type-sm-bold-uppercase;
    }
  }

  .tooltipWidth {
    width: 200px;
  }
}

.asideTabContainer {
  .prescriptionSettings {
    .cancelPrescribing {
      justify-content: center;
      display: flex;
      margin-top: 15px;
    }

    .disclaimer {
      color: $theme-text-secondary;
      @include type-sm;
      padding: 25px;
    }
  }

  .dosespotStatus {
    height: 70px;
    background: $theme-notice-soft-success;
    border-radius: 0px;
    display: flex;
    div {
      color: $theme-notice-bold-success;
      @include type-h4;
    }
    .key {
      margin: auto auto auto 25px;
    }
    .value {
      margin: auto 25px auto auto;
      @include type-lg-bold;
    }
  }

  .modalButtonContainer {
    width: 320px;
    margin: 20px auto auto auto;
    text-align: center;
  }
}

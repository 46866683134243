@import "src/assets/stylesheets/scss/tokens";

.appointmentRequestsList {

  .appointmentRequestItem {
    border: 1px solid $theme-border-primary;
    height: 100px;
    border-radius: 10px;
  }
}

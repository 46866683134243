@import "../../../../assets/stylesheets/scss/tokens";
.field {
	box-sizing: border-box;
	clear: both;
	position: relative;
	text-align: left;
}
.radioBtnLabelContainer {
	display: flex;
	height: 55px;
	align-items: center;
	overflow: hidden;
	cursor: pointer;
	.input {
		opacity: 0;
		cursor: pointer;
		position: absolute;
		margin-right: 3px;
	}
	.checkmark {
		width: 14px;
		height: 14px;
		background: $theme-background-primary;
		border-radius: 50%;
		border: 1px solid $theme-border-primary;
		position: relative;
		&:after{
				left: 4px;
				top: 0.25em;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background: $theme-accent-primary;
				content: "";
				display: none;
				position: absolute;
		}
	}
	&.active {
		.checkmark{
			&:after{
				display: block;
			}
		}
	}
	.col1 {
		display: block;
		margin: 0;
		padding: 0;
		flex: none;
		width: 8.33%;
	}
	.col2 {
		display: block;
		margin: 0px 1rem 0 0.15rem;
		padding: 0;
		flex: none;
		width: 16.67%;
	}
	.radioBtnLabel {
		display: block;
		margin: 0;
		padding: 0;
		flex: none;
		width: 75%;
		color: $theme-text-primary;
		text-align: left;
		@include type-lg;
	}
}
.desktopRadioBtnContainer {
	overflow: auto;
	.radioBtnLabelContainer {
		.checkmark{
			&:after{
				left: 2px;
				width: 8px;
				top: 0.15em;
				height: 8px;
			}
	}
	}
}
.mobileRadioBtnContainer {
	overflow: auto;
	.radioBtnLabelContainer {
		.checkmark {
			width: 20px;
			height: 20px;
			&:after{
				left: 4px;
				top: 0.25em;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: $theme-accent-primary;
			}
	}
	}
}

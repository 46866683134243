@import "./src/assets/stylesheets/scss/tokens";

.deleteAppointmentModal {
    &#modal:global {
      z-index: 1200;
      > div + div#modal-content {
        margin: 3rem auto 0px auto;
      }
    }
    &Content {
        .confirmationText {
          display: flex;
          flex-direction: column;
          p {
            color: $theme-text-secondary;
            @include type-lg;
            text-transform: none;
            &.radio-button-label{
              @include type-lg-bold;
            }
          }

          > p:nth-child(2) {
            margin: 1.8rem 0 0;
          }
          
          > p:last-child {
            margin: 1.8rem 0 0 0;
          }
          
          > div {
            margin-bottom: 0.62rem;
          }
        }
    }
}

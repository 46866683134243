@import "assets/stylesheets/scss/tokens";
.headerOption {
  width: calc(100% - 40px);
  margin: 0 26px;
  border-bottom: 1px solid $theme-border-primary;
  align-self: flex-end;
  display: flex;
  align-items: center;
  height: 35px;

  .addFavCode {
    margin-left: auto;
    color: $theme-accent-primary;
    @include type-lg;
    text-transform: unset;
    letter-spacing: unset;
    margin-right: 10px;
    cursor: pointer;
  }
  span.headerText {
    text-transform: uppercase;
    margin-left: unset;
    color: $theme-text-secondary;
    @include type-sm-bold;
    margin-left: 0;

    &:first-child {
      min-width: 60px;
    }
  }
 
}

.updatedSelectOption {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 0.5em;
  padding-right: 20px;
  margin-left: 26px;
  padding-left: 0px;

  .favCode {
    margin-right: 5px;
  }

  .deleteFavCode {
    margin-left: 15px;
  }

  &.isLastFavorite {
    border-bottom: 1px solid $theme-border-primary;
    margin-right: 26px;
  }

  span {
    &:first-child {
      margin-left: 26px;
      min-width: 45px;
      margin-right: 15px;
      color: $theme-text-secondary;
      @include type-lg;
    }

    &:last-child {
      color: $theme-text-primary;
      @include type-lg;
    }
  }


  &:hover {
    background-color: $theme-background-secondary;
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.mutationFormContainer {
  padding: 0.9375rem 0;

  .divider {
    border: 1px solid $theme-border-primary;
    margin-bottom: 1.2rem;
  }

  .mutationFormBtn {
    margin: 1rem 0;
    text-align: right;
  }
}

@import "src/assets/stylesheets/scss/_tokens";

.displayLabOrderContainer {
  margin-top: 25px;
  padding: 10px;
  min-height: 35vh;
  justify-content: space-around;
  align-items: center;
  
  .displayLabOrderTitle {
    @include type-h4;
    margin-bottom: 25px;
  }

  .displayLabOrderMainInfo {
    width: 100%;
    padding: 10px;

    .displayLabOrderInfoItem {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

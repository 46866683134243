@import "assets/stylesheets/scss/tokens";
.labelContainer {
		display: flex;
		align-items: center;
}

.tooltip {
		vertical-align: middle;
		padding-top: 3px;
		margin: 0 .63rem 0 .125rem;
		display: inline-flex;
		align-items: center;
}
.hr {
		margin: 15px auto;
		height: 1px;
		background: $theme-border-primary;
		width: 350px;
}

.fileName {
		@include type-lg;
}

.btnContainer {
		margin: 10px 0;
}

@import 'src/assets/stylesheets/scss/tokens';

.dropdownItem {
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: $theme-accent-primary;
  display: flex;
  width: 100%;
  min-width: 134px;
  align-items: center;
  padding: 0.75rem;
  @include type-lg;
  /* Overriding the font-weight set by chmln-stylesheet-custom */
  font-weight: 400 !important;

  svg {
    margin: 0 0.3125rem;
  }
  &.sm {
    @include type-sm;
  }
  &.md {
    @include type-lg;
  }
  &.default {
    color: $theme-accent-primary;
    &:hover, &:active {
      background-color: $theme-background-hover;
      color: $theme-accent-primary;
    }
  }
  &.danger {
    color: $theme-notice-bold-warning;
    &:hover, &:active {
      background-color: $theme-notice-soft-warning;
      color: $theme-notice-bold-warning;
    }
  }
  &.disabled {
    background-color: $theme-background-disabled;
    color: $theme-text-secondary;
    &:hover, &:active, &:focus{
      cursor: default;
      background-color: $theme-background-disabled;
      color: $theme-text-secondary;
    }
  }
}
.separator {
  padding: 0 12px;
  height: 1px;
  background-color: $theme-background-secondary;
  margin: 0 0.75rem 0.5rem 0.75rem;
}


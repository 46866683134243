@import "../../../assets/stylesheets/scss/tokens";
@import "../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.mobileBtn {
		@include type-lg-bold;
		border-radius: 3px;
		border-width: 1px;
		border-style: solid;
		border-color: $theme-accent-primary;
		text-align: center;
		margin: 0;
		padding-top: 8px;
		padding-bottom: 8px;
		box-sizing: border-box;
		outline: none;
		cursor: pointer;
		white-space: nowrap;
		box-shadow: none;
		display: inline-block;
		-moz-appearance: none;
		-webkit-appearance: none;
		align-items: center;
		background: $theme-accent-primary;
		color: $theme-white;
		&:hover, &:active, &:focus {
				background: $theme-accent-primary;
				border-color: $theme-accent-primary;
				color: $theme-white;
		}
		// Used on mobile forms for button that jumps to next field
		&.nextFieldBtn {
				@include mobile {
						display: none;
						position: absolute;
						bottom: 5px;
						right: 5px;
						width: 3.5rem;
				}
		}

		@include tablet {
			display: none !important;
		}
}

@import "src/assets/stylesheets/scss/tokens";


.tasksDropdownContent {
  width: REM(430);
  min-height: REM(430);
  @include shadow-sm;
  border-radius: 3px;
  padding: 0px !important;
  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: none;

  &.dropdownFormView {
    background: $theme-background-primary;
    padding: 1rem;
  }

  @media(max-width: 428px) {
    width: 100%;
    height: 87%;
    position: fixed;
    left: 0;
    overflow-y: scroll;
  }

  .taskListHeader {
    background: $theme-background-primary;
    border-radius: 3px 3px 0px 0px;
    height: REM(71);
    width: REM(430);

    .placeholder{
      width: 380px;
      height: 20px;
      color: $theme-text-secondary;
      @include type-lg;
      padding: 10px 26px;
    }

  }

  .taskDropdown {
    .taskDropdownHeader {
      .listToggle {
        display: flex;
        height: REM(34);

        .listViewHeader  {
          width: 50%;
          @include type-lg;
          color: $theme-text-primary;
          text-align: center;
          margin-top: 9px;
          height: 100%;
          cursor: pointer;
          z-index: 1;
        }

        .selected {
          width: 215px;
          color: $theme-accent-primary;
          @include type-lg-bold;
          text-align: center;
          border-bottom: 1px solid $theme-accent-primary;
        }
      }
    }

    .detailsLink {
      width: 100%;
      height: 100%;
      padding: 0 2px 2px 2px;
      margin-top: 10px;
    }

    .healthieButton {
      @include type-lg-bold;
      margin: 3px 0;
      border-radius: 3px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;
      text-align: center;

      box-sizing: border-box;
      outline: none;
      cursor: pointer;
      white-space: nowrap;
      box-shadow: none;
      display: inline-block;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      align-items: center;

      &.primaryButton {
        background-color: $theme-accent-primary;
        color: $theme-white;
        border-color: $theme-accent-primary;

        &:hover {
          background-color: $theme-accent-primary;
          color: $theme-white;
          border-color: $theme-accent-primary;
        }
      }

      padding: 8px 20px;

      span{
        @include type-lg-bold;
      }
    }
  }
}

.listTasks {
  padding: 12px;
  height: 272px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media(max-width: 428px) {
    height: 100%;
  }

  .complete {
    .taskRowContent {
      text-decoration: line-through;
    }
    >:not(.react-tooltip){
      opacity: 0.55;
    }
  }

  .headerTasks {
    min-height: 90px;
    opacity: .99;
    cursor: pointer;

    &.highPriority {
      border-left:$theme-notice-bold-warning solid !important;
      .headerCompleteMarker {
        border-left: 6px solid transparent;
      }
    }
  }

  .clientProfile {
    margin: unset;
    // margin: 5px 0;

    &.highPriority {
      border-left:$theme-notice-bold-warning solid !important;
      .headerCompleteMarker {
        border-left: 6px solid transparent;
      }
    }
  }

  .emptyList {
    margin: 104px auto;
    img{
      max-height: 100%;
    }

    .noTasks {
      color: $theme-accent-primary;
      height: 15px;
      width: 176px;
      margin: 0 auto;
      @include type-lg-bold
    }
  }
}

.taskRow {
  :global {
    .healthie-dropdown-triangle {
      left: 172px !important;
    }
  }

  width: 100%;
  height: auto;
  background: $theme-background-primary;
  border: 1px solid $theme-border-primary;
  border-radius: 3px;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  margin-bottom: -12px;

  &.clientProfile {
    margin: 5px 0;

    &.highPriority {
      border-left:$theme-notice-bold-warning solid;
      .headerCompleteMarker {
        border-left: 6px solid transparent;
      }
    }
  }


  .headerCompleteMarker {
    min-width: 25px;
    display: flex;
    justify-content: center;
    border-left: 8px solid transparent;

    .notCompletedIcon {
      circle {
        stroke: $theme-accent-primary;
        fill: $theme-background-input;

        &:hover {
          stroke: $theme-accent-primary;
        }
      }
    }
  }

  .taskRowContent{
    display: block;
    padding: 12px;
    line-height: 2;
    flex-grow: 1;
    white-space: pre-wrap;
    word-wrap: break-word;

    .dueDay {
      @include type-sm;
    }
  }

  .tasksAvatarContainer {
    margin-right: 11px;
    display: flex;

    .taskClient {
      margin-left: -8px;
    }
  }

  .hideButton{
    margin: auto 11px auto 0px;
    padding-top: 6px;
    cursor: pointer;
  }

  .react-tooltip {
    background-color: $theme-text-primary;
  }

  .react-tooltip.type-dark.place-top::after {
    border-width: 5px 0 5px 6px;
    margin: auto -41px auto 0;
  }

  .react-tooltip, .react-tooltip.show {
    display: flex;
    position: unset;
    max-width: 211px;
    max-height: 82px;
    margin-left: -211px;
    margin-right: 17px;

    &::before, &::after {
      position: unset;
    }

    &::after {
      margin: auto -27px auto 0;
    }

    .cc-tooltip {
      margin-right: 21px;

      p {
        width: 169px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-decoration: none;
      }
    }
  }
}

.taskCount {
  margin: 0;
  left: 31px;
  top: 2px;
  text-align: center;
  height: 20px;
  width: 20px;
  background: $theme-notice-bold-warning;
  color: $theme-white;
  font-weight: 600;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  position: absolute;
  display: inline-block;
  font-size: 10px;
  z-index: 1;
  line-height: 20px;
}

.hasTextCentered {
  text-align: center !important;
}
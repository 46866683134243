@import '../../../assets//stylesheets/scss/tokens';
.radioPillInputContainer {
  
  opacity: 0;
  width: 0;
  position: fixed;
}

:global(.client-profile-form) {
  :local(.RadioPillButton) {
    @include type-sm-bold;
    width:5rem;
  }
}

.RadioPillButton {
  @include type-lg-bold;
  border-radius: 1rem;
  border: 2px solid $theme-border-primary;
  background: none;
  color: $theme-accent-primary;
  text-align: center;
  padding: 0.3rem 0rem;
  width: 6.5rem;
  margin:0;
  display: inline-block;
  &:focus {
      border-color: $theme-accent-primary;
  }
  &:hover {
      background: $theme-accent-primary;
      border-color: $theme-accent-primary;
  }
  &:active {
      background: $theme-text-primary;
      border-color: $theme-text-primary;
  }
  &:hover, &:active {
      color: $theme-text-secondary;
  }
}

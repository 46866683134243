@mixin mobile {
  @media screen and (max-width: 768px) {
    @content
  }
};


@mixin desktop {
  @media screen and (min-width: 1024px) {
    @content
  }
};

@mixin tablet {
  @media screen and (min-width: 769px ) {
    @content
  }
};


@mixin tablet-landscape {
  @media screen and (max-width: 1024px ) {
    @content
  }
};


@mixin fullhd{
  @media screen and (min-width: 1409px){
    @content;
  }
}

@mixin tablet-to-desktop{
  @media screen and (min-width: 769px) and (max-width: 1024px){
    @content;
  }
}

@mixin desktop-to-fullhd{
  @media screen and (min-width: 1024px) and (max-width: 1408px){
    @content;
  }
}


@mixin widescreen {
  @media screen and (min-width: 1216px) {
    @content
  }
};


$type-sm: 'type-sm';
$type-sm-bold: 'type-sm-bold';
$type-lg: 'type-lg';
$type-lg-bold: 'type-lg-bold';
$type-h4: 'type-h4';
$type-h3: 'type-h3';
$type-h2: 'type-h2';
$type-h1: 'type-h1';

@mixin font-tokens($type) {
  @if $type == $type-sm {
    @include type-sm;
  } @else if $type == $type-sm-bold {
    @include type-sm-bold;
  } @else if $type == $type-lg {
    @include type-lg;
  } @else if $type == $type-lg-bold {
    @include type-lg-bold;
  } @else if $type == $type-h4 {
    @include type-h4;
  } @else if $type == $type-h3 {
    @include type-h3;
  } @else if $type == $type-h2 {
    @include type-h2;
  } @else if $type == $type-h1{
    @include type-h1;
  }  @else {
    @error "Unknown type #{$type}"
  }

};


// for different browsers

@mixin only-for-ie{
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
@mixin only-for-safari{
  @media not all and (min-resolution:.001dpcm){
    @supports (-webkit-appearance:none) {
      @content;
  }}
}
@mixin for-ie-and-edge{
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@import "../../../assets/stylesheets/scss/tokens";
@import "../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

tr.row {
  .link {
    color: $theme-accent-primary;
    @include type-lg-bold;
  }

  .invoiceIdLinkCol {
    padding: 0.8rem;
    
    .invoiceIdLink {
      @extend .link;
    }
    
  }

  .fullNameCol {
    padding: 0.6rem;
  }

  .statusCol {
    .unpaid-status,
    .partially-paid-status,
    .paid-status {
      @include type-lg-bold;
    }

    .unpaid-status {
      color: $theme-notice-bold-warning;
    }

    .partially-paid-status {
      color: $theme-notice-bold-alert;
    }

    .paid-status {
      color: $theme-notice-bold-success;
      display: inline-block;
      width: 110px;
    }
  }

  .actionsCol {
    text-align: right;
    padding-right: 0px;

    [class="dropdown-menu"] {
      left: -8em;
    }

    [class="healthie-dropdown-triangle"] {
      left: 133px;
    }

    [class="healthie-dropdown-content"] {
      width: 180px;

      [class="dropdown-item"] {
        @include type-sm;
        padding: 0;

        &:last-child {
          border-top: 1px dashed $theme-text-primary;
        }

        a {
          color: $theme-accent-primary;
    
          &[class="danger"] {
            color: $theme-notice-bold-warning;
          }
        }
      }
    }
  }
  
  .fullNameCol,
  .invoiceIdLinkCol,
  .statusCol {
    display: none;

    @include desktop {
      display: table-cell;
    }
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.sectionRow {
		padding: 0.75rem 0;
		border-bottom: 1px solid $theme-border-primary;
		&.noBottomMargin {
				margin-bottom: 0 !important;
		}
		.sectionDescription {
				@include type-lg;
				color: $theme-text-primary
		}
		.sectionValue {
				text-align: right;
		}
		&.isDisabled {
				.sectionDescription{
						color: $theme-text-secondary;
				}
				.sectionValue{
						opacity: 50%;
				}
		}
}

@import "assets/stylesheets/scss/tokens";

.inlineRadioBtnsContainer {
		display: flex;
		flex-direction: column;
		.labelBox{
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				.label{
						color: $theme-text-secondary;
						@include type-lg-bold;
				}
				.subLabel {
						color: $theme-text-secondary;
						@include type-sm;
						text-align: right;
						margin: 8px 0 5px 0;
						min-width: 130px;
						&.selectedValueText{
								color: $theme-accent-primary;
								@include type-sm-bold;
						}
				}
				
				.subLabelHint {
						color: $theme-text-secondary;
						@include type-sm;
						text-align: right;
						text-transform: uppercase;
				}
		}
		.radioEmojisBlock {
				display: flex;
				max-height: 61px;
				justify-content: space-between;
				width: 100%;
				.radioEmoji{
						background: $theme-background-secondary;
						border-radius: 50%;
						height: 61px;
						width: 61px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						&:hover, &.selected {
								background: $theme-accent-primary;
								span {
										opacity: 1
								}
						}
						
						span {
								opacity: 0.3;
								width: 34px;
								height: 34px;
								span{
										opacity: 1;
								}
						}
				}
				&.health {
						background: $theme-background-secondary;
						border-radius: 3px;
						height: 35px;
						.radioEmoji{
								width: 33.3333%;
								display: flex;
								justify-content: center;
								align-items: center;
								border-radius: unset;
								height: auto;
								span{
										width: 26px;
										height: 26px;
								}
								&:not(:last-child){
										border-right: 1px solid $theme-border-primary;
										&.selected{
												border-right: 1px solid transparent;
										}
								}
								&:first-child{
										&:hover, &.selected {
												background: $theme-notice-bold-alert;
										}
								}
								&:nth-child(2) {
										&:hover, &.selected {
												background: $theme-notice-bold-alert;
										}
								}
								&:last-child {
										&:hover, &.selected {
												background: $theme-notice-bold-success;
										}
								}
								
								&:first-child {
										span:first-child {
												transform: rotate(180deg)
										}
								}
								
								&:nth-child(2) {
										span:first-child {
												transform: rotate(-90deg)
										}
								}
						}
				}
		}
		
		.radioBtnBlock {
				display: flex;
				margin: 10px 0 10px 0;
				justify-content: space-between;
				background: $theme-background-secondary;
				border-radius: 22px;
				height: 40px;
				width: 100%;
				.radioBtn{
						width: 48px;
						height: 40px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: $theme-background-secondary;
						color: $theme-text-secondary;
						cursor: pointer;
						@include type-sm;
						
						&:hover, &.selected {
								width: 60px;
								outline: 0;
								border-radius: 50%;
								z-index: 10;
								background: $theme-accent-primary;
								color: $theme-white;
								@include shadow-sm;
								@include type-lg-bold;
								text-align: center;
						}
						&:first-child {
								border-top-left-radius: 22px;
								border-bottom-left-radius: 22px;
						}
						&:last-child{
								border-top-right-radius: 22px;
								border-bottom-right-radius: 22px;
						}
				}
		}
		.input {
				position: absolute;
				clip: rect(0, 0, 0, 0);
				pointer-events: none;
				box-sizing: border-box;
				padding: 0;
				overflow: visible;
				margin: 0;
				font-family: inherit;
				font-size: inherit;
				line-height: inherit;
		}
}
@import "../../../../assets/stylesheets/scss/tokens";

.saveIndicatorContainer {
		display: flex;
		justify-content: center;
		align-items: center;
}

		.saveIndicator {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 12px;
				height: 24px;
				width: 120px;
				background: $theme-notice-soft-success;
				
				.icon {
						color: $theme-notice-bold-success;
						font-size: 8px;
				}
				
				.text {
						color: $theme-notice-bold-success;
						@include type-sm-bold;
						text-transform: uppercase;
						user-select: none;
						margin-left: 5px;
				}
		}
		
		.tooltip {
				padding: 15px;
				
				p {
						text-align: center;
				}
				p:first-child {
						color: $theme-white;
						@include type-sm-bold;
				}
				p:last-child {
						color: $theme-white;
						@include type-sm;
				}
		}
		
.locked {
		.saveIndicator {
				background: $theme-notice-soft-success;
				.text {
						color: $theme-text-secondary;
				}
		}
		
		.tooltip p {
				color: $theme-white;
				@include type-sm;
		}
}

.failed {
		.saveIndicator {
				background: $theme-notice-bold-warning;
				.icon, .text {
						color: $theme-white;
				}
		}
}

.loading {
		.saveIndicator {
				border: 1px solid rgba(81, 81, 81, 0.2);
				background: $theme-background-primary;
		}
}

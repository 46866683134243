@import "assets/stylesheets/scss/tokens";

.label {
	@include type-sm;
  text-align: left;
  color: $theme-text-primary;
	&:not(:last-child){
		margin: 8px 0 5px 0;
		@media screen and (max-width: 768px) {
			margin: 15px 0 8px 0;
		}
	}
}
.field {
		margin-bottom: 5px;
		display: flex;
		cursor: pointer;
		user-select: none;
		padding: 0;
		-ms-user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		align-items: center;
		position: relative;
		.checkmark {
				min-width: 14px;
				width: 14px;
				height: 14px;
				background-color: $theme-background-input;
				border: 1px solid $theme-border-input;
				border-radius: 2px;
				position: relative;
				&:after {
						content: "";
						display: none;
						position: absolute;
				}
		}
		.label {
				display: flex;
				color: $theme-text-primary;
				text-align: left;
				@include type-lg;
				padding-left: 5px;
				align-items: center;
				svg{
						margin-right: 5px;
				}
		}
		&.checked{
				.checkmark{
						background: $theme-accent-primary;
						border: 1px solid $theme-border-input;
						&:after {
								display: block;
								top: 0;
								left: 5px;
								width: 3px;
								height: 10px;
								border: solid $theme-white;
								transform: rotate(45deg);
								border-width: 0 2px 2px 0;
								-ms-transform: rotate(45deg);
								-webkit-transform: rotate(45deg);
						}
				}
		}
		&.readOnly {
				.checkmark {
						cursor: not-allowed;
				}
		}
}

.horizontal {
		display: flex;
		align-items:  center;
		.label{
				@include type-lg;
		}
		.field {
				margin-left: 10px;
				margin-bottom: 0;
		}
}

@import 'src/assets/stylesheets/scss/tokens';

.labOrderHeader {
  display: flex;
  flex-direction: row;
  margin: 27px 48px 20px 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid $theme-border-primary;
  gap: 20px;

  .downloadButton {
    margin-left: auto;
    margin-right: 40px;
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    h2 {
      @include type-h2;
      margin-bottom: 10px;
    }

    .bold {
      font-weight: 600;
    }
  }
}

@import "src/assets/stylesheets/scss/_tokens";

.supportDashboard {
	display: flex;
	width: 100vw;
	height: 100vh;
}

.supportDashboardHeader{
	background-color: $theme-background-sidebar;
	padding: .5rem;
	margin-bottom: 0 !important;
}

.maskedInButton{
	margin-left: auto;
}

.isMaskedIn {
	color: $theme-accent-primary;
	&:hover {
		color: $theme-white;
		background-color: $theme-accent-primary;
		border-radius: 0.8rem;
		cursor: pointer;
	}
}

.notMaskedIn {
	color: $theme-accent-primary;
}

.sideBar{
	background-color: $theme-accent-primary;
	height: 100%;
}

.supportDashboardMain{
	height: 100vh;
}

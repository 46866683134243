@import "./src/assets/stylesheets/scss/tokens";

.field {
		position: relative;
		height: 49px;
		display: flex;
		flex-direction: column;
		
		&:not(:last-child, .noBottomMargin) {
				margin-bottom: .75rem;
		}
		
		.numberButtonRow {
				display: flex;
				align-items: center;
				.numberBtn {
						max-height: 40px;
						height: 100%;
						&:hover{
								cursor: pointer;
						}
						&.disabled {
								svg{
										rect{
												fill: $theme-text-secondary;
										}
										polygon {
												fill: $theme-text-secondary;
										}
								}
						}
				}
		}
		.input {
				height: 40px;
				width: 100%;
				border-radius: 3px;
				border: 1px solid;
				padding: calc(0.375em - 1px) calc(0.625em - 1px);
				-moz-appearance: textfield;
				appearance: textfield;
				margin: 0 5px;
				text-align: center;
				&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
						appearance: none;
						-webkit-appearance: none;
				}
				
				&::placeholder {
						color: $theme-text-secondary;
				}
				
				&:focus, &:active, &:hover:not(.isDanger) {
						border-color: $theme-accent-primary;
				}
				
				&:not(:focus) {
						border: 1px solid $theme-border-input;
				}
				
				&.isDanger {
						border-color: $theme-notice-bold-warning;
				}
		}
		
		[data-error-adapter] {
				position: absolute;
				bottom: -13px;
		}
}

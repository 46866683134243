@import "src/assets/stylesheets/scss/tokens";

.formsBreadcrumbsRightContent {
  border-left: 1px solid $theme-border-primary;
}

.breadCrumb {
  display: flex;
  width: fit-content;

  &:after {
    cursor: default;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 14px;
    content: "\F054";
    color: $theme-text-secondary;
    margin: auto 15px;
    width: 8px;
    height: 18px;
  }

  .name{
    @include type-lg-bold;
    color: $theme-accent-primary;
  }

  .clientLink{
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
  
      .name{
        @include type-lg-bold;
        color: $theme-accent-primary;
      }
    }

    :global {
      .patient-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .col {
        .patient-avatar {
            margin-right: auto;
        }
      }
    }
  }
}

.lastCrumb {
  div:only-child {
    &:hover, &:focus, &:active {
      cursor: default;
    }
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.availabilityBigCalendar {
  .availabilitiesLoadingSpinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
  }

  :global(.rbc-today) {
    background-color: $theme-background-secondary;
  }

  :global(.is-loading) {
    .rbc-btn-group {
      opacity: .5;
      pointer-events: none;
    }
  }

  :global(.rbc-time-view) {
    border: none;
    background: $theme-background-primary;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
  }

  :global(.rbc-day-slot) {
    .rbc-event.calendar-availability-event {
      min-height: 12px;
    }
  }

  :global(.rbc-addons-dnd-over) {
    background-color: $theme-background-secondary;
  }
}

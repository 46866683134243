@import "src/assets/stylesheets/scss/tokens";

.dropdown {

  :global {
    .dropdown-trigger {
      display: flex;
    }

    .dropdown-menu {
      right: 0;
      left: unset;
    }

    .healthie-dropdown-triangle {
      right: 7px;
      left: unset !important;
    }
  }

  .ellipsis {
    font-size: 1.5em;
    color: $theme-accent-primary;
  }
}

.wrapper {
  display: flex;
  margin-left: 10px;
}

.content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border-radius: 0 !important;
  border: 1px solid $theme-border-primary;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: $theme-background-primary;
  width: 100%;
}

@import "assets/stylesheets/scss/tokens";

:global .post-form {
  :local .dateTimeAdapterContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
.dateTimeAdapterContainer {
  label {
    margin: 0 0 0.25rem 0;
    display:block;
  }
  
  .inputField {
    display:flex;
    gap: 5px;
    align-items: center;
  }
  input {
    @include type-lg;
    min-height: 30px;
    border-radius: 3px;
    background-color: $theme-background-input;
    border: 1px solid $theme-border-input;
    box-shadow: none;
    color: $theme-text-primary;
    box-sizing: border-box;
    padding: calc(.375em - 1px) calc(.625em - 1px);

    &::placeholder {
      color: $theme-text-secondary;
    }
    &:hover {
      background-color: $theme-background-input;
      box-shadow: none;
      border: 1px solid $theme-border-input;
      color: $theme-text-primary;
      box-sizing: border-box;
    }
    &[disabled]{
      background-color: $theme-background-disabled;
      border-color: $theme-border-input;
      box-shadow: none;
      color: $theme-text-primary;
    }
  }
}
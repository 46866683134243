@import 'src/assets/stylesheets/scss/tokens';

.archiveAllClientsModal {
  .archiveAllClientsTitle {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid $theme-border-primary;
  }

  .archiveAllClientsBody {
    text-align: center;
    margin-bottom: 20px;
  }
}

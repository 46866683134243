@import "../../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.paymentDetailsCell{
  display: none;

  .paymentDetailsContainer{
    display: flex;
  }

  .paymentDetailsItem{
    flex: 1;
    min-width: 0;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .hTooltip {
    max-width: 450px;
    word-break: break-all; /* this help when user adds one long word */
  }

  @include desktop {
    display: table-cell;
    max-width: 100px;
  }

  @include fullhd {
    max-width: 300px;
  }
}

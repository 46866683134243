@import 'src/assets/stylesheets/scss/tokens';

.header {
  @include type-h4;
  margin-bottom: 30px;
}

.text {
  margin-bottom: 20px;
}

.button {
  text-align: center;
}

.errorId {
  @include type-sm-bold;
}
@import "assets/stylesheets/scss/tokens";

.inputWrapper {
  display: flex;
  margin-top: 0.5rem;
  :global {
      li.react-datepicker__time-list-item--disabled {
        cursor: default;
      }
  
      .react-datepicker-popper {
        min-width: 100px;
      }
    }
}

@import "./src/assets/stylesheets/scss/tokens";

.field {
  align-items: center;
  column-gap: 8px;
  display: grid;
  grid-template-columns: min-content 1fr;
  width: fit-content;

  .input {
    border-radius: 4px;
    border: 1px solid $theme-border-input;
    height: 14px;
    width: 14px;

    &:hover {
      cursor: pointer;
    }
  }

  .label {
    @include type-lg;
    color: $theme-text-primary;
  }
}

@import 'src/assets/stylesheets/scss/tokens';

.wrapper {
  height: calc(100vh - 9.5rem);

  .editorContainer {
    height: 100%;
    overflow-y: auto;
  }

  .fullHeight {
    height: 100%;
  }
}
@import 'src/assets/stylesheets/scss/tokens';

.basicInfo {
    padding: 0rem 1.25rem;

    .sidebarLocation {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.shrinkedBasicInfo {
    padding: 1.25rem 0;
}

.wordBreak {
    word-break: break-word;
}

.label {
    @include type-sm;
}
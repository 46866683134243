@import "src/assets/stylesheets/scss/tokens";

.container {
  margin-top: 15px;
}

.fields {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  > div:not(:last-child) {
    width: 80px;
    margin-right: 20px;
  }
}

.text {
  p {
    @include type-sm;
  }

  a {
    @include type-sm-bold;
  }
}

.subHeader {
  margin-top: 1rem;
  @include type-lg-bold;
}

[data-input-for='appointment_type_cpt_code.units']{
  margin-bottom: 0 !important;
}

.insuranceBillingEnabledWrapper {
  margin: REM(10) 0 REM(24);
}
@import "../../../assets/stylesheets/scss/tokens";

.anchor {
  visibility: hidden;
}

.flexWrapper {
  display: flex;
  flex-wrap: wrap;

  .option {
    margin-right: 20px;
    overflow: hidden;
  }
}

@import "src/assets/stylesheets/scss/_tokens";

.familyHistory {
  border-radius: 3px;
  border: 1px solid $theme-border-primary;
  margin-bottom: 1rem;

  .header {
    display: flex;
    @include type-lg-bold;
    align-items: center;
    height: 46px;

    .name {
      margin-left: 1rem;
      @include type-lg;
    }
  }

  .details {
    padding: 0.75rem;
    margin-bottom: 0;

    .label {
      @include type-lg;
      color: $theme-text-secondary;
    }
  }

  .optionsWrapper {
    margin-left: auto;

    .options {
      :global
      .dropdown-menu {
        min-width: 14rem;
        right: 0px;
        left: auto;
      }

      :global
      .healthie-dropdown-triangle {
        right: 4px;
        left: auto;
      }

      :global
      .fa-ellipsis-h {
        padding: .5rem;
        font-size: 18px;
        color: $theme-accent-primary;
      }
    }
  }
}

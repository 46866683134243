.firstNameBox {
  width: '45%';
  display: 'inline-block';
  float: 'left';
}

.lastNameBox {
  width: '45%';
  display: 'inline-block';
  margin-left: '5%';
}

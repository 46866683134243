@import "src/assets/stylesheets/scss/tokens";

.courseItemForm {
  .hasTooltip {
    margin-top: -24px !important;
  }

  .fieldWrapper {
    margin-left: 14px;
    display: flex;
    flex-direction: column;

    .label {
      @include type-lg-bold;
      color: $theme-text-primary;
      margin-right: 7px;
    }

    .checkboxLabel {
      padding-bottom: 8px;
    }

    .descriptionCounter {
      @include type-sm;
      color: $theme-text-secondary;
      margin-left: auto;
      padding-bottom: 12px;
    }
  }

  .extraPaddingBottom {
    padding-bottom: 8px;
  }
  
  .modulesOrderingInput {
    :global(.Select) {
      width: 20%;
      display: inline-block;
    }
  }

  .contentTypeContainer {
    :global(.form-control) {
      width: auto;
      display: inline-block;

      input {
        margin-right: 5px;
      }
    }
  }
}

@import 'src/assets/stylesheets/scss/tokens';

.modalPreviewContent {
  border-radius: 4px;
  border: 1px solid $theme-border-primary;
  padding: REM(24);
  color: $theme-text-primary;

  &:not(:last-child) {
    margin-bottom: REM(40) !important;
  }

  .divider {
    border-top: 1px solid $theme-border-primary;
    margin: REM(24) 0;
  }

  
  .modalPreviewLogo {
    max-height: 61px;
    max-width: 110px;
    overflow: hidden;

    img {
      display: block;
      height: 100%;
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
  }

  .additionalNotes {
    white-space: pre-line;
    @include type-lg;
  }
  
  .boldText {
    @include type-lg-bold
  }

  .smallText {
    @include type-sm;
  }

  .largeText {
    @include type-lg;
  }
}

@import "./src/assets/stylesheets/scss/tokens";
@import "../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.signUpContainer {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background-color: $theme-background-primary;
  .logoContainerV2{
    width: 100%;
    text-align: center;
    margin-top: 5.31rem;
    img{
      height: auto;
      width: 100%;
      max-width: 200px;
    }
    @include tablet-to-desktop{
      margin-top: 4.37rem;
    }
    @include mobile{
      margin-top: 3.75rem;
      img{
        height: auto;
        width: 100%;
      }
    }
  }
  .header {
    margin-top: 1.87rem;
    h3{
      @include type-h3;
      color: $theme-text-primary;
    }
  }
  .optionsContainer {
    justify-content: center;
    margin-top: 3.87rem;
    @include tablet-to-desktop{
      margin-top: 4.37rem;
    }
    @include mobile{
      margin-top: 1.87rem;
    }
    .optionCol {
      display: flex;
      &:first-child {
        justify-content: flex-end;
      }
    }
    .option{
      margin: 0 0.5rem;
      display: block;
      width: 300px;
      height: 300px;
      border-radius: 8px;
      @include shadow-md;
      background-color: $theme-background-primary;
      border: solid 1px $theme-border-primary;
      padding:0;
      box-sizing: border-box;
      
      .optionLogo{
        width: 100%;
        text-align: left;
        padding:0px;
        i{
          color: $theme-text-secondary;;
          margin-left: 0.5rem;
          margin-top:0.5rem;
          font-size: 2.1em;
          @include mobile{
            margin-left: 0.25rem;
            margin-top:0.25rem;
            font-size: 1.1rem;
          }
        }
      }
      .optionHeader{
        width: 100%;
        text-align: center;
        margin-top: 1rem;
        padding: 0 2.5rem;
        @include mobile{
          padding: 0 0.75rem;
          margin-top: 0;
        }
        h3{
          @include type-h2;
          color: $theme-text-primary;
        }
      }
      .optionDescription{
        text-align: center;
        width: 100%;
        margin-top: 2rem;
        padding: 0 2.5rem;
        @include mobile{
          padding: 0px 0.75rem;
          margin-top: 1rem;
        }
        p{
          text-align: center;
          color: $theme-text-primary;
          
        }
      }
      .optionCta{
        width: 100%;
        text-align: center;
        margin-top: 3rem;
        @include mobile{
          margin-top: 1rem;
        }
        div {
          @include type-lg-bold;
          line-height:initial;
          max-height: 36px;
          border-radius: 3px;
          text-align: center;
          margin: 0;
          display: inline-block;
          box-sizing: border-box;
          outline: none;
          cursor: pointer;
          white-space: nowrap;
          box-shadow: none;
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          transition: 0.3s;
          padding: 0.5rem 0.75rem;
          background-color: $theme-accent-primary;
          color: $theme-white;
          border: 1px solid $theme-accent-primary;
          &:hover, &:active, &:focus {
            background: $theme-accent-primary;
            color: $theme-white;
            border-color: $theme-accent-primary;
          }
        }
      }
    }
  }
  .footer{
    width: 100%;
    text-align: center;
    margin-top: 7.18rem;
    margin-bottom: 1.25rem;
    @include mobile{
      margin-top: 4rem;
    }
    color: $theme-text-primary;
    a, a:hover, a:focus, a:active{
      text-decoration: underline;
      color: $theme-accent-primary;
    }
  }
}

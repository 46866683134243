@import "src/assets/stylesheets/scss/tokens";

.multiFilterWrapper {
  &:not(:only-of-type) {
    margin-left: 8px;
  }
}

.multipleFilterDropdown {
  >div[role="menu"] {
    background: $theme-background-primary;
    border-radius: 0px;
    width: 280px;
    border: 1px solid $theme-border-primary;
    @include shadow-md;
    top: 120%;

    >div >div {
      left: 50%;
      top: -4px;
    }
  }

  .multiFilterTrigger {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  &.menuPosLeft {
    >div[role="menu"] {
      left: 24px;
      transform: translate(-50%, 0);
    }
  }

  &.menuPosCenter {
    >div[role="menu"] {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  button[aria-controls="dropdown-menu"] {
    background-image: none;
  }
}

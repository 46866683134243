@import "src/assets/stylesheets/scss/tokens";

.label {
  @include type-sm;
  color: $theme-text-primary;
}

.fieldRowWrapper {
  display: flex;
  gap: 1rem;
  div, input {
    border-color: $theme-border-input;
  }

  > div {
    flex: 1 1;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.nonEditableInputContainer {
  :global {
    .non-editable-input {
      cursor: pointer;
    }
  }
  .placeholder, &.placeholder {
    :global {
      .non-editable-input {
        color: $theme-text-secondary;
      }
    }
  }
}
@import "assets/stylesheets/scss/tokens";
.field {
		margin-bottom: 5px;
}

.label {
	@include type-sm;
  text-align: left;
  color: $theme-text-primary;
	&:not(:last-child){
		margin: 8px 0 5px 0;
		@media screen and (max-width: 768px) {
			margin: 15px 0 8px 0;
		}
	}
}
.groupHorizontalCheckboxField {
		display: flex;
		align-items:  center;
		.label{
				@include type-lg;
		}
		.field {
				margin-left: 10px;
				margin-bottom: 0;
		}
}

@import "assets/stylesheets/scss/tokens";

.container{
		display: block;
		position: relative;
		margin: 0 auto;
		width: 100%;
		.fileInputContainer {
				position: relative;
				overflow: hidden;
				.fileInput {
						position: absolute;
						top: 0;
						right: 0;
						width: 0;
						height: 0;
						font-size: 100px;
						text-align: right;
						filter: alpha(opacity=0);
						opacity: 0;
						outline: none;
						background: $theme-background-primary;
						cursor: inherit;
						display: block;
				}
		}
}

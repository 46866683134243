.providerHomeTasks {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerLeft {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .emptySmartState {
    padding: 2rem;
  }

  .collapsibles {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }
}

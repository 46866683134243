@import "src/assets/stylesheets/scss/tokens";

.modalContent {
  display: grid;
  row-gap: 0.5rem;

  .modalTitle {
    @include type-h4;
    text-align: center;
  }
}

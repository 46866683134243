@import 'src/assets/stylesheets/scss/_tokens.scss';



.appointmentContainerWrapper, .blockContainerWrapper {
  line-height: unset;
   span, p {
    @include type-sm-bold;
  }
}

.appointmentContainerWrapper {
  svg {
    box-shadow: rgba(98, 51, 51, 0.4) 0rem 0rem 0.18rem 0rem;
    position: absolute;
    top: 0px;
    right: 0px;
    background: $theme-text-primary;
    border-radius: 0rem 0rem 0rem 0.18rem;
    padding: 0.125rem;
    width: 12px;
    height: 12px;
    
    g {
      opacity: 1;
      g {
        fill: $theme-white;
      }
    }
  }

  :global .video-camera-icon {
    margin-bottom: 0.09rem;
  }
}

.repeatBlock.blockContainerWrapper {
  width:90%;
  margin: 0 auto;
  margin-top:0.4rem;
  margin-bottom:0.6rem;
  position: relative;
  z-index: -1;


  .repeatBlockContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      display: block;
      path {
        fill: $theme-white;
      }
    }
    .repeatTitle {
      color: $theme-white;
      text-transform: uppercase;
    }
    .blockTimes {
      @include type-lg;
    }
  }

  .blockTime {
    display: none;
  }
}


:global {
  .recurring-block-off-time .rbc-event-label {
    display: none;
  }


  .calendar-event.smallest-event-item {
    &.recurring-block-off-time.block-off-time .rbc-addons-dnd-resizable .rbc-event-content {
      margin-top: 0;
      width: 100%;
    }
    :local .repeatBlock.blockContainerWrapper {
      margin: 0;
      padding: 0 0.2rem;
      width: 100%;
      .repeatBlockContainer {
        display: inline-flex;
        align-items: center;
        width: 100%;
      }

      .blockTime {
        display: inline;
      }
      svg {
        width: 12px;
        height: 12px;
      }
      .blockTitle, .blockTimes {
        display: none;
      }
    }
  }
}

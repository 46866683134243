@import 'src/assets/stylesheets/scss/_tokens.scss';

.container {
  width: 100%;
  display: grid;
  row-gap: 10px;

  .cptFields {
    display: flex;
    justify-content: space-between;
  }
  
  .cptCode { 
    width: 100%;
  }
  
  .units {
    margin-left: REM(15);
    max-width: 100px;
  }
}

@import "../../../assets/stylesheets/scss/tokens";

.textareaIcons {
		box-sizing: border-box;
		clear: both;
		font-size: 1rem;
		position: relative;
		text-align: left;
		display: flex;
		align-items: center;
		.commentIcon svg {
				position: absolute;
				z-index: 2;
				bottom: 10px;
				left: 16px;
		}
		textarea{
				transition: width ease-in 0.2s;
				min-height: 44px;
				border-radius: 22px;
				border: 1px solid $theme-border-primary;
				resize: none;
				padding: 10px 43px;
				width: 100%;
				@include type-lg;
				&::placeholder {
						color: $theme-text-secondary;
				}
		}
		.sendIcon svg{
				opacity: 0;
				position: absolute;
				z-index: 2;
				bottom: 2px;
				right: 6px;
		}
		&.expanded{
				.sendIcon svg{
						opacity: 1;
				}
		}
		&.textareaLockHeight {
				textarea {
						height: 43px !important;
				}
		}
		&.textareaHasValue{
				.sendIcon{
						cursor: pointer;
						svg circle{
								fill: $theme-accent-primary;
						}
				}
		}
}

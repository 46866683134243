@import "src/assets/stylesheets/scss/tokens";

.dialinWrapper {
  margin-top: 24px;

  .detailWrapper {
    margin-bottom: 10px;
  }

  .detailsSubheader {
    margin-right: 10px;
  }

  .clientLinkWrapper {
    margin-bottom: 10px;
    flex-direction: column;
    display: flex;
    align-items: flex-start !important;
  }

  .phoneCallHeader {
    background-color: $theme-background-secondary;
    width: 418px;
    height: 48px;
    padding: 12px 24px 12px 24px;
    margin-left: -25px;
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: $theme-text-secondary;
  }
}

@import 'src/assets/stylesheets/scss/tokens';
@import 'src/assets/stylesheets/scss/utilities/MediaQueries/_mediaQueryMixins';

.tableWrapper {
    margin-top: 1rem;
    margin-bottom: 0rem;

    .intakeFlowContainer {
        height: 135px;
        display: flex;
        align-items: center;
        height: auto;
        margin: 0;

        .intakeFlowImage {
            width: 50px;
            height: 100%;
            display: flex;
            min-width: 50px;
            margin-right: 2.5rem;
            align-items: center;
            background: $theme-background-secondary;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            background-color: transparent;

            .intakeFormTypeContainer {
                left: 58px;
                width: 44px;
                height: 44px;
                display: flex;
                min-width: 44px;
                border-radius: 50%;
                position: absolute;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: transparent;

                .typeCol {
                    padding-left: 1rem;
                }

                @include mobile {
                left: 38px;
                }
            }
        }

        .intakeFlowMain {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .intakeFormTitleContainer {
                width: 100%;
                display: flex;
                margin-bottom: 0;

                .nameCol{
                    padding-left: 1rem;
                    width: 73%;
                    padding-right: 0rem;
                }

                .intakeFlowRequestDetails {
                    display: flex;
                    gap: 8px;

                    .requestedCol {
                        padding-left: 1.5rem;
                    }

                    .completedCol {
                        padding-left: 1rem;
                    }
                }
            }

            .intakeFormStatusActionContainer {
                display: flex;
                align-items: center;
                gap: 1rem;
                justify-content: flex-end;
                width: 330px;

                .statusCol {
                    margin: auto;
                }
            }
        }

        @media screen and (max-width: 1269px) {
            height: fit-content;
            padding: 0.625rem 0;
                .intakeFlowImage {
                    height: initial;
                    margin: -0.625rem 2.5rem -0.625rem 0;
                }

                .intakeFlowMain{
                    .intakeFormTitleContainer {
                        .nameCol{
                            width: 45%;
                        }
                    }
                }
        }

        @include mobile {
            height: fit-content;
            padding: 0.625rem 0;
                .intakeFlowImage {
                    height: initial;
                    margin: -0.625rem 2.5rem -0.625rem 0;
                }

                .intakeFlowMain{
                    .intakeFormTitleContainer {
                        .nameCol{
                            width: auto;
                        }
                        .intakeFlowRequestDetails{
                            display: none;
                        }
                    }
                    .intakeFormStatusActionContainer{
                        width: auto;
                    }
                }
            }

        @media screen and (min-width: 1270px) {
            height: 75px;

            .intakeFlowMain {
            display: flex;
            align-items: center;
            justify-content: space-between;
                .intakeFormTitleContainer {
                    width: 100%;
                    display: flex;
                    margin-bottom: 0;
                }
            }
        }
    }
}

@import 'src/assets/stylesheets/scss/tokens';

.pronounsField {
  @include type-lg;
  .creatableSelect {
    flex-basis: 100%;
    &__value-container {
      min-height: 30px !important;
      height: 30px !important;
    }
    &__indicator-separator,
    &__indicator,
    svg {
      display: none;
      z-index: 10;
      cursor: pointer;
    }
  }
    .control {
      border: 1px solid $theme-border-input !important;
      min-height: 30px !important;
      height: 30px !important;
    }


}

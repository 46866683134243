.actionRow {
  // Done to have action row hover above help button
  padding-bottom: 6rem;
  padding-top: 2rem
}

// ErrorAdapter has a z-index of 2 due to issues in CMS1500s
// In this form's layout, we need to have a z-index of 3 to have the popper over the content's error message.
.datePickerPopper {
  z-index: 3;
}

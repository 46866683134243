@import 'src/assets/stylesheets/scss/tokens';

:global {
  .tox {
    &.tox-tinymce {
      overflow: visible !important;
    }

    .tox-editor-container {
      position: relative;
      overflow: visible !important;

      .tox-editor-header {
        border-radius: 10px 10px 0 0;
      }
    }

    .tox-edit-area {
      border-radius: 10px;
    }

    .tox-sidebar {
      width: fit-content !important;
      >div {
        transition: width .01s ease, opacity .01s ease;
      }
    }

    .tox-sidebar__slider[style] {
      width: inherit !important
    }

    .smartPhraseMenuChatParentContainer{
      position: absolute;
      width: 280px !important;
      height: 275px;
      left: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);
      &.tox-sidebar{
        background-color: transparent;
      }
      .tox-sidebar--sliding-open {
        border: 1px solid $theme-border-primary;
        border-left: 0;
        @include shadow-sm;
        background-color: $theme-background-primary;
      }
    }
    .mce-smart-phrase-menu {
      max-width: 280px;
      padding-top: 10px;
      border-left: 1px solid $theme-border-primary;

      form {
        margin: 0 10px;
        background-color: $theme-background-secondary;
        border-radius: 3px;
        width: initial;

        input {
          min-width: 100%;
          height: 35px;
          padding-left: 30px;
          background: initial;

          &::placeholder {
            color: $theme-text-secondary;
          }
        }
      }

      svg {
        top: 11px;
        left: 20px;
        position: absolute;
      }

      >div {
        position: relative;

        >div:first-of-type {
          color: $theme-accent-primary;
          margin: 0 10px;
          padding: 12px 0;
          border-bottom: solid 1px $theme-border-primary;
          cursor: pointer;
        }

        .patient-avatar {
          img {
            height: 30px;
          }
        }
      }
    }
  }
}

.smartPhrasesMenu {
  @include type-lg;
  max-height: 250px;
  min-width: 280px;
  overflow: scroll;

  form {
    margin: 0 10px;
    background-color: $theme-background-secondary;
    border-radius: 3px;
    position: relative;
    height: 100%;

    input {
      padding-left: 30px;
      position: unset;
      width: 100%;
      height: 36px;

      &::placeholder {
        color: $theme-text-secondary;
      }
    }
  }

  .createPhraseLink {
    color: $theme-accent-primary;
    margin: 0 10px;
    padding: 12px 0;
    border-bottom: solid 1px $theme-border-primary;
    cursor: pointer;
  }

  .smartPhrase {
    color: $theme-text-secondary;
    padding: 10px;
    white-space: normal;
    display: flex;
    align-items: center;

    &:hover {
      background: $theme-background-secondary;
    }
  }

  .subHeader{
    @include type-sm-bold-uppercase;

    background-color: $theme-background-secondary;
    color: $theme-text-secondary;
    padding: 12px;
    gap: 10px;
  }

  .smartPhraseUserAvatar{
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
    color: $theme-white;
    background-color: $theme-background-secondary;
    margin-right: 10px;
  }

  .smartPhraseAvatarBox {
    position: relative;

    >div {
      @include type-sm;

      position: absolute;
      padding: 8px 15px;
      left: -10px !important;
      top: -30px !important;
      width: 280px;

      &:after{
        left: 25px !important;
      }
    }
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.optionRow {
		:global{
				.patient-avatar {
						width: 30px;
						height: 30px;
						line-height: 30px;
				}
		}
}

@import "src/assets/stylesheets/scss/tokens";

.sectionHeader{
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
}

.sectionHeaderDivider {
    border: 1px solid $theme-border-primary;
}

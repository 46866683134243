@import 'src/assets/stylesheets/scss/_tokens.scss';

.containerWrapper {
  height: 100%;

  .container {
    width: 100%;
    height: 100%;

    .listWrapper {
      overflow-x: hidden;

      li {
        text-align: center;
        margin-top: 40px;
      }
    }
  }
}
@import "../../../assets/stylesheets/scss/tokens";

.dropdownWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  &.defaultSpacing {
    margin: 0 0.5rem;
    min-width: 3rem;
  }
  &.noMarginSpacing{
    margin: 0;
    min-width: auto;
  }
  &.dotSize{
    button {
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }

  .dropdown {
    border-radius: 10px;
    @include shadow-md;
    background-color: $theme-background-primary;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    outline: 0;
    z-index: 10;
    padding: 0.5rem;
    position: absolute;
    margin-top: 0.5rem;
    width: max-content;
    
    &WithTriangle {

      .healthieDropdownTriangle {
        top: -12px;
        right: 20px;
        margin-top: -3px;
        margin-left: -8px;
        border-top: none;
        border-bottom-color: white !important;
        width: 1px;
        height: 0;
        position: absolute;
        box-sizing: content-box;
        border: 8px solid transparent;
      }
      .healthieDropdownTriangle::before {
        top: -9px;
        height: 0;
        width: 1px;
        position: absolute;
        box-sizing: content-box;
        border: 8px solid transparent;
        right: -8px;
        content: "";
        z-index: -1;
        border-width: 8px;
        border-bottom-color: rgba(0,0,0,.25);
      }
    }
  }

  .modalClose:after,
  .modalClose:before {
    background-color: $theme-text-primary;
  }
}

.storyWrapper {
  width: 90%;
  height: 21px;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
}

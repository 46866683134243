@import "src/assets/stylesheets/scss/_tokens";

.taskInfo {
  display: flex;
  min-width: 150px;
}

.completedLine {
  border-left: $theme-notice-bold-warning solid;
}

.incompletedLine {
  border-left: transparent solid;
}

.completeMarker {
  vertical-align: middle;
  min-width: 2.8rem;
  margin: auto 0px;
}

.taskContent {
  margin: auto 0px;
  word-break: break-word;
}

.taskFullName {
  @include type-lg-bold;
}

.taskListCompleteIconWrapper {
  display: flex;
  align-items: center;

  img, svg {
    cursor: pointer;
  }

  svg:hover {
    g {
      g {
        stroke: $theme-text-primary;
      }
    }
  }
}

.tooltip{
  max-width: 500px;
}

.wordwrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

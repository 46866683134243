@import "assets/stylesheets/scss/tokens";

.cardWrapper {
  border-radius: 0.5rem;
  border: 1px solid $theme-border-primary;
  background-color: $theme-background-primary;
  min-height: 7.25rem;
  padding: 1rem 1.63rem;
}

.cardColTitle {
  @include type-sm;
  margin-bottom: 0.25rem;
}

@import "./src/assets/stylesheets/scss/tokens";

.resourceTypeButton {
  @include type-lg-bold;
  line-height:initial;
  height:35px;
  max-height: 36px;
  text-align: center;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: 0.3s;
  background: $theme-white;
  border: 1px solid $theme-accent-primary;
  padding: 0.5rem;
  svg g[fill]:not([fill="none" ]), svg path[fill], svg polygon[fill] {
    fill:$theme-accent-primary;
  }
  &:first-child{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &:hover, &:active, &:focus, &.active {
    background: $theme-accent-primary;
    outline: none;
    svg g[fill]:not([fill="none" ]), svg path[fill],svg polygon[fill] {
      fill: $theme-white;
    }
  }
  &:disabled {
    border-color: $theme-border-primary;
    background: $theme-background-disabled;
    color: $theme-white;
    cursor: not-allowed;
    svg g[fill]:not([fill="none" ]), svg path[fill], svg polygon[fill] {
      fill: $theme-text-secondary;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.headerClientResult {
  .userName {
    @include type-lg-bold;
    overflow: hidden;
    width: 100%;
    max-width: 220px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .archivedUser {
    @include type-sm-bold;
    color: $theme-text-secondary;
  }
}

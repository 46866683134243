@import '../../../../../assets/stylesheets/scss/tokens';

.groupNoteMessage {
  padding-bottom: 5px;

  .groupNoteMessageTitle {
    display: flex;
    color: $theme-text-secondary;
    @include type-h4;
    margin-bottom: 5px;
  }

  .group-charting-note-custom-message {

    .flash-icon-container {
      border-radius: 8px 0 0 8px;
    }

    .main-container {
      border-radius: 0 8px 8px 0;
    }
  }
}

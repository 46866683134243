@import "assets/stylesheets/scss/tokens";

.radioButtonContainer {
	display: inline-flex;
	max-width: REM(500);
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
}

// Radio button container
.radioButton {
	cursor: pointer;
	user-select: none;
	-ms-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	height: 100%;
	display: flex;
	align-items: flex-start;
	border: 1px solid transparent;
	border-radius: REM(4);
	margin-bottom: 0;
	&:focus{
		border: 1px solid $theme-accent-primary;
	}
	&:hover	{
		background: $theme-background-secondary;
	}
	&.error {
		background: $theme-notice-soft-warning;
		border: 1px solid $theme-notice-bold-warning;
		.checkmark{
			&.active {
				&:after {
					background: transparent;
				}
			}
		}
	}
	.label {
		color: $theme-text-primary;
		text-align: left;
		@include type-lg;
		display: flex;
		max-width: 31.25rem;
		padding: 0 0.25rem;
		align-items: flex-start;
		align-self: stretch;
	}
	
	// Hide the browser's default radio button
	.radioInput {
		visibility: hidden;
		cursor: pointer;
		margin: auto 0;
	}
	
	// Create a custom radio button
	.checkmarkWrapper{
		display: flex;
		padding: 0.25rem;
		align-items: center;
		justify-content: center;
		.checkmark {
			width: 1rem;
			height: 1rem;
			background: $theme-background-primary;
			position: relative;
			border-radius: 50%;
			border: 1px solid $theme-text-primary;
			&:after {
				content: "";
				display: none;
				position: absolute;
			}
			&.active {
				&:after{
					display: block;
					left: REM(2);
					top: REM(2);
					width: REM(10);
					height: REM(10);
					border-radius: 50%;
					background: $theme-accent-primary;
				}
			}
		}
	}
	&.disabled {
		cursor: not-allowed;
		&:hover	{
			background: $theme-background-disabled;
		}
		.label{
			color: $theme-text-secondary;
		}
		.checkmark {
			border: 1px solid	$theme-background-disabled;
			&:after{
				background: $theme-background-disabled;
			}
			&.active {
				&:after {
					background: $theme-background-disabled;
				}
			}
		}
	}
	.tooltipIcon{
		display: flex;
		width: 24px;
		height: 24px;
		padding: REM(2);
		justify-content: center;
		align-items: center;
	}
}
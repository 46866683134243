.cptRow {
  .descriptionCol {
    width: 45%;
    word-break: break-word;
  }

  .unitsCol {
    text-align: center;
  }
}

@import "src/assets/stylesheets/scss/_tokens";

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid $theme-accent-primary;
  border-right: 1px solid $theme-accent-primary;
  padding: 10px 15px;
  background-color: $theme-background-secondary;
  @include shadow-sm;
  border-radius: 10px;

}

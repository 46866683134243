@import "src/assets/stylesheets/scss/_tokens";

.totalPatientCountContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: auto;
  padding: 15px 20px;
  background-color: $theme-accent-primary;
  border-radius: 10px 0px;
  border-bottom: 2px solid $theme-border-primary;


  .totalPatientCountTitle {
    @include type-lg-bold;
    height: 100%;
    width: auto;
    color: $theme-text-secondary;
    text-align: end;
    margin-bottom: 15px;
  }

  label {
    color: $theme-text-secondary;
  }
}
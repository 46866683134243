@import "assets/stylesheets/scss/tokens";
@import "assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.container {
		box-sizing: border-box;
		clear: both;
}
.label {
		display: block;
		@include type-sm;
		text-align: left;
		color: $theme-text-primary;
		&:not(:last-child){
				margin: 8px 0 5px 0;
		}
}
.textarea {
		@include type-lg;
		min-height: 30px;
		border-radius: 3px;
		background-color: $theme-background-input;
		border: 1px solid $theme-border-input;
		width: 100%;
		box-shadow: none;
		color: $theme-text-primary;
		box-sizing: border-box;
		max-width: 100%;
		min-width: 100%;
		padding: 0.625em;
		resize: vertical;
		
		&::placeholder {
				color: $theme-text-secondary;
		}
		
		&:not([rows]) {
		  max-height: 245px;
		  min-height: 200px;
 }
		
		@include mobile {
				min-height: 44px;
				border: 1px solid $theme-border-input;
				&::placeholder {
						color: $theme-text-secondary;
				}
		}
		
		&:active, &:focus, &:hover{
				background-color: $theme-background-input;
				box-shadow: none;
				border: 1px solid $theme-accent-primary;
				color: $theme-text-primary;
				box-sizing: border-box;
				@include mobile {
						border: 2px solid $theme-accent-primary !important;
						&::placeholder {
								color: transparent;
						}
				}
		}
		&:disabled {
				background: $theme-background-disabled;
				border-color: $theme-border-input;
				resize: none;
				cursor: not-allowed;
		}
		&.hasError {
				background-color: $theme-background-primary;
				border-color: $theme-notice-bold-warning;
				box-shadow: none;
		}
}
@import 'src/assets/stylesheets/scss/_tokens.scss';

.taskModal {
  margin: 0;

  :global {
    #modal-content {
      section {
        .tasks-form {
          margin-bottom: 16px;
        }
      }
    }
  }
}

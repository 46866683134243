@import "src/assets/stylesheets/scss/tokens";

.optionsSection{
    margin-top: 1rem;

    .bookableByGroupsContainer {
        padding-left: 1.2rem;

        .specificGroupsContainer {
            margin-top: 0.5rem;
            padding-left: 1.5rem;
        }
    }

    .sectionHeader{
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
    }

    .sectionHeaderDivider {
        border: 1px solid $theme-border-primary;
    }
}

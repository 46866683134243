@import "../../../../assets/stylesheets/scss/tokens";

.totalAmountCell {
  text-align: left;

  .invoiceFrequencyPrice {
    color: $theme-accent-primary;
    margin-right: 5px;
  }

  .whiteSpace {
    width: 19px;
    height: 14px;
    display: inline-block;
  }
}
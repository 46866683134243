@import "src/assets/stylesheets/scss/_tokens";


.title {
    @include type-lg-bold;
    height: auto;
    width: 50%;
    color: $theme-text-secondary;
    text-align: end;
    align-self: end;
    padding: 1.25rem 0.313rem 0.625rem 1.25rem;
    background-color: $theme-accent-primary;
    border-radius: 0rem 0.625rem 0rem 0.625rem;
    margin-bottom: 1.563rem;
}


.tileTitle {
    margin-bottom: 0.625rem;
    @include type-lg-bold;
    height: 100%;
    width: auto;
}


.supportSubmitBtn {
    display: flex;
    justify-content: center;
    @include type-lg-bold;
}


.tileForm {
    margin-bottom: 1.563rem;
}


.enableContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
}


.toggle {
    display: flex;
    justify-content: flex-start;
}


.toggleButton {
    margin-right: 0.5rem;
}

.divider {
    border: 1px solid $theme-border-primary;
    margin-bottom: 1.2rem;
}
@import "src/assets/stylesheets/scss/tokens";

.matrixTable {
  table-layout: fixed;
  border-collapse: collapse;

  thead, tbody {
    display: table;
    table-layout: fixed;

    tr {
      display: table-row;
      width: 100%;

      td, th {
        vertical-align: top;
        min-width: 200px;
        width: 1%;
        padding-right: 1.5rem;
      }
    }
  }


  .matrixCell {
    width: 100%;
    padding-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
  
    .textInput {
      height: 40px;
      width: 100%;
      border-radius: 4px;
      padding: 0.5rem 0.75rem;
      border: 1px solid $theme-border-input;
      @include type-lg;
      &:disabled {
        border: 1px solid $theme-border-input;
        background: $theme-background-disabled;
      }
    }
  }
}

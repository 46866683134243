@import "assets/stylesheets/scss/tokens";

.timezoneDropdownMenu {
  background: $theme-background-input;

  :global {
    .timezone-select__control {
      border: 1px solid $theme-border-input;
    }

    .timezone-select__control--menu-is-open {
      border-bottom: unset;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .timezone-select__menu {
      margin-top: 0;
      box-shadow: unset;
      z-index: 2 !important;
      border-radius: 0 0 3px 3px;
      border-left: 1px solid $theme-border-input;
      border-right: 1px solid $theme-border-input;
      border-bottom: 1px solid $theme-border-input;
    }

    .timezone-select__indicator {
      color: $theme-accent-primary;
    }

    .timezone-select__menu-list {
      padding-top: 0;

      .timezone-select__group:last-of-type {
        .timezone-select__group-heading {
          padding-left: 0;
          padding-right: 0;
          margin-left: 12px;
          margin-right: 12px;
          padding-bottom: 8px;
          border-top: 1px solid $theme-border-primary;
        }

        .selected-option-title {
          font-weight: 400;
        }
      }
    }

    .timezone-select__option {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: $theme-background-primary;
    }

    .timezone-select__option--is-selected,
    .timezone-select__option--is-focused {
      background-color: $theme-background-secondary;
      .selected-option-title {
        color: $theme-accent-primary;
      }
    }

    .timezone-select__single-value {
      &::before {
        content: '';
        width: 13px;
        height: 13px;
        margin-right: 5px;
        position: relative;
        display: inline-block;
        background-size: 13px 13px;
        background-repeat: no-repeat;
      }
    }

    .selected-option-title {
      color: $theme-text-primary;
    }

    .selected-option-description {
      color: $theme-text-primary;
    }
  }
}

@import "./src/assets/stylesheets/scss/tokens";
@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.sidebarMenu {
  background: $theme-background-sidebar;
  position: relative;
  overflow-y: auto;
  height: 100%;
  max-width: 240px!important;
  .topBar {
    width: 100%;
    min-height: 60px;
    background-color: $theme-background-sidebar;
    position: relative;
    @include desktop {
      min-height: 55px;
    }
    @include mobile{
      background-color: $theme-background-sidebar;
      position: sticky;
      top: 0;
    }
    &.customBrandLogo {
      min-height: 120px;
      @include mobile{
        min-height: 50px;
      }
    }
  }
  .toggleBtn {
    position: absolute;
    top: 10px;
    right:-10px;
    transition: opacity .3s;
    -webkit-transition:  opacity .3s;
    -moz-transition:  opacity .3s;
    -o-transition:  opacity .3s;
    z-index: 2;
    cursor: pointer;
    button svg path[fill] {
      fill : $theme-white !important;
    }
  }
}

.menuList {
  list-style-type: none;
  padding-bottom: 1.25rem;
  @include mobile{
    padding-bottom: 6.25rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  
  .itemContent{
    display: inline-flex !important;
    align-items: center;
    padding: 0 0 0 REM(10);
    height: 100%;
    margin: 0 0 0 REM(20);
    
    .text{
      margin: 0 0 0 REM(10);
      @include type-lg-bold;
    }
    .hiddenText {
      display: none;
    }
    .icon{
      stroke: currentColor;
      stroke-width: 1px;
      fill: transparent;
      stroke-linejoin: round;
      display: inline-block;
      line-height: normal;
      svg {
        path {
          fill: currentColor;
        }
      }
    }
    .dropdownIcon {
      display: none;
    }
    @include mobile{
      .dropdownIcon {
        display: block;
        position: absolute;
        right: 25px;
      }
    }
    .fas {
      text-align: center;
      width: 26px;
      &.labs-icon {
        color: inherit;
      }
    }
  }
  @include mobile{
    a, button{
      width: 100%;
    }
    .itemContent{
      margin: 0;
      padding: 0 0 0 1rem;
      width: 180px;
      text-align: left;
      display: flex;
      align-items: center;
      .text{
        font-weight: 500!important;
      }
    }
  }
  
  a, button {
    background-color: transparent!important;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 50px;
    padding:REM(5) 0;
    color: $theme-text-sidebar;
    box-shadow: none;
    outline: none;
    border: none;
    &.active {
      color: $theme-text-sidebar-active;
      @include desktop{
        &:before{
          content: '';
          width: 5px;
          height: 100%;
          background-color: $theme-text-sidebar-tab-bar;
        }
      }
    }
    &:hover{
      cursor: pointer;
      .icon {
        color: $theme-text-sidebar-active;
        fill: $theme-text-sidebar-active;
        opacity: 0.8;
      }
      .text {
        color: $theme-text-sidebar-active;
        opacity: 0.8;
      }
    }
  }
  button{
    position: relative;
    width: 100%;
  }
  
  .sidebarDropdown{
    list-style-type: none;
    li {
      padding-left: 0.75rem;
      @include mobile{
        padding-left: 0;
      }
    }
  }
  
  .fa-stack{
    height: 1em;
    width: auto;
    margin-left: REM(20);
    margin-right:REM(12);
    line-height: 1em;
    @include mobile{
      margin-left: 0;
    }
  }
  
  ul {
    border-left: none;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    
    
  }
  
  .faxing-list{
    @include mobile{
      padding-left: 0;
    }
    .fa-arrow-left, .fa-arrow-right{
      width: 25px;
    }
    .fa-stack{
      margin-right: REM(4);
      @include mobile{
        margin-right: 0;
      }
    }
    @include mobile{
      .itemContent{
        padding-left: REM(25);
      }
    }
    
  }
  
  .unreadNotification {
    @include type-lg-bold();
    display: inline-block;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 5px;
    text-align: center;
    vertical-align: middle;
    @include only-for-ie{
      margin: REM(-10) 0 0 0;
    }
    
    border-radius: 50%;
    background-color: $theme-notice-bold-warning;
    line-height: 20px;
    text-indent: 0;
    font-size: 10px;
    color: $theme-white;
    box-shadow: 0 0 5px 0 rgba(237,82,108,0.5);
    @include mobile {
      line-height: 20px;
      height: 20px;
      width: 20px;
      margin-left: REM(-5);
      right: 25px;
    }
  }
}
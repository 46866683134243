@import '../../../../assets/stylesheets/scss/tokens';

.mainHeader {
  @include type-h3;
  padding-bottom: 2rem;

  .subHeader {
    @include type-lg;
  }
}

.columnHeader {
  @include type-h4;
  padding: 0 0.75rem !important; // Needed to override col-4 padding
  width: 100%;
}

.formWrapper {
  padding: 1.75rem 1.5rem;

  .column {
    padding: 0.75rem 0.5rem !important; // Needed to override col-4 padding
  }
}

.formSection {
  color: $theme-text-primary;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  &:not(.reconciliation) {
    .title {
      max-width: 90%;
    }
  }
  
  &.reconciliation {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  svg {
    margin-right: 0.75rem;
  }

  .inputContainer {
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    input {
      margin: 0 0.5rem;
    }
    .labelWrapper {
      display: flex;
      flex-wrap: nowrap;
    }
  }
  .optionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow-wrap: anywhere;
  }

  .title {
    @include type-lg-bold;
    max-width: 50%;
  }
  .property {
    @include type-lg;
  }
  .errorText {
    color: $theme-notice-bold-warning;
    align-self: center;
    @include type-sm;
  }
}

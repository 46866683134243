@import './src/assets/stylesheets/scss/tokens';

.sectionHeaderRow {
  margin-bottom: 0 !important;
  border-bottom: 1px solid $theme-border-primary;
  .title {
    @include type-lg-bold;
    color: $theme-text-primary;
  }
  .action {
    text-align: right;

	button {
		padding-right: 0;
	}
  }
  &.isDisabled {
    border-bottom: 1px solid $theme-border-primary;
    .title {
      color: $theme-text-secondary;
    }
  }
  &.noBottomLine {
    border-bottom: none;
  }
}

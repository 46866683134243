@import 'src/assets/stylesheets/scss/tokens';

.header {
  @include type-sm-bold-uppercase;
  color: $theme-text-secondary;
  text-transform: uppercase;
  background: $theme-background-secondary;
  padding: 17px 24px;
}

.container {
  padding: 0 20px;
}

@import 'src/assets/stylesheets/scss/tokens';

.brandLogoWrapper {
  padding-right: 2.25rem;

  .brandLogo {
    display: inline-block;
    max-height: 61px;
    max-width: 110px;
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
}

.providerAddress {
  @include type-lg;
  padding: .5rem;
  color: $theme-text-primary;
  height: 84px;
}

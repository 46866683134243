// Pad contents to align with header
.collapsibleSectionContents {
  // Header padding (20px == 1.25rem) - collapsible section contents padding (1rem)
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.toggleButton {
  justify-content: flex-start;
}

@import "src/assets/stylesheets/scss/_tokens";

.moduleTable {
  table-layout: fixed;
  width: 100%;

  .checkboxColumn {
    width: 5%;
  }

  .labelColumn {
    width: 50%;
  }

  .idColumn {
    width: 30%;
  }
}
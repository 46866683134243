@import "src/assets/stylesheets/scss/_tokens";


.formAnswerContainer {
  margin-bottom: 3rem;

}
.previousVersions{
  background-color: $theme-background-secondary;
  border-radius: 10px;
  padding: 1rem 1rem 2rem;
  margin: 1rem;
}

.noPreviousVersion{
  @include type-lg-bold;
  color: $theme-notice-bold-warning;
}

@import "src/assets/stylesheets/scss/tokens";

.appointmentRequestItem {
  border: 1px solid $theme-border-primary;
  border-radius: 10px;
  padding: 1rem !important;
}

.divider {
  border-top: 1px solid $theme-border-primary;
}

.inputWrapper {
  label[for="service_date"] {
    margin: 0;
  }
  
  div[class="react-datepicker-wrapper"],
  input[name="service_date"] {
    width: 100%;
  }
}

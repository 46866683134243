@import "src/assets/stylesheets/scss/tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";


.chatControlSidebar {
  border-right: 1px solid $theme-border-primary;

  @include mobile {
    max-width: 100%;
    z-index: 5;
  }
  width: 100%;
  min-width: 200px;
  max-width: 200px;

  &__new_conv {
    @include mobile {
      display: none;
    }
  }

  &__filters_box {
    @include mobile {
      position: fixed;
      width: 100%;
      height: 100%;
      padding-top: 14px;
    }
  }

  &__section_content {
    padding-top: 6px;

    >div {
      margin-bottom: 20px;

      h3 {
        display: none;
      }
    }

    :global .chat-filter-container {
      .filter-count {
        display: none;
      }
    }
  }

  &__filters_title {
    color: $theme-text-primary;
    @include type-sm;
    line-height: 100%;
    margin-bottom: 8px;
  }

  &__filters {
    padding: 0px 10px;

    :global .Select.Select--multi.is-open {
      .Select-control {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    :global .Select:not(.is-searchable) {
      .Select-input[style] {
        display: none !important;
      }
    }

    :global .Select.Select--multi {
      height: initial;
      .Select-control {
        height: 36px;
        border: 1px solid $theme-accent-primary;
        border-radius: 4px;

        .Select-arrow-zone {
          width: 32px;
          padding: 0px;
          >svg {
            pointer-events: none;
          }
        }

        .Select-multi-value-wrapper {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 5px;

          .Select-value {
            display: flex;
            background-color: $theme-background-primary;
            border-color: $theme-accent-primary;
            margin: 7px 0 0 7px;
            border-radius: 4px;

            .Select-value-icon, .Select-value-label {
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: initial;
              padding: 0px;
            }

            .Select-value-icon {
              width: 20px;
              line-height: 18px;
              font-size: 17px;
              color: $theme-accent-primary;
              flex-shrink: 0;
            }

            .Select-value-label {
              color: $theme-accent-primary;
              @include  type-sm;
              padding: 4px 4px;
              line-height: 10px;
            }
          }
        }
      }
    }

    :global {
      .chat-filter-container {
        width: 100%;
        > h3 + div {
          display: flex;
          width: 100%;
          > div {
            padding: 0px;
            flex-grow: 1;
            justify-content: center;
            &:hover:not([class*='isActive']) {
              background-color: $theme-background-hover;
            }
            &.right-position {
              border-radius: 0px 4px 4px 0px;
            }
            &.left-position {
              border-radius: 4px 0px 0px 4px;

            }
            > span {
              @include type-sm;
              color: $theme-accent-primary;
            }
          }
          div[class*='isActive'] {
            background-color: $theme-accent-primary;
            > span + span {
              color: $theme-white;
            }
          }
        }
      }

      .cp-sidebar-expandable-section.base-section-styling {
        .cp-sidebr-section-header {
          padding: 15px 0px 12px 0px;
          margin-bottom: 12px;
          cursor: pointer;

          .left-flex-item {
            .medium-subtitle {
              transition: all 250ms ease;
              color: $theme-text-primary;
              @include type-lg-bold;
            }
          }

          .right-flex-item {
            span {
              svg {
                g g {
                  transition: all 250ms ease;
                  fill: $theme-text-primary;
                }
              }
            }
          }

          &:hover {
            .left-flex-item {
              .medium-subtitle {
                color: $theme-text-primary;
              }
            }

            .right-flex-item {
              span {
                svg {
                  g g {
                    fill: $theme-text-primary;
                  }
                }
              }
            }
          }

          .sidebar-icon{
            vertical-align: super;
            color: $theme-accent-primary;
          }
        }
      }
    }
  }
}
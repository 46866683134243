@import "src/assets/stylesheets/scss/tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.modal {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  z-index: 40;
  left:0;
  top:0;
  right:0;
  position:absolute;
  bottom:0;
  display:flex;
  align-items:center;
  flex-direction: column;
  justify-content: center;
  &.fixed {
    position:fixed;
  }
  
}


.modalBackground {
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;
  background-color: rgba(10,10,10,.86);
}

.modalContent {
  margin: 3.125rem auto 0px auto;
  width: 60%;
  position:relative;
  max-height: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  &::-webkit-scrollbar { 
    display: none;
  }
  
  .section {
    padding: 1.5rem;
    overflow-y: auto;
    background-color: $theme-background-primary;
    border-radius: 6px;
    box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
    color: $theme-text-primary;
    display:block;
  }

  .Select-menu-outer{
    position:absolute;
    z-index: 100;
  }
}

.modalScrollbar {
  position: relative;
  overflow:hidden;
  width:100%;
  height: 75vh;
  .scroll {
    position:absolute;
    inset: 0px;
    overflow: scroll;
    margin-right: -15px;
    margin-bottom: -15px;
  }
}

.confirmMessageContent h3{
 text-align: center;
}     

@include mobile {
  .modal {
    .modalContent:not(.docs-share-modal) {
      width: 90%!important;
      margin: 50px auto 0px auto;
    }
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.tooltip {
  pointer-events: auto !important;
  z-index: 3;
  opacity: 1 !important;
  padding: 0.75rem !important;
  max-width: 312px;
  border-radius: 0.5rem !important;
  background: rgba(0,0,0,0.9) !important;
  color: $theme-white;
  @include type-sm;

  &:hover {
    visibility: visible;
    opacity: 1;
  }
  .header {
    @include type-sm-bold;
    color: $theme-white;
    word-wrap: break-word;
    cursor: default;
  }
  .text {
    @include type-sm;
    padding: 0.5rem 0;
    color: $theme-white;
    word-wrap: break-word;
    cursor: default;
    a {
      color: $theme-white;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .button {
    text-align: right;
  }
}

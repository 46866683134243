@import "src/assets/stylesheets/scss/_tokens";


.supportSubmitBtn {
    display: flex;
    justify-content: center;
    @include type-lg-bold;
}

.refreshLabel {
    color: $theme-text-primary;
    @include type-lg-bold;
    margin-bottom: 0.625rem;
}

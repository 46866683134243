@import "./src/assets/stylesheets/scss/tokens";

.label {
  height: fit-content;
  margin: 0 0 0.25rem 0;
  text-align: left;
  color: $theme-text-primary;
  @include type-sm;
}
.labelTooltip {
  display: flex;
  padding-bottom: .125rem;
  margin-left: .25rem;
  align-items: center;
  margin-bottom: 1px;
}
.tooltip {
  max-width: 300px;
  margin-left: .2rem;
  margin-bottom: 0px;

  & .tooltipIcon {
    color: $theme-accent-primary;
    margin-left: 10px;
    margin-bottom: 3px;
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
}

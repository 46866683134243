@import "./src/assets/stylesheets/scss/tokens";

.container {
  z-index: 1000;
  background-color: rgba(10, 10, 10, .86);

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $theme-background-primary;
  }

  &.fixedFullscreenBackground {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    transform: translate(-50%, -50%);
  }
}

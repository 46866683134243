@import "src/assets/stylesheets/scss/tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";


.chatControlSidebarHeader {
  display: flex;
  border-bottom: 1px solid $theme-border-primary;
  height: 60px;
  >div {
    width: 100%;
  }

  .withHover {
    &:hover {
      background-color: $theme-background-hover;
      cursor: pointer;
    }
  }

  &__main {
    display: flex;
    height: 100%;
    align-items: center;
    position: relative;
    padding-left: 16px;
  
    span {
      color: $theme-text-primary;
      @include type-lg-bold;
      line-height: 22px;
      padding-left: 5px;
    }

    svg {
      margin-left: 0.5rem;
      position: relative;
      top: 2px;
    }
  
    &__options {
      position: absolute;
      top: 52px;
      left: 7px;
      display: flex;
      flex-direction: column;
      z-index: 5;
      background: $theme-background-primary;
      border: 1px solid $theme-border-primary;
      box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
      height: 74px;
      width: 180px;
  
      >p {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding-left: 10px;
        color: $theme-text-primary;
        @include type-lg-bold;
        line-height: 100%;
  
        &:not(:last-of-type) {
          border-bottom: 1px solid $theme-border-primary;
        }
  
        &:hover {
          background-color: $theme-background-hover;
          cursor: pointer;
        }
      }
    }
  }

  &__mobile_trigger {
    @include mobile {
      display: flex;
    }
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90px;
    border-left: 1px solid $theme-border-primary;
    @include type-sm;
    color: $theme-accent-primary;
  
    >div {
      height: 20px;
    }
  }
}
.asideModalHeaderComponent {
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;
  width: 100%;
  .headerActions {
    display: flex;
    gap: 0.75rem
  }
}

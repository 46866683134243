@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";
@import "./src/assets/stylesheets/scss/tokens";

.editUserProfileContainer {
  width: 100%;
  &.gridAdjustment{
    margin: 0;
    justify-content: center;
  }
  .title{
    @include type-h4;
    font-style: normal;
    font-stretch: normal;
    color: $theme-text-primary;
    line-height: normal;
  }
  .helperText {
    @include type-sm;
    @include fullhd {
      @include type-lg;
    }
    margin-bottom: 0.5rem;
  }
  .fieldBottomMargin {
    margin-bottom: 0.75rem;
  }
  .currentTime {
    @include type-lg;
    margin-bottom: 0.75rem;
  }
  .submitBtn {
    width: 100%;
    text-align: center;
    margin: 1rem 0;
  }
}
.pronounField {
  margin-bottom: 12px;
  padding: 0;
  display: block;
  > div:first-child {
    display: block;
    max-width: unset;
    width: 100%;
    &:last-child {
      margin-left: 0;
    
      & > div:last-child {
        position: unset;
        display: block;
      }
    }
  }
}

@import "src/assets/stylesheets/scss/tokens";

.editFormAnswerWrapper {
  min-height: 100%;
  background-color: $theme-background-secondary;

  :global {
    .Select-menu-outer{
      z-index: 2;
    }

    .danger-color .checkmark {
      border-color: $theme-notice-bold-warning;
    }

    .input.danger-color,
    .location-field.danger-color input[type=text],
    .location-field.danger-color .Select-control,
    .no-selected-value.danger-color .Select-control,
    #card-element.danger-color .stripe-style,
    .signature-input.signature-button-container.danger-color canvas,
    .virtual-select-with-search-icon.danger-color .Select-control,
    .mce-wrapper.danger-color .mce-tinymce.mce-container.mce-panel {
      border: 2px solid $theme-notice-bold-warning !important;
    }
  }
}

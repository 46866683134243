@import "../../../assets/stylesheets/scss/tokens";

.searchBarForm {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 13rem;
  height: 2.5rem;
  max-width: 20rem;
 > svg{
    position: absolute;
    left: 13px;
    top: 11px;
  }

  input {
    height: 100%;
    width: 100%;
    padding: 0 36px;
    border-radius: 4px;
    border: 1px solid $theme-border-input;
    background-color: $theme-background-input;
    color: $theme-text-primary;

    &::placeholder {
      color: $theme-text-secondary;
    }

    &:focus {
      border-color: $theme-accent-primary;
    }
  }

  div {
    position: absolute;
    right: 15px;
    top: 10px;
    display: flex;
    cursor: pointer;
  }
}

@import "src/assets/stylesheets/scss/_tokens";

.displayLabPatientContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid $theme-accent-primary;
  padding: 10px 15px;
  background-color: $theme-background-secondary;
  min-height: 10vh;
  @include shadow-sm;
  border-radius: 10px;

  .patientButtonContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.noLabFound {
  justify-content: space-around;
  align-items: center;
}
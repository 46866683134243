@import "src/assets/stylesheets/scss/tokens";

.goalModuleWrapper {
  align-items: center;
}

.goalTitle {
  @include type-h4
}

.description {
  @include type-lg
}

@import "./src/assets/stylesheets/scss/tokens";

.container {
  padding: 0.75rem;
  border-radius: 5px;
  background: $theme-background-primary;
  border: 1px solid $theme-border-primary;
  @include shadow-sm;
  
  .allCalendarsIcon {
    width: REM(50);
    height: REM(50);
    display: inline-block;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(../../../assets/images/Group@2x.png);
  }
}

@import 'src/assets/stylesheets/scss/tokens';

.orderRow {
  display: flex;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .orderRowName {
    display: flex;
    align-items: center;

    >:first-child {
      margin-right: 16px;
      align-items: center;

      &:not(svg) {
        width: 32px;
        height: 32px;
      }
    }
  }

  .orderRowActions {
    display: flex;
    align-items: center;

    p {
      @include type-sm;
      color: $theme-text-secondary;
      text-align: right;
    }
  }
}

@import "../../../assets/stylesheets/scss/tokens";
@import "../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";


@mixin hoveredActiveTab() {
  .tabLabel {
    color: $theme-accent-primary;
    @include type-lg;
  }
  &::before {
    content: '';
    position: absolute;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    top: unset;
    height: 1px;
    left: 0;
    bottom: -1px;
    width: 100%;
    background-color: $theme-accent-primary;
    transition: all .25s ease;
  }
}


.tabsContainer {
  padding-left: unset;
  border-bottom: 1px solid $theme-border-primary;
  width: 100%;
  min-width: fit-content;
  display: flex;
  overflow-x: auto;
  max-height: 55px;
  overflow-y: hidden;
  &.noBottomMargin {
    margin-bottom: 0;
  }

  @include mobile {
    padding: 0;
  }

  .tab {
    background-color: $theme-background-primary;
    border: none;
    margin: 0;
    padding: 0.75rem;
    position: relative;
    max-width: fit-content;
    min-width: fit-content;
    cursor: pointer;
    height: 54px;
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    place-items: center;


    .tabLabel {
      color: $theme-text-primary;
      @include type-lg;
      text-align: center;
      width: 100%;
      transition: all 250ms ease;
    }
    &.fixedWidth {
      min-width: 250px;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }


    &.isActive {
      .tabLabel {
        color: $theme-accent-primary;
        @include type-lg-bold;
      }
      &::before {
        content: '';
        position: absolute;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        top: unset;
        height: 4px;
        left: 0;
        bottom: -1px;
        width: 100%;
        background-color: $theme-accent-primary;
        transition: all .25s ease;
      }

      &::after {
        bottom: unset;
      }
    }
    &:not(.isActive):hover {
      @include hoveredActiveTab();
    }
    &:not(.isActive):not(.disabled):hover {
      @include hoveredActiveTab();
    }
    &.disabled:not(.isActive):hover {
      cursor: not-allowed;
    }

  }

}
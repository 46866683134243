@import "./src/assets/stylesheets/scss/tokens";

.collapsibleContent {
  margin: -1rem 0 0 0;
}

// AllergyFields

.verticalPadding {
  padding: 0.25rem 0 !important;
}

.topPadding {
  padding-top: 0.5rem !important;
}

// Added to lift the buttons in aside form above the Help button
.actionRow {
  padding-bottom: 4rem;
  padding-top: 0.75rem;
}

// Section headers

.sectionHeader {
  // !important required to override .row spacing rules
  margin-top: 0.75rem !important;

  .date {
    @include type-sm-bold-uppercase;
    color: $theme-text-secondary;
    // !important required to override .col spacing rules
    margin-left: -0.5rem !important;
  }
}

// AllergyRow

.severe {
  display: flex;
  @include type-lg-bold;
  color: $theme-notice-bold-warning;
}

.reactionType {
  word-break: break-word;
}

.thumbIcon {
  svg {
    vertical-align: middle;
  }
}

.noKnownAllergies {
  padding: 1rem 1rem 2rem
}

@import "./src/assets/stylesheets/scss/tokens";

.warningModal {
  z-index: 1105;
  .button-container {
    text-align: center;
    > button {
      margin-bottom: 10px;
    }
  }
}

.buttonContainer {
  margin-top: 2rem;
  text-align: right;
}

.apptWarningSection {
  margin-bottom: 1.5rem;
}

.apptWarningSectionHeader {
  @include type-h4;
  margin-bottom: 1.2rem !important;
}

.apptWarningSectionList {
  margin-bottom: 1.5rem;
  margin-left: 3rem;
  margin-top: 1.5rem;
  list-style-type: disc;
}

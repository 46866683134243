@import "./src/assets/stylesheets/scss/tokens";

.container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  background: $theme-background-primary;
  border-top: 1px solid $theme-border-primary;
  width: 100%;
  max-width: calc(100% - 240px);
  &.sidebarCloseWidth {
    max-width: calc(100% - 85px);
  }
  .title {
    @include type-sm-bold;
    text-transform: uppercase;
  }
}

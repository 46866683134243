@import 'src/assets/stylesheets/scss/tokens';
@import 'src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins';

.btnWrapper {
  position: relative;

  button[type="button"] {
    width: 100%;
  }

  .invoiceTemplatesPopup {
    padding: 0;
  }

  .searchContainer {
    height: 55px;
    padding: REM(10);
    border-bottom: 1px solid $theme-border-primary;
  }

  .invoiceTemplatesList {
    max-height: 260px;
    overflow-y: scroll;

    .searchBoxTitle {
      align-items: center;
      background-color: $theme-background-primary;
      display: flex;
      height: 46px;
      padding-left: REM(10);
    }

    .emptySearchMessage {
      text-align: center;
      padding-top: REM(20);
    }

    .listItems {
      display: grid;
    }

    .listItem {
      display: flex;
      align-items: center;
      height: 43px;
      padding-left: REM(10);
      cursor: pointer;
      border: none;
      background-color: transparent;

      &:not(:last-child) {
        border-bottom: 1px solid $theme-border-primary;
      }

      .invoiceType, 
      .invoiceName {
        @include type-sm;
      }
        
      .invoiceType {
        color: $theme-background-secondary;
        text-align: start;
        text-transform: uppercase;
        width: 90px;
      }

      .invoiceName {
        color: $theme-accent-primary;
      }
    }
  }

  .invoiceTemplatesListEmpty {
    display: flex;
    flex-direction: column;
    row-gap: REM(5);
    align-items: center;
    padding-top: REM(30);
    padding: REM(10);

    .title {
      @include type-h4;
    }

    .copy {
      max-width: 335px;
      text-align: center;
    }
  }

  @include desktop {
    button[type="button"] {
      max-width: 180px;
    }
  }
}

.tooltip {
  margin-top: 19px;
}

.tooltipWithMargin {
  margin-top: 34px;
}

.fieldPadding {
  padding-bottom: 1.5rem !important;
  margin-bottom: 0;
}
@import 'assets/stylesheets/scss/tokens';
.dropdownLabelText {   
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  p {
    @include type-lg-bold;
    color: $theme-accent-primary;
  }
}

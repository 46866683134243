@import "./src/assets/stylesheets/scss/tokens";

.field {
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child, .noBottomMargin) {
    margin-bottom: .75rem;
  }

  .labelContainer {
    display: flex;
    cursor: pointer;
    user-select: none;
    padding: 0;
    margin: 0;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    align-items: center;
    position: relative;
    color: $theme-text-primary;
    text-align: left;
    @include type-lg;
    padding-left: 5px;
    align-items: center;
    line-height: 14px;
  }

  [data-error-adapter] {
    position: absolute;
    bottom: -13px;
  }
}


@import "src/assets/stylesheets/scss/tokens";
.clientEmailExistsModal {
  .contentWrapper {
    word-break: break-word; // Break any very long emails in body and buttons
    margin: 0px 24px 24px 24px;
  }

  .buttonRow {
    margin: 18px 0px 0px 0px;
    padding: 0px;

    button {
      white-space: normal; // Allow wrapping on long names
    }

    .secondaryButton {
      width: 20%;

      button {
        width: 100%;
      }
    }

    .primaryButton {
      width: 75%;

      button {
        width: 100%;
      }
    }
  }

  .warning {
    background: $theme-notice-soft-warning;
    color: $theme-notice-bold-warning;
    padding: 18px;
    margin-top: 18px;
  }

  .familyLinkingNote {
    color: $theme-text-secondary;
    margin-top: 18px;
    @include type-sm;
  }
}

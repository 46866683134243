@import "src/assets/stylesheets/scss/tokens";

.shareInvoiceWrapper {
  .header {
    margin-bottom: REM(20);
  }

  .displayedInvoiceWrapper {
    min-width: 538px; 
  }

  .invoiceInfoSectionWrapper {
    padding-left: REM(35);
    min-width: 350px;
  }

  .emailInvoiceBtnWrapper,
  .downloadInvoiceBtnWrapper {
    margin-top: REM(15);
  }

  .downloadInvoiceBtnWrapper {
    width: fit-content;
  }

  .emailSentAtSectionText {
    margin-top: REM(15);
  }
}

@import "src/assets/stylesheets/scss/_tokens";

.profilePageContainer {
  margin-top: .6rem;
}

.headerContainer {
  min-height: 20vh;
  background: linear-gradient(to right, #3f0887, #5f36ac, #2654eb, #4b9bf1, #86f1e4);
  border: 1px solid $theme-border-primary;
  color: $theme-white;
  flex-wrap: nowrap;
}

.headerName {
  margin-left: 1rem;

  h2 {
    padding: 0rem 0rem 0rem .5rem;
  }
  p {
    padding: 1rem 0rem 0rem .5rem;
  }
}

.headerActionButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
  

  & > *:not(:last-child) {
    width: 200px;
    margin-bottom: 0.75rem;
  }

  button:not(:first-child) {
    width: 200px;
    background-color: white;
  }
}

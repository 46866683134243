@import "src/assets/stylesheets/scss/tokens";

.alertContainer {
  width: auto;
  height: 20px;
  display: flex;
  background: $theme-notice-soft-alert;
  border-radius: 10px;
  color: $theme-notice-bold-alert;
  padding: 4px;
  margin: auto 8px;
  text-transform: uppercase;
  @include type-sm-bold-uppercase;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

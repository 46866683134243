@import "../../../assets/stylesheets/scss/tokens";

.sortableTableHeader {
  cursor: pointer;
  white-space: nowrap;
  svg {
    margin-left: 5px;
    vertical-align: bottom;
  }
  &.isActive {
    color: $theme-text-primary;
  }
}

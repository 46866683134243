@import "src/assets/stylesheets/scss/tokens";
@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.listPaymentCards {

  .grid {
    display: grid;
    grid-template-columns: 8.335% 83% 8.335%;

    // Set last child to have no padding
    >:last-child {
      padding: 0;

      button {
        padding: 0;
      }
    }

    @include desktop {
      .itemsGroup {
        display: grid;
        grid-template-columns: 50% 25% 25%;
        padding: 0;
      }
    }

    @include mobile {
      grid-template-columns: 15% 65% 20%;
      grid-auto-flow: row;

      >:last-child {
        padding: 0.75rem;
      }

      .itemsGroup {
        padding: 0;
      }
    }

    @include tablet-to-desktop {
      grid-template-columns:  8.335% 80% auto;

      >:last-child {
        padding: 0.75rem;
      }

      .itemsGroup {
        display: grid;
        grid-template-columns: 50% 25% 25%;
        padding: 0;
      }
    }
  }
  
  .expiryWarn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    border-radius: 15px;
    height: 30px;
    color: $theme-white;
    @include type-lg-bold;

    span {
      padding-left: 0.5rem;
    }

    @include mobile {
      min-width: 70px;
      padding-left: 5%;

      span {
        @include type-sm;
        line-height: 10px;
      }
    }
  }

  .expired {
    background-color: $theme-notice-bold-warning;
  }

  .expiringSoon {
    background-color: $theme-notice-bold-success;
  }
}

@import 'src/assets/stylesheets/scss/tokens';

.selectClientModal {
  > section {
    height: 100%;
  }

  .buttonsContainer {
    margin-top: 25px;
  }
}
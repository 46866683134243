@import "../../../assets/stylesheets/scss/tokens";
@mixin check-icon-hover {
  #checked-goal-icon-path, #unchecked-goal-icon-path {
    fill: $theme-text-secondary;
  }
  #checked-goal-icon-polygon, #unchecked-goal-icon-polygon {
    fill: $theme-accent-primary;
  }
}

.goalCompleted{
  background: $theme-background-secondary !important;
  p, span {
    color: $theme-text-secondary !important;
  }
  .goalTitle{
    text-decoration: line-through;
  }
}

.goalBlock {
  background: $theme-background-primary;
  border: 1px solid $theme-border-primary;
  border-radius: 9px;
  @include shadow-sm;
  height: 174px;
  width: 261px;
  margin-right: 18px;
  padding: 15px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition-duration: .3s;
  transition-property: width, height;
  transition-timing-function: ease-out;
  a {
    text-decoration: underline;
    color: $theme-text-primary;
  }

  .goalFooter{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    :global(.mark-goal-complete-container){
      height: 40px;
    }
    .goalInfo{
      align-self: center;
    }
    svg {
      width: 40px;
      height: 40px;
    }
    svg:hover{
      @include check-icon-hover
    }
  }

  .goalTitle{
    color: $theme-text-primary;
    @include type-h4;
    height: 82px;
    width: 235px;
    transition: ease-out .1s;
  }

  .titleTooltip {
    max-width: 400px;
    white-space: normal;
  }

  .goalType{
    color: $theme-text-secondary;
    text-transform: uppercase;
    margin-right: 12px;
    @include type-sm
  }

  .goalDate{
    color: $theme-text-secondary;
    height: 16px;
    display: inline-block;
    @include type-sm
  }

  .subgoalsTitle{
    color: $theme-text-primary;
    text-transform: uppercase;
    @include type-sm-bold
  }

  .closeGoalDetails{
    width: 261px;
  }
}

.clientGoalSubgoal {
  height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  :global(.mark-goal-complete-container) {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }
  svg{
    width: 24px;
    height: 24px
  }
  svg:hover{
    @include check-icon-hover
  }
}

.moreDetailsWrapper{
  position: relative;
}

.subgoalsBlock {
  transition-duration: .3s;
  transition-property: width, height, opacity;
  transition-timing-function: ease-in;
  width: 0;
  height: 0;
  opacity: 0;
  background: $theme-background-secondary;
  border-radius: 3px;
  overflow-y: auto;
  padding: 10px 0 0 10px;

  a {
    color: $theme-accent-primary;
  }
}

.expandedGoalBlock {
  .subgoalsTitle {
    margin-bottom: 9px;
  }
  .subgoalsBlock {
    margin-bottom: 0;
    opacity: 1;
    height: 120px;
    width: 477px;
  }
  .goalBlock{
    height: 360px;
  }
}

.expandedGoalBlockShrinked{
  .goalBlock{
    height: auto;
  }
  .positioningGoalWrapper {
    &:not(.standalone) {
      height: 174px !important;
    }
  }
}

.expandedGoalBlock, .expandedGoalBlockShrinked{
  z-index: 10;
  @media screen and (min-width: 769px) and (max-width: 776px){
    .goalBlock{
      width: 498px !important;
      padding: 15px 15px 15px 11px !important;
      .closeGoalDetails{
        width: 498px !important;
      }
    }
  }
  .goalBlock{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 506px;
    justify-content: flex-start;
    padding-bottom: 0 !important;
    position: relative;
    .goalTitle{
      width: 430px;
      height: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.standalone {
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      margin: 0 auto;
    }
    .goalInfo{
      margin-top: 15px;
    }
    .clientGoalDescriptionContainer{
      opacity: 1;
      min-height: auto;
      max-height: 104px;
      overflow-y: scroll;
    }
    .closeGoalDetails{
      width: 506px;
    }
  }
}

.clientGoalDescriptionContainer{
  min-height: 67px;
  width: 476px;
  opacity: 0;
  transition-delay: .2s;
  transition-timing-function: ease-in;
  transition-duration: .2s;
  transition-property: max-height, opacity;
  white-space: pre-wrap;
  span {
    color: $theme-text-primary;
    font-size: 14px;
    font-weight: normal;
  }
}


.clientGoalBlockWrapper .closeGoalDetails {
  width: 261px;
  position: absolute;
  transition: ease-out .3s;
  left: 0;
  bottom: -41px;
}

.clientGoalBlockWrapper{
  margin-top: 18px;

  :global(.future-goals-container__body) & {
    opacity: 0.4;
  }

  &:not(:last-child) {
    margin-right: 18px;
  }
  &:not(.standalone) {
    max-width: 261px !important;
  }

  @media screen and (min-width: 537px) {
    &:hover{
      .moreGoalDetails {
        opacity: 1;
        transform: translate(0, -7px)
      }
      .goalBlock {
        &:not(.standalone) {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  }
}

.leftTransition{
  .goalBlock{
    position: absolute;
    right: -18px;
  }
  .closeGoalDetails{
    right: 0;
    bottom: -41px;
  }
  .positioningGoalWrapper {
    &:not(.standalone){
      width: 261px;
    }
  }
}

.goalTitleContainer{
  height: 50px;

  .goalTitleAndCheck{
    display: flex;
    justify-content: space-between;
    height: 50px;
  }
  .goalTypeAndDate{
    display: flex;
    position: relative;
    top: -20px;
  }
  svg:hover{
    @include check-icon-hover
  }
}

.moreGoalDetails, .closeGoalDetails {
  background-color: $theme-background-secondary;
  height: 41px;
  color: $theme-accent-primary;
  text-align: center;
  width: 261px;
  line-height: 41px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  cursor: pointer;
}

.moreGoalDetails {
  opacity: 0;
  position: absolute;
  transition-delay: .1s;
  transition-duration: .3s;
  transition-timing-function: ease-out;
  transition-property: opacity, border-radius, transform;
  @media screen and (max-width: 536px){
    display: none;
  }
}

.positioningGoalWrapper {
  &:not(.standalone) {
    position: relative;
    height: 174px;
  }
}

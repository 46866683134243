@import "../../../../assets/stylesheets/scss/tokens";

.calendarContainer {
  :global {
    .react-datepicker {
      border-color: $theme-border-primary;
      margin: 0 0 -5px 0;
    }

    .react-datepicker__header {
      background-color: $theme-background-primary;
      border-color: $theme-border-primary;
    }

    .react-datepicker__day--outside-month {
      visibility: visible;
      color: $theme-text-secondary;
    }

    .react-datepicker__day--selected{
      background-color: $theme-accent-primary;

    }

  }
  .calendarDateInputContainer {
    width: 200px;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 5px auto;
    justify-content: center;

    span {
      margin: 0 5px;
      font-size: 20px;
      padding: 0 0 10px 0;
    }
    input {
      background-color: $theme-background-input;
      border: 1px solid $theme-border-input;
      text-align: center;
      margin: 0 0 10px 0;
      display:inline-flex;
      justify-content: flex-start;
      height:2.25em;
      min-height:30px;
      line-height: 20px;
      max-width: 100%;
      box-sizing:border-box;
      position: relative;
      vertical-align:top;
      padding: calc(0.375em - 1px) calc(0.625em - 1px);
      &.shortInput {
        width: 20%;
      }
      &.longInput {
        width: 30%;
      }
    }
    label{
      margin: 0 5px 0 0;
      height: 40px;
      line-height: 30px;
      display: block;
    }

    }

  }


@import "../../../assets/stylesheets/scss/tokens";
.filterResultsContainer {
  display: inline-flex;
  align-items: center;
  .filterOptions {
    display: inline-block;
  }
  .filterResultsTitle {
    color: $theme-text-primary;
    @include type-lg;
    margin-right: 8px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

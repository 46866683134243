@import "./src/assets/stylesheets/scss/tokens";

.mutationFormNavBar {
  min-height: 80vh;
  border-right: 2px solid $theme-border-primary;
}

.activeTab {
  color: $theme-accent-primary;
}

.navButton{
  height: 2rem;
  cursor: pointer;
  &:hover {
    background-color: $theme-background-hover;
    color: $theme-text-primary;
  }
}

.navButtonText {
  margin-left: 1rem;
}

.mutationFormContainer {
  display: flex;
  width: 100%;
}

.mutationFormChildContainer{
  margin-left: 2rem;
}

@import "src/assets/stylesheets/scss/tokens";

.userTags {
  .userTagsWrapper {
    display: flex;
    align-items: center;
  }

  .userTagsModalTrigger {
    display: flex;
    align-items: center;
    margin-right: 3px;
    cursor: pointer;

    svg {
      margin-top: -4px;
    }
  }

  .userTagsContainer {
    display: flex;
    flex-flow: row wrap;
    margin-left: 0.25rem;
  }

  .userTagsAddTrigger {
    color: $theme-accent-primary;
    @include type-sm;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: -3px;
  }
}

@import "../../../../assets/stylesheets/scss/tokens";

.breadCrumb {
		display: flex;
		width: fit-content;
		&:after {
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				font-size: 14px;
				content: "\F054";
				color: $theme-text-secondary;
				margin: auto 15px;
				width: 8px;
				height: 18px;
		}
		.name{
				@include type-lg-bold;
				color: $theme-accent-primary;
		}
		.userBlock{
				display: flex;
				align-items: center;
				:global {
						.patient-avatar {
								border: 2px solid $theme-white;
								display: flex;
								justify-content: center;
								align-items: center;
								margin-right: 10px;
						}
						
						.col {
								.patient-avatar {
										margin-right: auto;
								}
						}
				}
		}
}
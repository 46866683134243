.separatedDateAdapter {
	min-height: 49px;
	display: flex;
	flex-direction: column;
	position: relative;

	&:not(:last-child, .noBottomMargin) {
    margin-bottom: .75rem;
  }

	.control {
		display: flex;
		justify-content: flex-start;

		.separateDayField, .separateMonthField {
			height: 35px;
			width: 25%;
			max-width: 60px;
			margin: 0 10px 0 0;
			@media screen and (max-width: 768px) {
				width: 22%
			}
		}

		.separateYearField {
			height: 35px;
			width: 35%;
			max-width: 65px;
			@media screen and (max-width: 768px) {
				width: 32%;
			}
		}

		input::-webkit-contacts-auto-fill-button {
			display: none !important;
		}

		input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
			display: none;
			-webkit-appearance: none;
			margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
		}

		input[type=number] {
    	-moz-appearance: textfield; /* Firefox */
		}
	}

	[data-error-adapter] {
    position: absolute;
    bottom: -13px;
  }
}

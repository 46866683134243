@import 'src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins';

.clientAppointmentPay {
  height: 100dvh;
  
  .clientAppointmentPayContent {
    height: 100%;
  }

  @include mobile {
    -webkit-overflow-scrolling: touch;
    height: calc(100vh - 58px);
    overflow-y: scroll;
  }
}

@import "assets/stylesheets/scss/tokens";

.signatureFieldWrapper{
		width: 500px;
		height: auto;
		position: relative;
		.labelRow {
				display: flex;
				align-items: flex-end;
				margin-bottom: 10px;
		}
}
.signatureInput{
		width: fit-content;
		canvas{
				background-color: $theme-background-primary;
				border: 1px solid $theme-border-input;
				&:active, &:focus{
						background-color: $theme-background-primary;
						box-shadow: none;
						border: 1px solid $theme-accent-primary;
				}
		}
		.emptySignature {
				background: $theme-background-secondary;
		}
		hr {
				bottom: 55px;
				margin: auto;
				position: relative;
				background: $theme-accent-primary;
		}
		.signatureLabel{
				width: 100%;
				.labelDescription{
						@include type-lg;
				}
		}
		.placeholderText{
				display: none;
		}
		@media(max-width: 375px) {
				.signature-label {
						width: 120px;
				}
		}
}
@media(max-width: 812px) {
		.signatureFieldWrapper{
				width: 100%;
				padding: 0 10px;
				position: relative;
				.signatureInput{
						width: 100%;
						canvas {
								width: 100%;
						}
				}
				hr {
						width: 90%;
						margin:0 auto;
				}
				.placeholderText{
						display: block;
						position: absolute;
						top: 125px;
						left: 8%;
						color: $theme-text-secondary;
				}
	}
}


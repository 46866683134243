@import '../../../assets/stylesheets/scss/tokens';

.wrapper {
  .billingDivider {
    display: inline-block;
    height: 2px;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .billingSubtitle {
    color: $theme-text-primary;
    @include type-lg-bold;
    height: 14px;
    padding-bottom: 20px;
  }
}

.submitBtn {
		margin: 1.5rem auto;
		display: flex;
		justify-content: center;
		.iconSpan {
				display: flex;
				svg{
						margin-top: -3px;
				}
		}
}

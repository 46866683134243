.emptyStateTextContainer {
  padding: 0.625rem 1.875rem;
  text-align: center;

  .text {
    margin-top: 2.5rem;
  }
}

.scrollHandler {
  position: 'absolute';
  top: '4%';
  width: '100%';
  text-align: center;

  &.mobile {
    top: 2%;
  }

  &.loading {
    top: 1.5rem;

    span {
      padding-right: 1rem;
    }
  }
}
@import "src/assets/stylesheets/scss/tokens";

.multiFilterForm {
  max-width: 278px;

  .multiFilterFormHeader {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-bottom: 1px solid $theme-border-primary;

    span {

      &:first-child {
        color: $theme-text-primary;
        @include type-lg-bold;
      }

      &:last-child {
        color: $theme-text-secondary;
        @include type-sm;
      }
    }
  }

  .multiFilterFormSelections {
    padding: 16px 10px 16px 20px;

    .multiFilterFormOption {
      label {
        display: flex;
        align-items: center;
        height: 20px;
        padding: 18px 0px 18px 0px;

        span:first-of-type {
          margin-right: 8px;
        }

        span:last-of-type {
          padding-left: 0px;
        }
      }
    }
  }

  .multiFilterFormButtons {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    background: $theme-background-primary;

    > button {
      background: $theme-background-primary;
      color: $theme-accent-primary;
    }

    > .field button {
      width: 106px;
    }
  }
}

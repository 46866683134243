@import "src/assets/stylesheets/scss/_tokens";


.labPatientContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .labPatientTitle {
    @include type-lg-bold;
    text-align: center;
    margin-bottom: 35px;
  }

  .labPatientInfoContainer{
    display: flex;
  }
}
@import "./src/assets/stylesheets/scss/tokens";

.asideModalHeader {
		display: flex;
		justify-content: space-between;
		align-items:center;
		padding-bottom: 16px;
		border-top: none;
		border-left: none;
		border-right: none;
		background: $theme-background-primary;
		&.hasBorder {
				border-bottom: 1px solid $theme-border-primary;
		}
		.headerTitle {
				@include type-h4;
				color: $theme-text-primary;
				margin-bottom: auto;
		}
		.closeButton {
				justify-content: flex-end;
		}
}

@import '../../../../assets/stylesheets/scss/tokens';

.headerContainer {
  display: flex;
  height: 100%;
  align-items: center;
  min-width: 400px;
  justify-content: flex-end;

  :global(.quick-profile) {
    height: 100%;
    border-left: 1px solid $theme-border-primary;
    padding-left: 1rem;
    margin-left: 1rem;
  }
  .portalTarget {
    position: absolute;
    top: 60px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
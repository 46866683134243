@import "../../../assets/stylesheets/scss/tokens";

.messageBlastAlertContainer{
  background-color: $theme-background-primary;
  width: 704px;
  color: $theme-text-primary;
  height: 600px;
  .largeSubtitle {
    margin-bottom: 1.25rem;
  }
  .largeSubtitle, .assignTaskTitle {
    text-align: center;
    color: $theme-text-primary;
  }
  .contentBorder {
    border-right: solid 1px $theme-border-primary;
  }
  .columnStyle {
    padding: 1.25rem
  }
  .alertText {
    margin-bottom: 1.25rem;
    color: $theme-text-primary;
  }

}
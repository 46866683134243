@import "./src/assets/stylesheets/scss/tokens";

.formsContainer {
  margin: 1.5rem
}

.sectionNotificationContainer:not(:empty) {
  margin-bottom: 2rem;

  // add space between stacked notification banners
  > div + div {
    margin-top: 0.5rem;
  }
}

.sectionNotificationList {
  margin-left: 1rem;
  margin-top: 0.25rem;
}

.asideFormNotificationContainer:not(:empty) {
  margin-bottom: 2rem;

  // add space between stacked notification banners
  > div + div {
    margin-top: 0.5rem;
  }

  // move action to follow content vertically (vs horizontally)
  // > bannerInlineContainer > iconContainer + bannerContentWrapper
  > div > div + div {
    flex-direction: column;
    align-items: flex-start;

    // remove bottom padding when followed by action link/button
    // > bannerContent
    > div:first-child:not(:last-child) {
      padding-bottom: 0;
    }

    a {
      padding-bottom: 0.75rem;
      padding-left: 0.75rem;

      // prevent shift on hover where unseen border disappears
      &:hover {
        border: 1px solid transparent;
      }
    }
  }
}

.asideFormNotificationList {
  margin-left: 1rem;
  margin-top: 0.25rem;
}

.asideForm {
  padding-top: 0.5rem;

  input[type=checkbox] {
    &:not([disabled]) {
      cursor: pointer;
    }

    &[disabled] + label:hover {
      cursor: not-allowed;
    }
  }
}

.asideFormActionsRow {
  padding-top: 1.75rem;
  // pad form bottom for save button to avoid "Help" Beacon button
  padding-bottom: 6rem;
}

.confirmationModal {
  header {
    border-bottom: 0;
    margin-bottom: 1rem;
  }

  h5 {
    @include type-h4;
  }

  p {
    margin-bottom: 1rem;
    word-wrap: break-word;
  }
}

@import 'src/assets/stylesheets/scss/tokens';
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.PermissionTemplateWrapper {
  :global(.conversation-confirmations) {
    p {
      text-align: center;
      span {
        font-weight: 600;
      }
    }
  }
  .PermissionTemplateItem {
    display: flex;
    background: $theme-background-primary;
    border-radius: 5px;
    border: 1px solid $theme-border-primary;
    box-shadow: 0px 2px 5px 0px rgba(51, 51, 51, 0.15);
    min-height: 98px;
    margin-bottom: 16px;

    .LeftBlock {
      position: relative;
      display: flex;
      align-items: center;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      padding-left: 17px;
      background-color: $theme-background-secondary;
      width: 90px;
      min-width: 90px;
    }

    .RightBlock {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding-left: 36px;
      padding-top: 11px;
      padding-bottom: 11px;
    }
  }

  .TemplateTitle {
    height: 30px;
    color: $theme-text-primary;
    @include type-h4;
  }

  .PermissionTemplateIcon {
    width: 26px;
    height: 26px;
    svg {
      margin-left: 4px;
    }
    path {
      fill: $theme-text-secondary;
    }
  }

  .PermissionTemplateAvatar {
    position: absolute;
    right: -27px;

    :global {
      .patient-avatar {
        border: 2px solid $theme-white;
        background-color: $theme-background-secondary;
        color: $theme-text-secondary;
        @include type-lg;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @include mobile {
        .patient-avatar[style] {
          width: 38px !important;
          height: 38px !important;
          min-width: 38px !important;
          min-height: 38px !important;
          line-height: 38px !important;

          img[style] {
            width: 38px !important;
            height: 38px !important;
            min-width: 38px !important;
            min-height: 38px !important;
            line-height: 38px !important;
          }
        }
      }
    }
  }
}

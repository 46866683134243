@import "../../../../assets/stylesheets/scss/tokens";
.loadingStateContainer {
  position: relative;
  text-align: center;
  &.centered {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  svg path {
    stroke-width: 1;
    stroke: $theme-accent-primary;
  }
  .loadingSpinnerText {
    color: $theme-accent-primary;
    @include type-lg-bold;
  }
}

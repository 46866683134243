@import "./src/assets/stylesheets/scss/tokens";

.field {
  position: relative;
  height: 49px;
  display: flex;
  flex-direction: column;

  &:not(:last-child, .noBottomMargin) {
    margin-bottom: .75rem;
  }

  .input {
     @include type-lg;
    height: 35px;
    width: 100%;
    border: 1px solid;
    border-radius: 3px;
    padding: calc(0.375em - 1px) calc(0.625em - 1px);

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      appearance: none;
      -webkit-appearance: none;
    }

    &::placeholder {
      color: $theme-text-secondary;
    }

    &:focus, &:active, &:hover:not(.isDanger) {
      border-color: $theme-accent-primary;
    }

    &:not(:focus) {
      border: 1px solid $theme-border-input;
    }

    &.isDanger {
      border-color: $theme-notice-bold-warning;
    }
  }

  [data-error-adapter] {
    position: absolute;
    bottom: -13px;
  }
}

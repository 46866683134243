@import "src/assets/stylesheets/scss/_tokens";

.embedFormID {
  display: flex;
  align-items: center
}

.removeEmbedForm {
  display: flex;
  align-items: center;
}

.embedFormIDText {
  font-weight: bold;
  line-height: normal; 
}

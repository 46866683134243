@import "src/assets/stylesheets/scss/tokens";

.priceFields {
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div:first-child {
    max-width: 120px;
  }

  >div:last-child {
    padding-top: 10px;
  }
}

.radioGroup {
  margin-bottom: 20px;
}

.codesFields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  >div:first-child {
    flex-grow: 1;
    max-width: 270px;
  }

  >div:last-child {
    width: 110px;
    margin-left: 20px;
  }
}

.apptTypeInfo {
  display: flex;
  padding: 15px 0;
  margin-top: 15px;
  border-top: 1px solid $theme-border-primary;
}

.banner {
  margin-bottom: 10px;

  a {
    border: none;
  }
}

.tooltip {
  max-width: 450px;
}

.switchAccountProviderInfo {
  height: 269px;

  .orgInfoOnly {
    padding-top: 96px;
  }

  .noOrgInfo {
    padding-top: 20px;
  }

  .brandLogoContainer {
    height: 50px;

    img {
      max-height: 100%;
    }
  }

  .dietitian {
    .avatarContainer {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
    }
  }
}

.switchAccountProviderInfo > div {
  text-align: center;
  padding-top: 20px;
}
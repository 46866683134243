@import 'src/assets/stylesheets/scss/_tokens';

.border {
  color: $theme-white;
  background-color: $theme-background-primary;
  width: 100%;
  max-height: 0px;
  border: solid 1px $theme-border-primary;
  margin: 1rem 0.8rem 1rem
}

.searchBar{
  margin-bottom: 2rem;
}
.form {
  padding: .75rem 0;

  .checkbox {
    margin-bottom: 4px;
  }

  .notificationBanner {
    margin-bottom: 1rem;
  }

  :global {
    & p[class^="ErrorAdapter_errorText"] {
      position: unset;
      margin-top: auto;
      bottom: unset;
    }
  }
}

@import '../../../assets/stylesheets/scss/tokens';

.checkboxContainer {
  display: flex;
  padding-bottom: 10px;
  margin: 15px 0 10px;

  .labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    input {
      display: none;
    }

    h3{
      @include type-lg-bold;
      display: inline-block;
      width: 100%;
    }

    .selectedValues {
      color: $theme-text-secondary;
    }
  }
}

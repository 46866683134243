@import "assets/stylesheets/scss/tokens";

// row component

.cardRow {
  flex-wrap: nowrap;
  padding-left: 0;
  padding-right: 0;
}

.cardRowTopBorder {
  border-top: 1px solid $theme-border-primary;
}

.cardRowInlineActions {
  white-space: nowrap;

  button + button {
    // clear left margin and effectively collapse padding between buttons
    margin-left: -0.5rem;
  }
}

// col component

.cardRowCol {
  @include type-lg;
  word-wrap: break-word;
  // allow CardRowCol contents to wrap agnostic of word breaks;
  // otherwise, long and run-on content can extend the col size
  // past its specified width and, for example, force CardRow
  // actions to overflow or wrap past content
  overflow-wrap: anywhere !important;
}

.cardRowColSansLabel {
  // default padding is 0.75rem + (1rem + 0.75rem) to fill the label space;
  // !important is necessary to locally overcome specificity of .row .col,
  // and styles only apply with explicit empty string label for CardRowCol
  padding-top: 2.5rem !important;
}

.cardRowColLabel {
  @include type-sm;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
}

@import 'src/assets/stylesheets/scss/tokens';

.organizationMembersFiltersForm {
  .formWrapper {
    height: 88vh;
    display: flex;
    flex-direction: column;

    .filtersContainer {
      flex: 1;
      overflow-x: auto;
    }
  }
}

@import 'assets/stylesheets/scss/tokens';

@mixin iconSize {
  svg {
    width: 16px;
    height: 16px;
  }
}

// Icon color for secondary and tertiary buttons
@mixin iconColor($color, $property: fill) {
  svg g[fill]:not([fill='none']),
  svg path,
  svg path[fill],
  svg polygon[fill] {
    #{$property}: $color;
  }
}

@mixin iconStyles {
  &.default,
  &.transparent {
    color: $theme-accent-primary;
    @include iconColor($theme-accent-primary);
  }

  &.danger {
    color: $theme-notice-bold-warning;
    @include iconColor($theme-notice-bold-warning);
  }

  // Hover effect
  &:hover {
      &.default {
        @include iconColor($theme-accent-primary-hover);
      }
      &.danger {
        @include iconColor($theme-notice-bold-warning-hover);
      }
  }

  // Active effect
  &:active {
      &.default {
        @include iconColor($theme-accent-primary-hover);
      }
      &.danger {
        @include iconColor($theme-notice-bold-warning-hover);
      }
  }

  // Disabled effect
  &:disabled {
    @include iconColor(0.5, opacity);
    &:hover {
      cursor: not-allowed;
    }
  }
}

a.healthieButton {
  padding-block: 1rem;
  padding-inline: 1rem;
}

.healthieButton {
  @include type-lg-bold;
  height:2.5rem;
  max-height: 2.5rem;
  max-width: 100%;
  border-radius: 3px;
  border: none;
  text-align: center;
  margin: 0;
  padding: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: 0.3s;
  &:disabled,
  &:not(:disabled).loading {
    opacity: 0.5;
    border-color: $theme-background-disabled;
    background: $theme-background-disabled !important;
    color: $theme-white;
    cursor: not-allowed;
  }
  &.loading {
    align-items: center;
    display: inline-flex;
    gap: 8px;
  }
  &.rounded {
    border-radius: 1rem;
  }

  &:focus-visible {
    outline-offset: 2px;
    outline-style: solid !important;
    outline-color: $theme-accent-primary !important;
    outline-width: 2px !important;
  }
}

.buttonWithIcon {
  @extend .healthieButton;
  @include iconSize();
  height: inherit;
  padding: REM(8) !important;
  svg {
    display: block;
  }
}

.buttonWithText {
  @extend .healthieButton;
}

.buttonWithTextAndIcon {
  @extend .buttonWithText;
  @include iconSize();
  align-items: center;
  display: inline-flex;
  gap: 8px;
  &.startIcon > svg {
    order: 0;
  }
  &.endIcon > svg {
    order: 1;
  }
}

.primary,
.secondary,
.tertiary {
  & + button,
  & + a {
    margin-left: REM(8);
  }
}

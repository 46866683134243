@import "assets/stylesheets/scss/tokens";
.filterOption {
  height: 40px;
  border-color: $theme-accent-primary;
  border-style: solid;
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: $theme-accent-primary;
  border-radius: 0 0 0 0;
  border-width: 1px 0 1px 1px;
  &:first-child {
    border-radius: 3px 0 0 3px;
    border-width: 1px 0 1px 1px
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
    border-width: 1px 1px 1px 1px ;
  }
  
  .filterCount {
    margin-right: 0.25rem;
    color: $theme-accent-primary;
    @include type-lg-bold;
  }
  .filterTitle {
    @include type-lg;
    color: $theme-accent-primary;
  }
 
  &.isActive{
    cursor: initial;
    background-color: $theme-accent-primary;
    color: $theme-white;
    .filterTitle, .filterCount {
      color: $theme-white;
    }

    &:hover {
      background-color: $theme-accent-primary-hover;
    }
  }

  &:hover {
    background-color: $theme-background-secondary;
  }
}

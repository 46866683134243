@import "src/assets/stylesheets/scss/tokens";

.priceFields {
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div:first-child {
    max-width: 120px;
  }

  >div:last-child {
    padding-top: 10px;
  }
}

.apptTypeInfo {
  display: flex;
  padding: 15px 0;
  margin-top: 15px;
  border-top: 1px solid $theme-border-primary;
}

.tooltip {
  max-width: 350px;
}

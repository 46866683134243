@import 'src/assets/stylesheets/scss/_tokens.scss';

.subOrgForm {
  padding-top: 15px;
  margin: 0 20px;

  &__actions {
    display: flex;
    gap: 20px;
    padding: 20px 0 26px 0;
    border-top: 1px solid $theme-border-primary;

    :global {
      .healthie-button {
        border-radius: 4px;
      }

      .field:not(:last-child) {
        margin-bottom: 0px;
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
  }

  .fieldsRow {
    display: flex;
    gap: 20px;
  
    :global .field:not(:only-of-type) {
      width: 100%;
      .label {
        margin-top: 0px;
      }
    }

    :global div.field:only-of-type {
      margin-bottom: 0px;
      .custom-checkbox-container {
        margin-bottom: 0px;
      }
    }

    >span {
      height: 20px;
      :global {
        .react-tooltip {
          max-width: 331px;
        }
      }
    }
  }

  .fieldsSection {
    background: $theme-background-secondary;
    border-radius: 5px;
    padding: 20px;
    margin: 25px 0px 24px 0px;

    :global {
      .field:last-child {
        .custom-checkbox-container {
          margin-bottom: 0px;
        }
      }
    }
  }

  .fieldsRow, .fieldsSection {
    >span {
      :global {
        .react-tooltip {
          padding: 8px 12px;
          border-radius: 8px;
          text-align: left;
          word-break: break-word;

          >p:not(:first-child) {
            margin-top: 10px;
          }

          >p span {
            font-weight: 600;
          }

          >p, >p span {
            @include type-sm;
          }
        }
      }
    }
  }

  .infoBanner {
    margin-top: 17px;
    margin-bottom: 21px;
    border-radius: 8px;
    overflow: hidden;

    :global {
      .flash-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div.main-container:last-of-type {
        background: $theme-background-secondary;
        .msg-container {
          padding: 8px 12px;
        }
      }
    }
  }
}

.sectionDelimeter {
  display: flex;
  align-items: center;
  height: 48px;
  margin: 24px -20px;
  padding: 12px 24px;
  background: $theme-background-secondary;

  span {
    @include type-sm-bold-uppercase;
    color: $theme-text-secondary;
  }
}

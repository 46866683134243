@import "src/assets/stylesheets/scss/tokens";

.planHeader {
  @include type-sm;
  background-color: $theme-background-secondary;
  margin: 0 !important;
}

.planDetails {
  @include type-sm;
  color: $theme-text-secondary;
}

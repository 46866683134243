@import "src/assets/stylesheets/scss/_tokens";

.savedFilterSelection {
  #saved-filters-wrapper {
    & * > {
      background-color: none;
    }
  }
}

// .filterSelect {
//   @include select-enh();
// }

/*
  SC Epic: https://app.shortcut.com/healthie/epic/120018
  This is a hopefully short-lived override.
  When the Accordion updates (sc-117500) is released, and
  turned on for all customers, this can be deleted.

  I'll add a note to the Remove LD flag for saved filters to
  clear out this override code (sc-120032)
*/
.collapseOverride {
  &:first-child {
    margin: 0;
  }

  div[data-testid="collapsible-section-header"] {
    svg {
      width: 16.8px;
      height: 18px;
    }
    svg path {
      fill: $theme-text-primary;
    }
  }

  div[data-testid="collapsible-section-body"] {
    padding: 1rem 0;
  }

  div[data-testid="collapsible-section"] {
    padding: 0;
  }
}
/*
  End of overridding code, to be removed.
*/

.selectionButtons {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;

  button {
    text-align: left;
  }

  button:not(:first-child) {
    margin-left: 0;
  }
}

.savedFiltersAsideContent {
  padding: 1rem;
}

.savedFiltersList {
  margin-top: 1rem;
}

.savedFiltersCTA {
  margin-top: 2rem;
  text-align: right;
}

.savedFilterCopyBanner {
  margin: .25rem 0;
  height: '80px';
  width: '100%';
}

.deleteFilterModal {
  z-index: 2000;
}

.deleteFilterModalText {
  margin-bottom: 1rem;
}

.savedFilterOrgMessage {
  margin-top: 1rem;
  font-size: .8rem;
}

.savedFilterFormButtons {
  margin-top: 1.5rem;
  text-align: right;
}

.deleteFilterModalButtons {
  margin-top: 1.5rem;
  text-align: right;
}

.savedFilterRow {
  width: 100%;
  display: grid;
  grid-template-columns: 2.75fr 1.25fr;
  margin-bottom: .75rem;
  align-items: center;

  .savedFilterName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .savedFilterIcons {
    text-align: right;

    button {
      padding: .3rem;
      margin: 0;
    }
  }
}

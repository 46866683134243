@import "assets/stylesheets/scss/tokens";

.chip {
  border-radius: REM(24);
  text-align: center;
  max-height: REM(24);
  padding: 0.2rem 0.75rem;
  @include type-sm-bold;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  width: fit-content;
  border: 1px solid;
  &.info {
    background: $theme-background-secondary;
    color: $theme-notice-heavy-info;
    border-color: color-mix(in srgb, var(--theme-notice-heavy-info), transparent 75%);
  }
  &.success {
    background: $theme-notice-soft-success;
    color: $theme-notice-heavy-success;
    border-color: color-mix(in srgb, var(--theme-notice-heavy-success), transparent 75%);
  }
  &.warning {
    background: $theme-notice-soft-alert;
    color: $theme-notice-heavy-alert;
    border-color: color-mix(in srgb, var(--theme-notice-heavy-alert), transparent 75%);
  }
  &.error {
    background: $theme-notice-soft-warning;
    color: $theme-notice-heavy-warning;
    border-color: color-mix(in srgb, var(--theme-notice-heavy-warning), transparent 75%);
  }
}

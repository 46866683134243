@import 'src/assets/stylesheets/scss/tokens';

.invoiceTemplatesPopup {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}
.invoiceTemplatesList {
  max-height: 260px;
  overflow-y: scroll;
}

@import "./src/assets/stylesheets/scss/tokens";

.breadCrumb {
		display: flex;
		width: fit-content;
		&:after {
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						font-size: 14px;
						content: "\F054";
						color: $theme-text-primary;
						margin: auto 15px;
						width: 8px;
						height: 18px;
		}
		.name{
				@include type-lg-bold;
				color: $theme-accent-primary;
				&:hover, &:focus, &:active{
						cursor: pointer;
				}
		}
		&.isActive {
				position: relative;
				margin-right: 15px;
				&:after {
						position: absolute;
						content: '';
						height: 2px;
						width: 100%;
						top: 22px;
						left: 0;
						margin: 0;
						background-color: $theme-accent-primary;
				}
				.name {
						color: $theme-accent-primary;
						text-transform: capitalize;
				}
		}
		&.isLastCrumb {
				&:after{
						display: none;
				}
		}
}
@import 'src/assets/stylesheets/scss/tokens.scss';

.benefitsWrapper {
  position: relative;

  &::after,
  &::before {
    background-color: $theme-text-secondary;
    content: '';
    display: inline-block;
    position: absolute;
    top: 12px;
    height: calc(95% - 12px);
    width: 1px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  .benefitsRow {
    margin-bottom: 0.313rem !important;
  }
}

.benefitsCategory {
  @include type-lg-bold;
  text-transform: none;
  font-size: 14px;
  color: $theme-text-primary;
}

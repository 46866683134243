@import "../../../assets/stylesheets/scss/tokens";

.storybookTemplateWrapper {
  :global(#modal) {
    position: relative !important;
  }
}


.shareAndEmbedWrapper {
  display: flex;
  flex-direction: column;

  :global(#tab-container) {
    margin-bottom: 0px;
    border-bottom: none;
  }

  .shareLinkWrapper {
    height: 100px;
    padding: 18px 28px;
    margin-right: -25px;
    margin-left: -25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 2px solid $theme-border-primary;

    .linkAndCopyButton {
      display: flex;
      justify-content: space-between;
  
      .linkArea {
        overflow-x: scroll;
        padding: 6px;
        width: 80%;
        border-radius: 3px;
        white-space: nowrap;
        background: $theme-background-primary;;
        border: 0.5px solid $theme-accent-primary;
      }

      button {
        width: 120px;
      }
    }
    
    .disclaimerText {
      @include type-lg
    }
  }

  .customizationWrapper {
    padding: 20px 28px;
  }

  .previewHeader {
    height: 46px;
    margin: 10px -25px;
    padding: 13px 25px;
    display: flex;
    justify-content: space-between;
    span {
      @include type-lg-bold;
      text-transform: uppercase;
      
      a {
        @include type-sm-bold;
        svg {
          margin-bottom: -4px;
          margin-left: 4px;
        }
        &:hover {
          color: $theme-text-primary;
          svg g g {
            fill: $theme-text-primary;
          }
        }
      }
    }
  }

  .previewWrapper {
    height: 400px;
    
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

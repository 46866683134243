@import 'src/assets/stylesheets/scss/_tokens.scss';

.convoTasks {
  display: flex;
  background-color: $theme-notice-soft-alert;

  .leftBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 34px;
    padding: 18px 0;
    background-color: $theme-notice-bold-alert;
  }

  .mainBox {
    display: flex;
    width: 100%;
    padding: 8px 10px;

    .titleBox {
      padding-top: 8px;
      flex-shrink: 0;
      margin-right: 16px;

      span {
        @include type-lg-bold;
        color: $theme-text-primary;
      }
    }

    .contentBox {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      max-height: 120px;
      overflow: auto;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;

  input {
    width: 85px;
  }

  span {
    margin: 0 10px;
  }
}

.bloodPressureButton {
  display: flex;
  justify-content: center;

  >button {
    margin-top: 12px;
    position: static;
    width: 75px;
  }
}
@import "src/assets/stylesheets/scss/tokens";

.container {
  margin-top: 15px;
}

.fields {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  > div:not(:last-child) {
    width: 80px;
    margin-right: 20px;
  }
}

.banner {
  margin: 10px 0;

  i {
    color: $theme-white !important;
  }

  a {
    border: none;
  }
}

.text {
  p {
    @include type-sm;
  }

  a {
    @include type-sm-bold;
  }
}
@import 'src/assets/stylesheets/scss/tokens';

.recentLabWidget {
  background: $theme-background-primary;
  border-radius: 3px 3px 3px 3px;
  border: 1px solid $theme-border-primary;
  margin: 1rem 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 0 10px;
    background: $theme-background-primary;
    .left {
      .title {
        @include type-sm-bold-uppercase;
        color: $theme-text-primary;
        text-transform: uppercase;
      }
    }
    .right {
      .actions {
        display: flex;
        gap: 10px;

        >button {
          text-transform: uppercase;
          font-size: 9px;
          gap: 6px;
          &:not(:hover) {
            background-color: $theme-background-hover;
          }
          &:first-of-type {
            svg {
              width: 10px;
              height: 10px;
            }
          }
          &:last-of-type:not(:only-child) {
            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}

.content {
  padding: 1rem;
  .ordersList {
    display: flex;
    flex-direction: column;
    .ordersListFooter {
      display: flex;
      align-items: flex-end;
      justify-content: left;
      height: REM(36);
      margin-top: REM(16);
    }
    .ordersListFooterOld {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: REM(36);
      margin-top: REM(16);

      border-top: REM(1) solid $theme-border-primary;
    }
    .ordersListEmpty {
      @include type-lg;
      display: flex;
      justify-content: left;
      height: REM(25);
    }
    .ordersListEmptyOld {
      @include type-lg-bold;
      display: flex;
      justify-content: center;
      height: REM(25);
    }
  }
}

.ordersListGroup {
  display: flex;
  flex-direction: column;
  .groupTitle {
    @include type-sm;
    color: $theme-text-secondary;
    margin-bottom: REM(16);
    text-transform: uppercase;
  }
  &:not(:first-of-type) {
    .groupTitle {
      margin-top: REM(16);
    }
  }
}

.emptyState {
  >div {
    margin-top: 0;
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.topPadding {
  padding-top: 1.5rem;
}

.sectionHeaderRow {
	padding: 0.5rem 0;
	margin: 1.5rem -0.75rem !important;
	border-bottom: 2px solid $theme-border-primary;
	.title {
			@include type-sm-bold-uppercase;
			color: $theme-text-primary;
	}
}

@import '../../../../../assets/stylesheets/scss/tokens';

.individualClientNotesContainer {
  width: 375px;
  padding: 25px;
  margin-top: -21px;
  border-left: 1px solid $theme-border-primary;
  margin-left: 25px;

  .individualClientNotesHeader {
    display: flex;
    flex-direction: column;

    .individualClientNotesContainerTitle {
      color: $theme-text-secondary;
      @include type-h4;
    }
  
    .individualClientNotesContainerSubtitle {
      color: $theme-text-secondary;
      @include type-lg;
    }
  }

  .individualClientNotesContainerAttendees {
    margin-left: auto;

    .individualClientNotesContainerAttendee {
      margin-bottom: 20px;

      .attendeeName {
        cursor: pointer;
        color: $theme-accent-primary;
        @include type-sm-bold-uppercase;
      }

      .attendanceStatus {
        @include type-sm-bold-uppercase;
        pointer-events: none;
      }
    }
  }
}

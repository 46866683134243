@import "src/assets/stylesheets/scss/tokens";

.asideTabContainer {
  :global {
    .outside-click-handler {
      padding: unset !important;
    }

    .aside-tab-additional-wrapper {
      .aside-tab-content__header {
        padding: 24px 24px 16px;
        background: $theme-background-primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
        border-bottom: 1px solid $theme-border-primary;

        .header-text {
          color: $theme-text-primary;
          height: auto;
          @include type-h4;
        }

        .close-aside-tab  {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }

      .aside-tab-content__body {
        margin-top: 20px;
      }
    }

    .field label.label {
      color: $theme-text-primary;
      @include type-sm-bold
    }
  }

  .sectionHeader {
    padding: 0 24px;

    .sectionTitle {
      height: auto;
      border-left: unset;
    }

    .sectionInfo {
      margin-top: 4px;

      ul {
        margin: 6px 0 0px 20px;

        li { list-style: disc; }
      }

      p {
        margin-top: 12px;
      }

      .learnMore {
        margin-top: 10px;
        display: block;
      }
    }
  }

  .sectionRow {
    margin-top: 20px;

    .header {
      padding: 0 24px;
      background: $theme-background-secondary;
      min-height: 46px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      span:first-child {
        color: $theme-text-primary;
        @include type-sm-bold-uppercase
      }
    }

    .fieldsContent {
      padding: 20px 24px;

      .hasClients {
        color: $theme-text-primary;
        @include type-lg-bold
      }

      .text {
        margin-top: 4px;
        color: $theme-text-primary;
        @include type-lg
      }

      .fields {
        margin-top: 20px;

        :global {
          .help {
            display: block;
            font-size: 0.75rem;
            margin-top: 0.25rem;

            &.is-danger {
              color: $theme-notice-bold-warning;
            }
          }
        }
      }

      .sessionNote {
        color: $theme-text-secondary;
        @include type-sm
      }
    }

    .noClients, .noAppointments {
      span {
        color: $theme-text-primary;
        @include type-lg-bold
      }
    }
  }


  .sectionSubmit {
    display: flex;
    justify-content: center;
    padding: 0 24px;
    border-top: 1px solid $theme-border-primary;
    padding-top: 20px;

    button:first-child {
      margin-right: 18px;
      width: 290px;
    }
  }
}

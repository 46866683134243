@import "src/assets/stylesheets/scss/tokens";

.videoCallSettings {
  .settingHeader {
    @include type-lg-bold;
    padding-bottom: 6px;
  }

  .sidebarSectionContainer:global(.new-styled-expandable-section) {
    :global {
      .cp-sidebr-section-header {
        margin-bottom: 20px !important;
      }
    }
  }

  .addProviderButton {
    padding-top: 0px;
    margin-top: 0px;
  }

  :global {
    .radio-button-group {
      margin-bottom: 20px;
    }

    .field-wrapper {
      margin-bottom: 20px !important;
    }
  }
}

.advancedPricingErrorTxt {
  z-index: 2;
  margin-top: 0.3rem;
  color:$theme-notice-bold-warning;

  @include type-sm;
}

.verticallyAlignedLinkContent {
  a {
    align-content: center;
    display: inline-block;
  }
}

.autoPointerEvents {
  pointer-events: auto !important;
}

@import "assets/stylesheets/scss/tokens";

.container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.5rem;
  position: relative;

  .labelWrapper {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  :global {
    .react-datepicker__input-container {
      height: 33px;
      width: 200px;

      input {
        height: 31px;
        width: 100%;
        @include type-lg;
        padding: 0.3125rem 0.5625rem;
        background-color: $theme-background-input;
        border: 1px solid $theme-border-input;
        border-radius: 3px;

        ::placeholder {
          color: $theme-text-secondary;
        }
      }
    }
    .react-datepicker-popper {
      z-index: 2;
    }
  }
}


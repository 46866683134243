@import "src/assets/stylesheets/scss/tokens";

.wrapper {
  height: 100%;
  width: 100%;
  background-color: $theme-background-secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;

  .header {
    margin-bottom: 32px;

    span, h1 {
      @include type-h2;
      color: $theme-text-primary;
    }
  }

  .featureFlagContent {
    .contactInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .subheader {
      margin-bottom: 20px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .subheader {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  .content, .featureFlagContent {
    padding: 32px;
    width: 417px;
    background-color: $theme-background-primary;
    border-radius: 8px;
    margin-bottom: 32px;
    @include shadow-sm;

    .subheader {
      span, h2 {
        margin-bottom: 1rem;

        &:first-child {
          @include type-h4;
        }

        &:last-child {
          @include type-lg;
        }
      }
    }

    .things {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      .thing {
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
          @include type-lg;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    span, button {
      @include type-lg;

      &.link {
        @include type-lg-bold;
        color: $theme-accent-primary;
        text-decoration: underline;
        cursor: pointer;
        background: 0px;
        border: 0px;
      }
    }
  }
}

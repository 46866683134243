@import "./src/assets/stylesheets/scss/tokens";
.appointmentTypeRow {
		height: 64px;
		cursor: grab;
		border-bottom: 1px solid $theme-border-primary;
		transform: translate3d(0, 0, 0);
		position: relative;
		z-index: 1;
		td{
				border: none;
				padding: 0.75rem;
				.icon {
						color: $theme-accent-primary;
						opacity: 0
				}
				.contactIcons {
						display: flex;
						align-items: center;
						svg:not(:first-child){
								margin-left: 4px;
						}
				}
				.dropdown{
						i {
								color: $theme-accent-primary;
								opacity: 0;
								font-size: 18px;
						}
				}
				.apptPricing {
						cursor: default;
						display: initial;
				}
		}
		&.isHover{
				background: $theme-background-secondary;
				td{
						.icon{
								opacity: 1;
						}
						.dropdown{
								i{
										opacity: 1;
								}
						}
				}
		}
		.tooltipContent {
				padding-right: 0.75rem;
		}
		:global{
				.healthie-dropdown-triangle {
						left: 67px;
				}
				.dropdown-menu {
						left: -74px;
						.healthie-dropdown-triangle {
								left: 81px !important;
						}
						.healthie-dropdown-content {
								z-index: 1000;
								width: 121px;
						}
				}
		}
}

@import "./src/assets/stylesheets/scss/tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.appointmentTypesContainer {
		@include mobile{
				overflow-x: scroll;
		}
		.table {
				background: $theme-background-primary;
				width: 100%;
				margin-bottom: 50px;
				thead {
						tr{
								border: none;
								height: 3.25rem;
						}
						th {
								@include type-sm-bold;
								text-transform: uppercase;
								color: $theme-text-secondary !important;
								border: none;
								.tooltip {
										max-width: 400px;
										@include type-lg;
										text-transform: none;
								}
								i {
										color: $theme-accent-primary;
								}
								&.colXL{
										width: 32%;
								}
								&.colL {
										width: 16%;
								}
								&.colMD{
										width: 12%;
								}
						}
				}
				.scrollRow {
						height: 150px;
				}
		}
}

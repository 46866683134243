@import 'src/assets/stylesheets/scss/_tokens.scss';

.label {
  height: fit-content;
  margin: 0 0 0.25rem 0;
  text-align: left;
  color: $theme-text-primary;
  @include type-sm;
}
.ageField {
  display: flex;
  flex-direction: column;
}

.ageText {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  margin-top: 0.125rem;
}
.wrapper {
  margin-bottom: 0.75rem !important;
}

.inputWrapper {
  padding: 0.25rem;

  .input {
    @include type-lg;
    border: none;
    background-color: $theme-background-input;
  }
}

.customModuleWrapper {
  :global {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
  .customModuleCustomInput {
    width: 100%;
    justify-content: space-between;

    input {
      border: none;
    }
  }
}

@import '../../../assets/stylesheets/scss/tokens';

.permissionTemplateDropdown {
  .dropdownBtn {
    background-image: none;
    background-color: transparent;
    border: none;

    > span {
      color: $theme-accent-primary;
    }
  }
  .dropdownContent {
    border: none;
    display: flex;
    flex-direction: column;
    .dropdownItem {
      padding: 6px 0;
      @include type-lg-bold;
      text-align: left;

      &:hover {
        background-color: transparent;
        border-color: $theme-accent-primary;
        text-decoration: underline;
      }

      &:hover:after {
        background-color: transparent;
      }
    }
  }
  :global {
    .dropdown-menu {
      background-color: $theme-background-primary;
      left: -270px;
      top: 40px;
      border-radius: 4px;
      @include shadow-sm;
      padding-bottom: 4px;

      .healthie-dropdown-triangle {
        left: 170px;
        top: -4px;

        &::before {
          border: none;
        }
      }
    }
  }
}

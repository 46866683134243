@import "../../../../assets/stylesheets/scss/tokens";

.customizationFormWrapper {
  display: flex;
  padding: 16px 0;

  form {
    width: 100%;
  }

  .styleCustomizations {
    display: flex;
    height: 70px;

    .primaryColor {
      width: 33%;
      display: flex;
      height: 55px;
      flex-direction: column;

      .colorPickerWrapper {
        height: 35px;

        :global(.chrome-picker) {
          margin-left: 30px;
        }
      }
    }
  }
}

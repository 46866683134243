@import 'src/assets/stylesheets/scss/tokens';

.erxSignUpForm {
  .section {
    .header {
      background: $theme-background-secondary;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 1rem;
      gap: 10px;
      height: 48px;

      @include type-sm-bold-uppercase;
      color: $theme-text-secondary;
    }

    .body {
      padding: 20px 0;

      .info {
        @include type-lg;
        color: $theme-text-primary;
      }

      .learnMore {
        color: $theme-accent-primary;
      }

      & label {
        margin: 0 0 3px 0;
      }

      & p[class^="ErrorAdapter_errorText"] {
        position: unset;
        margin-top: auto;
        bottom: unset;
      }

      & div[class^="LabelAdapter_labelTooltip"] {
        margin-bottom: unset;
      }

      .roleNote {
        top: -15px;
        position: relative;
        @include type-sm;
        color: $theme-text-secondary;
      }

      .fields {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .inline {
          display: flex;
          gap: 16px;

          & > div {
            width: 100%;
            height: auto;
            margin-bottom: unset;
          }
        }
      }

      .checkboxes {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 30px 0 10px 0;
      }
    }
  }

  .submitButtom {
    display: flex;
    padding: 0;
    justify-content: space-between;
    gap: 16px;
  }

  .noteSection {
    padding: 30px 0;

    @include type-lg;
    color: $theme-text-secondary;

    & p:last-of-type {
      margin-top: 5px;
    }
  }
}

@import "src/assets/stylesheets/scss/tokens";

.link {
  color: $theme-accent-primary;
  border: none;
  background-color: transparent;
  padding: 8px 20px;
  @include type-lg-bold;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: $theme-text-primary;
    border: none;
    background-color: transparent;
  }
}


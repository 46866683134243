@import "src/assets/stylesheets/scss/_tokens";

.calendarSharingLinkContainer {
  border-radius: 3px;
  padding: 4px;
  border: 1px solid $theme-border-primary;
  display: flex;
  width: 100%;
  align-items: center;

  .calendarSharingLink {
    padding: .75rem .75rem .75rem;
    display: inline;
    overflow: hidden;
    user-select: all;
    border-radius: 3px;
    overflow-x: scroll;
    white-space: nowrap;
    -ms-user-select: all;
    -moz-user-select: all;
    -ms-overflow-style: none;
    -webkit-user-select: all;
    background: $theme-background-primary;
    border: 0.5px solid $theme-border-primary;

    span {
      color: $theme-text-primary;
      @include type-sm;
    }
  }

  .calendarSharingLink::-webkit-scrollbar {
    display: none;
  }

  span#clipboard-button {
    margin-left: 4px;
  }
}

.calendarSharingLinkCopied {
  height: 68px;
  padding: 13px;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  align-items: center;
  background: $theme-notice-bold-success;

  p {
    @include type-lg;
    color: $theme-white;
  }
}


@import "../../../assets/stylesheets/scss/tokens";
.formHeaderContainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 66px;
		padding-left: 25px;
		padding-right: 30px;
		background-color: $theme-background-primary;
		position: sticky;
		top: 0;
		z-index: 9;
		
		.formName {
				justify-self: center;
				color: $theme-text-primary;
				@include type-h4;
				@media screen and (max-width: 768px) {
						margin-left: 0;
						font-size: 14px;
				}
		}
}

@import "./src/assets/stylesheets/scss/tokens";
@import "./src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.asideModalBackground {
		left:0;
		top:0;
		right:0;
		bottom:0;
		background-color: rgba(0,0,0,0.2);
		height: 100vh;
		width: 100%;
		position: fixed;
		display: flex;
		opacity: 0;
		z-index: -40;

		@supports (height: 100dvh) { // Check if the browser supports 100dvh, to fix iOS bottom bar
			height: 100dvh;
		}

		.asideModalContentWrapper {
				margin: 0 0 0 auto;
				position:relative;
				max-height: none;
				height: 100%;
				background: $theme-background-primary;
				padding: 1.25rem;
				overflow-y: auto;
				@include shadow-lg;
				.asideModalContent {
						position:relative;
						height: 100%;
				}

				&.widePadding {
						padding: 1.25rem 1.5rem;
				}
      
				:global('.react-datepicker-popper')  {
						z-index: 50;
				}
		}
		&.openModal {
				opacity: 1;
				z-index: 40;
				@include mobile {
					z-index: 120;
				}
				.asideModalContentWrapper{
						&.sm{
								animation-name: smOpen;
						}
						&.md {
								animation-name: mdOpen;
						}
						&.lg {
								animation-name: lgOpen;
						}
						animation-duration: 0.2s;
						animation-timing-function: ease;
						animation-fill-mode: forwards
				}
		}
		&.closeModal {
				animation-name: closeWrapperAnimation;
				animation-duration: 0.2s;
				animation-timing-function: ease;
				.asideModalContentWrapper{
						&.sm{
								animation-name: smClose;
						}
						&.md {
								animation-name: mdClose;
						}
						&.lg {
								animation-name: lgClose;
						}
						animation-duration: 0.3s;
						animation-timing-function: ease;
				}
		}
}

//Opening Animations
@keyframes smOpen {
		0% {
				width: 0;
				transform-origin: right;
		}
		100% {
				width: 418px;
		}
}

@keyframes mdOpen {
		0% {
				width: 0;
				transform-origin: right;
		}
		100% {
				width: 500px;
		}
}

@keyframes lgOpen {
		0% {
				width: 0;
				transform-origin: right;
		}
		100% {
				width: 588px;
		}
}

//Closing Animations
@keyframes smClose {
		0% {
				width: 418px;
		}

		100% {
				width: 0;
		}
}
@keyframes mdClose {
		0% {
				width:500px;
		}

		100% {
				width: 0;
		}
}
@keyframes lgClose {
		0% {
				width: 588px;
		}

		100% {
				width: 0;
		}
}

@keyframes closeWrapperAnimation {
		0% {
				opacity: 1;
				z-index: 40;
		}

		90% {
		z-index: 40;
		}

		100% {
				opacity: 0;
				z-index: -40;
		}
}


@import "../../../../assets/stylesheets/scss/tokens";
.pulseLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .animatedPulseLoader {
    position: relative;
    display: flex;
    .pulseCircle {
      border-radius: 50%;
      background: $theme-background-secondary;
      animation: pulse 1800ms ease-in-out infinite;
      animation-duration: 1.42s;
    }
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

  }
  .pulseLoaderText {
    margin-top: 30px;

    span {
      color: $theme-accent-primary;
      @include type-lg-bold;
      margin-top: 35px;
    }
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.422, 1.422, 1.422);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@import "assets/stylesheets/scss/tokens";
.inputContainer {
		box-sizing: border-box;
		clear: both;
		position: relative;
		text-align: left;
}

.input {
		@include type-lg;
		min-height: 30px;
		border-radius: 3px;
		background-color: $theme-background-input;
		border: 1px solid $theme-border-input;
		box-shadow: none;
		color: $theme-text-primary;
		box-sizing: border-box;
		width: 100%;
		height: 2.25em;
		justify-content: flex-start;
		padding: calc(0.375em - 1px) calc(0.625em - 1px);
		
		&::placeholder {
				color: $theme-text-secondary;
		}
		&:focus, &:active{
				border-color: $theme-accent-primary;
		}
}


.input[readonly] {
	background: $theme-background-secondary;
}
@import './src/assets/stylesheets/scss/tokens';

.content {
  display: flex;
  flex-direction: column;

  .form {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid $theme-border-primary;

    .fields {
      display: flex;
      flex-direction: column;

      .header {
        padding: 0 21px;
        border-bottom: 1px solid $theme-border-primary;

        span {
          @include type-sm;
          color: $theme-text-secondary;
          text-transform: uppercase;

          &:first-child {
            display: inline-block;
            width: 70px;
          }
        }
      }

      .body {
        padding-top: 10px;
        overflow: scroll;
        height: 280px;

        .row {
          display: flex;
          margin-bottom: 0.3rem;
          width: 100%;
          .checkboxLabel {
            display: flex;
            align-items: center;
            
            span {
              @include type-lg;
              max-width: 450px;
              &.code{
                @include type-lg-bold;
                width: 70px;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    > div {
      margin-right: 10px;
    }

    > div, > div > div {
      display: flex;
      min-height: 34.5px;
      height: 34.5px;
      margin-bottom: unset;
    }
  }
}

@import 'src/assets/stylesheets/scss/tokens';

.text {
  font-style: normal;
  font-stretch: normal;
  color: $theme-text-primary;
  @include type-lg;
}

.description {
  @extend .text;
  margin: 15px 0;
}

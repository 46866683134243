@import "src/assets/stylesheets/scss/tokens";

.previewTextAreaContainer {
    padding: 0.5rem;
    @include type-lg;
    min-height: 80px;
    border-radius: 0.25rem;
    background-color: $theme-background-input;
    border: 1px solid $theme-border-input;
    box-shadow: none;
    color: $theme-text-primary;
    box-sizing: border-box;
  
    &:hover,
    &:focus,
    &:active {
      background-color: $theme-background-primary;
      border: 1px solid $theme-accent-primary;
    }
  
   :global {
    // Styles needed to make sure tinymce displays correctly without relying on global scss classes.
    // Scoped to this form builder component only.
      ul {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
      }
    
      ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
      }
    
      li {
        display: list-item;
      }
    
      p {
        strong {
          color: inherit;
        }
      }
    }
}
@import "assets/stylesheets/scss/tokens";
.inputContainer {
		text-align: center;
		width: 100%;
		margin: 0 auto;
		.input {
				padding: 0;
				color: $theme-accent-primary;
				@include type-h1;
				text-align: center;
				justify-content: center;
				border: none !important;
				border-bottom: 1px solid $theme-border-primary !important;
				width: 50%;
				margin: 0 auto;
		}
}

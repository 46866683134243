@import "src/assets/stylesheets/scss/tokens";

.createConversationAction {
  display: flex;
  width: 100%;
  padding: 11px 11px 20px 11px;

  >button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    svg {
      margin-right: 8px;
    }

    &:hover, &:active, &:focus {
      svg {
        path {
          fill: $theme-white;
        }
      }
    }
  }
}

@import "src/assets/stylesheets/scss/tokens";

.orgChatWarnModal {
  section:first-of-type {
    border-radius: 0px;
    padding: 0 27px 30px 27px;
  }

  header {
    padding-top: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 20px;
  }
}

.messageBox {
  display: flex;
  margin: 30px 0 10px 0;

  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: 10px;

    >span {
      @include type-sm;
    }
    >span:last-of-type {
      color: $theme-text-secondary;
      margin-bottom: 8px;
    }
  }
}

.noteBox {
  display: block;
  position: relative;
  @include type-lg;
  word-break: break-word;
  padding: 8px 14px;
  margin-left: 8px;
  color: $theme-text-primary;
  background-color: $theme-background-secondary;
  border-radius: 0 15px 15px;

  &:before {
    content: "";
    border-style: solid;
    border-width: 8px 8px 0px 8px;
    border-color: $theme-background-secondary transparent transparent transparent;
    position: absolute;
    left: -8px;
    top: 0;
  }

  u {
    cursor: default;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: initial;
  }
}


@import 'src/assets/stylesheets/scss/tokens';

.selectClientModal {

  >section {
    height: 100%;
  }

  .errorNotification {
    margin-top: 1em;
  }
}
@import "assets/stylesheets/scss/tokens";
.colorPickerWrapper.withSample {
  position: relative;

  .colorPickerSample {
    position: absolute;

    border-radius: 5px;
    height: 23px;
    width: 23px;
    top: 6px;
    left: 6px;
    z-index: 1;
  }

  :global .chrome-picker {
    left: -40px;
    top: 45px;
  }

  input.colorPickerInput {
    padding-left: 36px;
    height: 35px;
  }
}

.label {
	@include type-sm-bold;
  display:inline-block;
  text-align: left;
  color: $theme-text-primary;
	&:not(:last-child){
		margin: 8px 0 5px 0;
		@media screen and (max-width: 768px) {
			margin: 15px 0 8px 0;
		}
	}
}

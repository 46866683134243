.appointmentTypeSelectWrapper {
  label {
    margin-bottom: 5px;
  }

  div[class*='menu-list'] {
    max-height: 135px;
  }
}

.actions {
  display: flex;
  margin-top: 150px;
  justify-content: space-between;
}

@import "src/assets/stylesheets/scss/tokens";
@import "src/assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins";

.requestedBillingInfo {
  .billingDisclaimer {
    min-width: 400px;
    max-width: 600px;

    @include mobile {
      min-width: unset;
      width: 100%;
    }
  }

  h4 {
    @include type-h4;
    margin: 1.25rem 0 0 0;
    font-style: normal;
    font-stretch: normal;
    color: $theme-text-primary;
    line-height: normal;
  }

  :global {
    .row:first-child {
      margin-right: 0px;
      margin-left: 0px;
    }

    .btn-pt-30 {
      padding-top: 1.875rem;
    }

    .row {
      .col.card-label-type {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .billing-info-form {
      .Select-control {
        height: 2.195rem;
      }

      .label-container {
        margin: 0.75rem 0 0;

        .icon {
          margin: -0.125rem 0 0.125rem 0;
        }
      }

      #card-element {
        height: 2.195rem;
      }

      .stripe-container{
        .field {
          .danger-text {
            position: absolute;
          }
        }
      }

      @include mobile {
        .billing-info-form {
          width: 100%;
        }

        .col-4 {
          width: 150px;
          margin: 0 auto;
          padding-left: 0;
        }

        .btn-pt-30 {
          padding-top: 0;
        }

        .stripe-container {
          width: 330px;
        }

        #card-element {
          width: 100%;
        }
      }
    }
  }

  @include only-for-ie{
    div:last-child div {
      z-index: 14
    }
  }
}

@import "../../../assets/stylesheets/scss/tokens";

.bannerWrapContentContainer {
  &.bannerInlineContainer, &.bannerContainer {
    display: inline-flex;
    width: auto;

    .actionContainer {
      display:flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;
      padding: 0.75rem 1rem;
      gap: 0.5rem;
      padding-top: 0;
      :first-child, :last-child {
        margin: 0;
      }
    }
  }
  .bannerContentWrapper {
    display: flex;
    flex-direction: column;

    .bannerContent {
      width: 100%;
      margin: 0;
    }
  }
  .iconContainer svg {
    align-self: flex-start;

  }

}

.bannerInlineContainer, .bannerContainer{
  background-color: $theme-background-primary;
  color: $theme-text-primary;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  @include shadow-sm;
  .iconContainer {
    box-sizing: inherit;
    min-width: 34px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $theme-white;
    padding: 12px 0;
    font-size: 16px;
    align-self: normal;
  }
  .bannerContentWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }
  .bannerContent {
    display: inline-block;
    width: calc(100% - 34px);
    padding: 12px 15px;
    @include type-lg;
    @media screen and (max-width: 450px) {
      padding: 0.75rem 0;
    }
    a {
      text-decoration: underline;
    }
  }
  .actionContainer{
    justify-content: flex-end;
    padding-right: 15px;
    display: flex;
    :first-child{
      margin-right: 10px;
    }
    :last-child{
      margin-right: 0;
    }
    @media screen and (max-width: 450px) {
      padding: 0 1rem 1rem 0;
      align-self: flex-end;
    }
  }

  &.success{
    .iconContainer{
      background: $theme-notice-bold-success;
    }
  }
  &.alert{
    .iconContainer{
      background: $theme-notice-bold-alert;
    }
    i {
      color: $theme-white;
    }
  }
  &.warning, &.error{
    .iconContainer{
      background: $theme-notice-bold-warning;
    }
  }
  &.info, &.specific{
    .iconContainer{
      background: $theme-accent-primary;
    }
  }
}

.bannerContainer{
  position: absolute;
  top: 0;
  border-radius: 0;
  z-index: 15;
  left: 0;
  .bannerContentWrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }
}
.bannerInlineContainer{
  border-radius: 8px;
  .iconContainer{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.bannerStickyContainer {
  position: sticky;
}

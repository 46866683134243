@import 'src/assets/stylesheets/scss/tokens';

.header {
		@include type-h4;
		margin-bottom: 30px;
		text-align: center;
}

.text {
		margin-bottom: 20px;
		text-align: center;
}

.button {
		text-align: center;
}

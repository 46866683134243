@import "./src/assets/stylesheets/scss/tokens";

.dayPickerInput{
  display: inline-block;
  width: 174px;
  border-radius: 3px;
  border: 1px solid $theme-border-input;
  padding: REM(4px) REM(8px);
  cursor: pointer;
  height: 30px;
  color: $theme-text-primary;
}

@import 'src/assets/stylesheets/scss/_tokens.scss';
@mixin dropdown-menu-styles {
  i.fas.fa-ellipsis-h {
    color: $theme-accent-primary;
    font-size: 18px;
  }

  :global(.dropdown) {
    .dropdown-menu {
      left: -10em;
      z-index: 600;

      .healthie-dropdown-triangle {
        left: 157px;
      }
    }

    hr {
      height: 1px;
      margin: 0px 20px;
      border-bottom: 1px solid $theme-border-primary;
    }

    .dropdown-item {
      color: $theme-accent-primary;
      @include type-lg-bold;
      padding-left: 20px;
      padding-right: 15px;

      &.danger-color {
        color: $theme-notice-bold-warning;
      }
    }
  }
}

.carePlansWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;

    .title {
      span {
        color: $theme-text-primary;
        @include type-h3;
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      :global(.dropdown-menu) {
        top: 45px;

        .healthie-dropdown-triangle {
          left: 44px;
          color: $theme-accent-primary;
          @include type-sm-bold;
        }
      }

      .hide-dropdown-button {
        margin-right: 0;

        .template-button {
          padding-left: 32px;
        }
      }

      button {
        position: relative;
        padding-left: 25px;
        margin-right: 20px;

        svg {
          position: absolute;
          left: 7px;
          top: 10px;
        }
      }
    }
  }

  // The below styles are used in ListCarePlans.tsx, a child of AllCarePlans
  :global {
    .care-plans-table {
      thead tr {
        border-bottom: 1px solid $theme-background-primary;

        th {
          padding-bottom: 15px;

          svg {
            color: $theme-text-secondary;
          }

          &[class*='isActive'] svg {
            color: $theme-accent-primary;
          }

          &.status-col,
          &.actions-col {
            width: 5%;
          }

          &.name-col,
          &.created-col {
            width: 45%;
          }

          &.careplan-col {
            width: 25%;
          }
        }
      }

      tbody {
        .care-plan-row {
          border-bottom: 1px solid $theme-border-primary;

          .status-cell {
            text-align: left;
          }

          .name-cell {
            position: relative;
            color: $theme-text-primary;
            @include type-lg-bold;

            svg {
              margin-right: 10px;
              margin-bottom: -3px;
            }
          }

          .actions-cell {
            @include dropdown-menu-styles;
            .dropdown-item {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.emptyStateWrapper {
  display: flex;
  justify-content: center;

  .emptyStateContainer {
    width: 676px;
    margin-top: 130px;
    display: flex;

    &.withPulseLoader {
      width: 100px;

      .homeLoaderContainer {
        margin-top: 9rem;
      }
    }

    .iconContainer {
      margin-right: 40px;
      margin-bottom: 30px;
    }

    .emptyStateTextContainer {
      display: flex;
      flex-direction: column;

      .title {
        span {
          color: $theme-text-primary;
          @include type-h3;
        }
      }

      .middleText {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 20px 0;

        div {
          span {
            color: $theme-text-secondary;
            @include type-lg;
          }

          &:last-child {
            margin-top: 20px;
          }
        }
      }
    }

    .buttonContainer {

      >a {
        margin-bottom: 10px;
      }

      :global .hide-dropdown-button {
        padding: unset;

        :global .template-button {
          width: 287px;

          &:hover,
          &:active {
            svg g g {
              fill: $theme-background-hover;
            }
          }

          svg {
            margin-bottom: -1px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

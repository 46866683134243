.pronounsFieldBox {
  display: flex;
  width: 100%;
  padding: 0.75rem;

  >div {
    width: 100%;
    max-width: 320px;
    min-width: 100px;
  }

  >div:first-of-type {
    display: flex;
    flex-direction: column;
  }

  >div:last-of-type:not(:only-child) {
    margin-left: 25px;
    align-self: end;
  }

  &.formBuilder {
    padding: 0 0 10px 0;
    text-align: left;
  }

  .customFieldBox {
    position: relative;
    div:last-child:not(:only-child) {
      position: absolute;
    }
  }
}

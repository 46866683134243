@import "src/assets/stylesheets/scss/tokens";

.cellWrapper {
  align-items: center;
  column-gap: REM(10);
  display: flex;

  .invoiceTypeTitle {
    @include type-lg;
  }

  .invoiceTypeToolTipTitle {
    @include type-sm-bold;
    color: $theme-background-primary;
  }

  .invoiceTypeToolTipServiceDate {
    @include type-sm;
  }
}
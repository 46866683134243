@import "../../../assets/stylesheets/scss/tokens";
.backBlock {
		display: flex;
		width: 46px;
		height: 100%;
		margin-right: 1.5rem;
		justify-content: center;
		align-items: center;
		border-right: 1px solid $theme-border-primary;
		.btn {
				background: transparent;
				border: none;
				color: $theme-accent-primary;
				width: 100%;
				height: 100%;
				&:hover, &:active, &:focus{
						cursor: pointer;
				}
				svg path {
					fill: $theme-accent-primary;
				}
			}
}
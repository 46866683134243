@import "src/assets/stylesheets/scss/tokens";

.icdPointerLabel {
    display: flex;
    
    .icdPointerIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      width: 20px;
      border-radius: 5px 5px 5px 0px;
      background-color: $theme-background-secondary;
      margin-right: 0.43rem;
      
      span {
        @include type-sm-bold;
        color: $theme-text-primary;
      }
    }
  }
  
  .icdPointerTooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@import '../../../../assets//stylesheets/scss/tokens';



.availabilityAccordion {
  > div > div[role="button"] {
    padding: 0;
    border-bottom: 1px solid $theme-border-primary;
    & + div {
      padding: 1rem 0rem;
    }
  }
  .noAvailabilityBodyText {
    margin-top: 0.5rem;
    span {
      color: $theme-accent-primary;
      cursor: pointer;
    }
  }
 }

.timePillsContainer {
    flex-wrap: wrap;
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.5rem;
    overflow-y: auto;
    max-height: 10rem;
    padding-top: 0.2rem;
}



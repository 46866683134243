@import "./src/assets/stylesheets/scss/tokens";

.container {
		width: 100%;
		input[type='range'] {
				overflow: hidden;
				-webkit-appearance: none;
				background-color: transparent;
		}
		
		/** Firefox */
		input[type="range"]::-moz-range-progress {
				background-color: $theme-background-secondary;
				height: 60px;
		}
		
		/* IE*/
		input[type="range"]::-ms-fill-lower {
				background-color: $theme-background-secondary;
				height: 60px;
		}
		.field {
				margin-bottom: 0.75rem;
				box-sizing: border-box;
				clear: both;
				font-size: 1rem;
				position: relative;
				text-align: left;
				
				.customRange {
						-webkit-appearance: none;
						width: 100%;
						height: 60px;
						border-radius: 30px;
						background: transparent;
						border: 1px solid $theme-border-primary;
						outline: none;
						-webkit-transition: .2s;
						transition: opacity .2s;
						
						&:hover {
								opacity: 1;
						}
						
						&::-webkit-slider-thumb {
								-webkit-appearance: none;
								appearance: none;
								width: 60px;
								height: 60px;
								background-color: $theme-accent-primary;
								background-image: url(../../../../assets/images/white_left_right_arrows.svg);
								background-repeat: no-repeat;
								background-position: center;
								cursor: pointer;
								border-radius: 50%;
						}
						
						&::-moz-range-thumb {
								width: 60px;
								height: 60px;
								background: $theme-accent-primary;
								background-image: url(../../../../assets/images/white_left_right_arrows.svg);
								background-repeat: no-repeat;
								background-position: center;
								cursor: pointer;
								border-radius: 50%;
						}
				}
		}
}

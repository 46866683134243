@import 'src/assets/stylesheets/scss/tokens';

.labOrderContent {
  padding: 0 40px;
  .noResults {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    padding: 30px;
    
    h3 {
      @include type-h3;
    }
  }

  .labOrderSubHeader {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid $theme-border-primary;
    margin-bottom: 20px;
  
    h6 {
      @include type-sm-bold-uppercase;
    }
  }

  .waitingOnResults {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    padding: 30px;

    h4 {
      @include type-h4;
    }
  }

  .labOrderContentTable {
    margin-bottom: 20px;
    width: 100%;
  
    td {
      width: 30%;
      padding: 0.6em;
      &:first-child {
        padding: 0.8em;
      }
    }
  
    th {
      color: $theme-text-primary;
      @include type-sm-bold;
    }
  
    tbody tr td:first-child, thead tr th:first-child, .client-cms-row {
      padding-left: 0
    }
    thead td,  thead th{
      border-width: 0 0 0;
    }

    tr * {
      vertical-align: middle;
    }

    .flagContainer {
      margin-left: 7px;
      vertical-align: baseline;
      color:$theme-notice-bold-warning;
      .abnormalMarker {
        display: inline-block;
        vertical-align: baseline;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background: $theme-notice-bold-warning;
      }
    }

  }
}

@import "../../../assets/stylesheets/scss/tokens";

.activityBottomMargin {
   margin-bottom: 1.25rem;
}

.formActivityBarContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem;
  gap: 1.875rem;
  margin-bottom: 0.625rem;
 
  .formActivityItem {
    @include type-sm-bold-uppercase;
    display: flex;
    align-items: center;
    padding: 1rem 1.25rem;
    gap: 1.25rem;
    min-width: 9rem;
    border: none;
    border-radius: 6.25rem;
    cursor: pointer;
    background-color: $theme-background-secondary;
    color: $theme-text-primary;

    &.locked {
      background-color: $theme-notice-soft-success;
    }
  }

  &.hidden {
    display: none
  }
}

.summaryBox {
  white-space: pre-wrap;
  border-radius: 0.5rem;
  background: $theme-background-secondary;
  padding: 0.5rem 0.5rem 1rem 1rem;
}

@import 'src/assets/stylesheets/scss/_tokens';

.enabled {
  @include type-lg-bold;
  color: $theme-notice-bold-success;
}

.disabled {
  @include type-lg-bold;
  color: $theme-text-secondary;
}

@import "src/assets/stylesheets/scss/tokens";

.organizationMembershipFormContainer {
  :global {
    .member-user-fields{
      margin-bottom: 30px;
    }
    .main-header{
      margin-bottom: 20px;
    }
    .fields-header{
      margin-bottom: 15px;
      margin-top: 25px;
    }
    .inline-label{
      font-weight: 400;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .checkbox-inputs-container .checkbox{
      display: block;
      width: fit-content;
    }
    .desktop-radio-button-container .form-control{
      width: fit-content;
    }
    .cc-tooltip.extra-width{
      max-width: 600px!important;
    }
    a {
      margin: auto 0px;
    }
  }
}

.permissionTemplateApplyingInfo {
  text-align: right;
  margin-bottom: 12px;
  @include type-sm;
}

.organizationMembershipForm {
  border: 1px solid $theme-border-primary;
  border-radius: 8px;
  padding-bottom: 20px;

  :global .checkbox-inputs-container {
    .label-title {
      color: $theme-text-primary;
      @include type-lg;
      font-stretch: normal;
      text-align: left;
    }
  }

  .sectionContent {
    display: flex;
    flex-direction: column;
    padding: 15px 20px 20px 20px;

    :global {
      span.tooltip {
        margin-left: 6px;
        cursor: pointer;
        z-index: 1;
      }

    }

    &.general {
      :global {
        i.far.fa-question-circle {
          font-size: 14px;
          color: $theme-accent-primary;
        }

        .radio-button-group > div {
          .form-control:not(:first-of-type) {
            margin-left: 36px;
          }
        }

        .field {
          width: 100%;
          max-width: 324px;

          label.label {
            display: none;
          }

          .Select {
            height: 36px;
          }

          .Select.is-focused:not(.is-open) {
            .Select-control {
              background-color: $theme-background-input !important;
              border-color: $theme-border-input !important
            }
          }

          .Select.is-open {
            .Select-control {
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }

          .Select-control {
            height: 36px;
            border: 1px solid $theme-border-input;
            border-radius: 4px;
            background-color: $theme-background-input;

            .Select-arrow-zone {
              >svg {
                pointer-events: none;
              }
            }
          }
        }
      }
    }

    .dosespotErxRole {
      width: 400px;
      margin-left: 20px;
    }
  }

  .fieldRow {
    &:not(:first-of-type) {
      margin-top: 24px;
    }

    &__labelBox {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }
  }

  .horizontalRadio {
    display: flex;
    justify-content: space-between;
    max-width: 100px;
  }

  .label {
    @include type-lg-bold;
    display: inline-block;

    &__field {
      @extend .label;
      font-weight: 600;
      font-size: 13px;
      line-height: 14px;
    }

    &__section {
      @extend .label;
      font-weight: 600;
    }

    &__light {
      @extend .label;
      font-size: 16px;
    }
  }
}

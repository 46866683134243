@import "src/assets/stylesheets/scss/tokens";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0.5rem 1.5rem 1.5rem;
  border-bottom: 1px solid $theme-border-primary;

  .title {
    @include type-h4;
  }
  .headerBlock {
    display: flex;
    align-items: center;
  }
}
@import '../BenefitItem/BenefitItem.module.scss';

.item {
  @extend .item;

  [class='padding-left col'] {
    margin: 0 !important;
  }

  .deductibleItems {
    display: grid;
    row-gap: 0.313rem;
  }

  .deductibleItem {
    margin-bottom: 0.313rem;

    &:not(:last-of-type) {
      border-bottom: 1px solid $theme-border-primary;
    }
  }
}

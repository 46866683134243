@import 'src/assets/stylesheets/scss/tokens';

.modalCopyWrapper {
  padding-bottom: REM(28);

  .modalCopy {
    @include type-lg;
    text-align: initial;
  }
}

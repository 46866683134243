@import "assets/stylesheets/scss/tokens";
.container{
		box-sizing: border-box;
		clear: both;
		.labelContainer{
				display: flex;
				margin-bottom: 10px;
				align-items: center;
		}
		.label{
				@include type-sm;
				text-align: left;
				color: $theme-text-primary;
		}
		.tooltip {
				display: flex;
				align-items: center;
				padding-bottom: .5rem;
				margin: .2rem;
				.react-tooltip {
						width: 280px;
				}
				i {
						margin-left: 10px;
						color: $theme-text-primary;
				}
		}
		.counterContainer {
				max-height: 15px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 10px;
				p{
						margin-top: 0;
				}
				.counter{
						color: $theme-text-secondary;
						@include type-sm;
						text-align: right;
						margin: 0 0 0 auto;
						width: 60%;
				}
		}
}

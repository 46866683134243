@import "./src/assets/stylesheets/scss/tokens";

.avatar {
  position: relative;
  svg {
    height: 14px;
    width: 14px;
    position: absolute;
    right: -3px;
    bottom: -3px;
    path {
      fill: $theme-accent-primary;
    }
  }
}

.button {
  a {
    // !important is needed to overwrite color defined in .archive-clients-form .client-name-row a
    color: $theme-accent-primary !important;
    height: auto;
    max-height: none;
    padding: 0px;
    margin-right: 10px;
  }
}

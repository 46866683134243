@import 'src/assets/stylesheets/scss/_tokens.scss';

.addSubOrg {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  padding-right: 30px;
}

.addSubOrgDrawer {
  :global {
    .aside-tab-content__header {
      padding: 15px 0;
      margin: 0 20px;
      background-color: $theme-background-primary;
      border-bottom: 1px solid $theme-border-primary;

      .header-text {
        height: auto;
        color: $theme-text-primary;
      }
    }
  }
}

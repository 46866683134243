@import "assets/stylesheets/scss/tokens";

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  :global {
    .react-datepicker-popper {
      z-index: 2;
    }

    .react-datepicker__input-container {
      height: 35px;
      width: 200px;

      input {
        height: 35px;
        width: 100%;
        @include type-lg;
        padding: 0.3125rem 0.5625rem;

        ::placeholder {
          color: $theme-text-secondary;
        }
      }
    }

    // input with icon
    svg + .react-datepicker-wrapper > .react-datepicker__input-container > input {
      padding-left: 32px;
    }
  }

  .labelWrapper {
    display: flex;
    align-items: center;
    gap: 0.25rem;

    // align tooltip icon with label
    label + svg {
      margin-bottom: 0.25rem;
    }
  }

  input[disabled] {
    background-color: $theme-background-disabled;
    border: 1px solid $theme-border-input;
    border-radius: 3px;
    box-shadow: none;
    color: $theme-text-secondary;

    + button {
      display: none;
    }
  }

  // icon in input
  > svg {
    pointer-events: none;
    position: absolute;
    left: 11px;
    top: 30px;
    z-index: 1;
  }
}

.fullWidth {
  :global {
    .react-datepicker__input-container {
      width: 100%;
    }
  }
}

@import '../../../assets/stylesheets/scss/utilities/MediaQueries/mediaQueryMixins';
@import '../../../assets/stylesheets/scss/tokens';

    .recurringBlockConfirmModal {
      z-index: 1200;
      @include only-for-safari{
        left: 44.5%!important;
      }
    }
  

@import "src/assets/stylesheets/scss/tokens";

.completeRequestedForm {
  background-color: $theme-background-secondary;
  min-height: 100%;

  :global {
    .Select-menu-outer {
      z-index: 2;
    }

    ul {
      list-style-type: disc;
      margin-left: 0.938rem;
    }

    ol {
      margin-left: 0.938rem;
    }

    strong {
      color: unset;
    }

    table {
      th {
        min-width: 110px;
      }

      td {
        padding-right: 0.625rem;
      }

      .x-button {
        color: $theme-text-secondary;
        border: none;
        background: transparent;
        margin: 0.625rem 0;
        cursor: pointer;
      }

      input[type="radio" i] {
        appearance: radio;
      }
    }

    .danger-color .checkmark {
      border-color: $theme-notice-bold-warning;
    }

    .input.danger-color,
    .location-field.danger-color input[type=text],
    .location-field.danger-color .Select-control,
    .no-selected-value.danger-color .Select-control,
    #card-element.danger-color .stripe-style,
    .signature-input.signature-button-container.danger-color canvas,
    .virtual-select-with-search-icon.danger-color .Select-control,
    .mce-wrapper.danger-color .mce-tinymce.mce-container.mce-panel {
      border: 2px solid $theme-notice-bold-warning !important;
    }
  }
}

.emptyStatePending {
  padding: 10px;

  .content {
    & p:first-of-type {
      margin-top: 4px;
    }

    & p:last-of-type {
      margin-top: 14px;
    }
  }
}
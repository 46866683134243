@import "../../../assets/stylesheets/scss/tokens";
.tasksForm {
  margin-bottom: 2.5rem;
  padding: .3rem .75rem;

  :global
  .react-datepicker-wrapper {
    width: 100%;
    input {
      width: 100%;
    }
  }
}

.taskIcon {
  margin-top: 1rem;
}

.noPadding {
  padding: 0 !important;
}
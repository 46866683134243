@import "src/assets/stylesheets/scss/_tokens";

.displayUserInfoContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3px;

  .displayUserInfoIcon{
    padding-top: 3px;
    margin-right: 5px
  }

  .displayUserInfoKey{
    @include type-sm-bold;
    color: $theme-text-primary
  }

  .displayUserInfoText {
    @include type-sm-bold;
    color: $theme-accent-primary;
    width: 85%;
    word-wrap: break-word;
    margin-left: 8px;
  }

}
@import "../../../assets/stylesheets/scss/tokens";
.progressBarWrapper {
  width: 100%;
  margin-bottom: 28px;
  &:first-child{
    color: $theme-text-primary;
    @include type-lg-bold;
  }
}

.progressBarWrapper.minimalVersion{
  .barMainContainer{
    background: $theme-background-primary;
    border-radius: 2px;
    height: 10px;
    border: unset;
  }

  .progressionBlock{
    background: $theme-accent-primary;
  }
  .progressionBlock.finished{
    background: $theme-notice-bold-success;
  }
  .textContainer{
    display: none;
  }
  .minimalSubtitle{
    @include type-sm;
    i {
      display: none;
    }
  }
  .completed{
    color: $theme-notice-bold-success;
    i{
      display: inline-block;
      margin-right: 5px;
      color: $theme-notice-bold-success;
    }
  }

  .inProgress{
    color: $theme-accent-primary;
  }

  .notStarted{
    color: $theme-text-secondary;
  }
}

.restrictedBar{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $theme-border-primary;
  background: $theme-background-secondary;
}
.progressionBlock{
  display: flex;
  justify-content: center;
  align-items: center;
  background: $theme-notice-bold-success;
}
.noProgressBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: $theme-background-secondary;
}


.textContainer{
  max-height: 19px;
  overflow: hidden;
  &.mainTextStyle{
    color: $theme-text-secondary;
    @include type-lg;
    text-align: center;
    &.textCompletion{
      color: $theme-white;
    }
    
  }
  .textAdditional{
    white-space: nowrap;
  }
}


.barMainContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13px;
  // abstract
  background: $theme-background-secondary;
  border-radius: 1px;
  border: $theme-notice-bold-success;
  height: 30px;
  width: 100%;
}

@import "src/assets/stylesheets/scss/tokens";

.expandableSection {
  :global {
    .cp-sidebr-section-header {
      border-bottom: 1px solid $theme-border-primary;

      &.flex-level {
        align-items: center;
        justify-content: space-between;
      }

      &.flex-level.flex-mobile {
        display: flex;
        padding: 8px 0 5px;

        .left-flex-item {
          display: flex;

          .medium-subtitle {
            color: $theme-accent-primary;
            @includetype-lg-bold;
            font-size: 18px !important;

            transition: all 250ms ease;
          }
        }

        .right-flex-item {
          align-items: center;
          justify-content: flex-end;

          svg {
            g {
              g {
                fill: $theme-text-secondary;
                transition: all 250ms ease;
              }
            }
          }
        }

        &:not(.is-static) {
          cursor: pointer;
          transition: all .25s ease;
        }
      }
    }

    .cp-sidebr-section-header:hover {
      *[class*='left-flex-item medium-subtitle'] {
        color: $theme-text-primary;
      }
    }
  }

  &WithTitleBackground {
    margin-top: 19px;

    :global {
      .cp-sidebr-section-header {
        background: $theme-background-secondary;
        border-bottom: none !important;
        margin-bottom: 11px !important;

        &.flex-level.flex-mobile {
          min-height: 46px;
          padding: 0 10px !important;

          .left-flex-item {
            display: flex;

            .medium-subtitle {
              text-transform: uppercase;
              color: $theme-text-primary;
              @include type-sm-bold-uppercase;
              font-size: 18px;

              transition: all 250ms ease;
            }
          }

          .right-flex-item {
            align-items: center;
            justify-content: flex-end;

            svg {
              g {
                g {
                  fill: $theme-text-secondary;
                  transition: all 250ms ease;
                }
              }
            }
          }
        }
      }
    }
  }
}

.verticalFields {
  display: flex;
  justify-content: space-between;
  align-items: center;

  div[class*='field'] {
    width: 100%;
    margin-bottom: 15px;
  }

  &.pair {
    div[class*='field']{
      max-width: 260px;

      .input[type="text"][disabled] {
        &:hover {
          background-color: $theme-background-secondary;
          border-color: $theme-border-primary;
          color: $theme-text-secondary;
        }
      }
    }
  }

  &.triple {
    & > div {
      padding: 0 5px;

      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }
}

.checkboxes {
  margin-top: 22px;

  &.row {
    display: flex;
    flex-direction: column;

    .checkboxNote {
      padding-left: 18px;
      margin-top: -11px;
      @include type-sm;
    }
  }
}

.withAdditionSpacing {
  margin-bottom: 12px;
}

.accessControl {
  margin-top: 10px;

  .accessControlHeader {
    border-bottom: 1px solid $theme-border-primary;
    height: 35px;

    .allowAccess {
      color: $theme-text-primary;
      @include type-lg-bold;
    }

    .selectAll {
      cursor: pointer;
      color: $theme-accent-primary;
      @include type-lg-bold;
    }
  }

  .checkboxList {
    margin-top: 15px;
  }

  .emailNote { @include type-sm; }
}



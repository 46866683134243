@import 'src/assets/stylesheets/scss/tokens';

.erxWrapper {
  .contentWidget {
    text-align: center;
    padding: 0 24px;
    margin: 30px 0;
  }

  .largeSubtitle{
    @include type-h4;
    color: $theme-text-primary;
    line-height: normal;
  }

  .sectionStateButtons {
    margin-top: 17px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 61px;
    padding-top: 25px;
    border-top: 'none';

    & div:first-child {
      display: inline-block;
    }
  }
}

@import "./src/assets/stylesheets/scss/tokens";

.appointmentSettingsContainer {
		flex-basis: 75%;
		max-width: 75%;
		padding: 0.75rem;
		.headerTitle {
				@include type-h3;
				color: $theme-text-primary
		}
		.headerSubTitle {
				@include type-lg;
				color: $theme-text-secondary;
		}
		.rowValue {
				@include type-lg-bold;
				&.isCapitalized {
						text-transform: capitalize;
				}
		}
}

@import "assets/stylesheets/scss/tokens";

.insurancePlansMenu {
    padding-top: 0.5rem;
    border-bottom: 1px solid $theme-border-primary;
    margin: 0 1rem;

    .insurancePlansMenuHeader {
        display: flex;
        align-items: center;
        margin: 0 1rem;
        border-bottom: 1px solid $theme-border-primary;
        padding: 0.75rem 0;

        span {
            color: $theme-text-secondary;
            @include type-sm-bold;
            text-transform: uppercase;

            &:first-child {
                width: 65px;
                margin-right: 0.75rem;
            }
        }
    }
}

@import "../../../assets/stylesheets/scss/tokens";
.pickableEmojiContainer {
  display: flex;
  background: $theme-background-primary;
  border-radius: 22px;
  border: 1px solid $theme-border-primary;
  height: 44px;
  width: 89px;
  margin-left: 10px;
  &.hide {
    display: none;
  }
  &.openedPicker {
    background: $theme-background-primary;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
    height: 49px;
    margin-bottom: 7px;
    align-self: flex-end;
    .emojiBlock {
      border:none;
    }
  }

}

.emojiBlock {
  display: flex;
  min-width: 44px;
  border-right: 1px solid $theme-border-primary;
  &.translucent {
    opacity: 0.3;
  }
  button {
    cursor: pointer;
    border: none;
    margin: auto;
    background: transparent;
  }
}

.emojiPickerBlock {
  display: flex;
  min-width: 44px;
  > div {
    margin: auto;
  }
  .dropdownEmojiContent {
    background: $theme-background-primary;
    border-radius: 22px 0px 22px 22px;
    height: 115px;
    width: 181px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    .pickableEmoji {
      width: 53px;
      height: 45px;
      display: flex;
      &:nth-child(1), &:nth-child(3) {
        border-bottom: 1px solid $theme-white;
      }
      &:nth-child(2){
        border-bottom: 1px solid $theme-white;
        border-left: 1px solid $theme-white;
        border-right: 1px solid $theme-white;
      }
      &:nth-child(5){
        border-left: 1px solid $theme-white;
        border-right: 1px solid $theme-white;
      }
      button{
        border: none;
        margin: auto;
        background: transparent;
      }
    }
  }

  :global {
    .dropdown-menu {
    right: -20px;
    top: 31px;
    left: unset;
      .healthie-dropdown-triangle {
        display: none;
      }
    }
  }

}

@import 'src/assets/stylesheets/scss/_tokens.scss';

.wrapper {
  .timeRangeDisplayWrapper {
    padding-top: 12px;

    &:first-of-type {
      padding-top: 0px;
    }

    .dayListWrapper {
      display: flex;
      justify-content: space-between;
  
      .dayList {
        display: flex;
        border: 1px solid $theme-border-primary;
        overflow: hidden;
        border-radius: 6px;
        text-align: center;
        height: 40px;
        flex: 7;
    
        .day {
          width: 100%;
          background: $theme-background-primary;
          height: 100%;
          padding: 8px 12px;
          color: $theme-accent-primary;
          cursor: pointer;
    
          &.selectedDayForRange {
            background: $theme-text-primary;
            color: $theme-white;
          }
    
          &.invalidDayForRange {
            background: $theme-background-secondary;
            color: $theme-white;
          }
    
          &:not(:last-child) {
            border-right: 1px solid $theme-border-primary;
          }
        }
      }
      .deleteRow {
        flex: 1;
        margin: auto;
        text-align: center;

        button {
          background: inherit !important;
        }
        
        :global(#close-icon) {
          cursor: pointer;
        }
      }
    }
  }

  .timeRangePicker {
    display: flex;
    align-items: baseline;
    padding: 10px 0;
    border-bottom: 1px solid $theme-border-primary;
    height: fit-content;
    min-height: 60px;
    flex-wrap: wrap;

    :global(.react-datepicker__tab-loop) {
      display: inline-block;
    }

    .checkbox {
      margin: 6px 8px 8px 0;
    }

    .text {
      margin: auto 8px;
    }

    .timeInput {
      width: 106px;
      height: 36px;
      display: flex;
    }
  }

  .addNewDayRange {
    padding-top: 12px;

    .tooltipButtonWrapper {
      width: fit-content;
    }
  }
}
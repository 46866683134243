@import "src/assets/stylesheets/scss/tokens";

.quickProfileActions {
  position: absolute;
  display: flex;
  bottom: -16px;
  left: -80px;
  max-width: 210px;
  margin-bottom: REM(14);
  .clientAction {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $theme-accent-primary;
    border-radius: 50%;
    border: none;
    height: 24px;
    width: 24px;
    margin-bottom: 0;
    cursor: pointer;
    &.message {
      margin-right: REM(12);
      & +.video {
        margin-left: REM(12);
      }
    }

  }
}

@import 'src/assets/stylesheets/scss/_tokens.scss';

.taskRow {
  display: flex;
  gap: 30px;
  justify-content: space-between;


  .taskContent {
    width: fit-content;
    padding: 8px;
    background-color: $theme-notice-soft-alert;
    border-radius: 4px;
    border: 1px solid $theme-border-primary;

    p {
      @include type-lg;
      color: $theme-text-primary;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .taskActions {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;

    a {
      @include type-lg-bold;
    }
  }
}

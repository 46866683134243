@import 'src/assets/stylesheets/scss/tokens';

.eLabsDirectButton {
  h1 {
    @include type-h4;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: REM(99);
  border: 1px solid $theme-border-primary;
  border-radius: 4px;
  background-image: linear-gradient(to right, #F4F1EE , #E1DBFD);
  width: 100%;

  @include shadow-sm;
  cursor: pointer;
  &.disabled {
    background-color: $theme-background-disabled;
    background-image: linear-gradient(to right, #F4F1EE , $theme-background-disabled);
    cursor:not-allowed
  }
}
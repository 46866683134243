@import "src/assets/stylesheets/scss/tokens";

.tooltipSection {
  display: grid;
  grid-auto-rows: min-content;

  .tooltipLink {
    color: $theme-accent-primary;
  }
}

@import "assets/stylesheets/scss/tokens";

.chartHistoryContainer {
  margin: 15px auto;
  border: 1px solid $theme-border-primary;
  border-radius: 8px;
  overflow: hidden;

  &__bottom {
    margin-bottom: 100px;
  }

  .chartHistoryHeader {
    background: $theme-background-secondary;
    min-height: 46px;
    padding: 13px 10px;
    color: $theme-text-primary;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }

  .historyEvent {
    min-height: 72px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    border-bottom: 1px $theme-border-primary solid;

    &.signature {
      background: $theme-notice-soft-success;
    }

    &.lock {
      background: $theme-background-secondary;
    }

    &.addendum {
      min-height: 101px;
      background: $theme-background-primary;
    }

    .historyEventIcon {
      margin-right: 10px;
    }

    .historyEventContent {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      &__signedBy {
        @include type-sm-bold-uppercase;
        color: $theme-text-primary;
        line-height: inherit;
      }

      &__signedAt {
        color: $theme-text-primary;
        @include type-sm
      }

      &__content {
        margin-top: 10px;
      }
    }
  }
}
@import "../../../../assets/stylesheets/scss/tokens";

.circledSpinner {
  width: 10px;
  height: 10px;
  position: relative;
  .spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &::before {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $theme-notice-bold-success;
      border-radius: 100%;
      animation: circleFadeDelay 0.8s infinite ease-in-out both;
    }
  }
}

@for $i from 1 through 8 {
  .circledSpinner .spinner#{$i} {
    transform: rotate(#{$i*45}deg);
    &::before {
      animation-delay: -(0.9 - calc($i/10)) + 0s;
    }
  }

}


@-webkit-keyframes circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}
